import React, { useContext, useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import InputTextComponent from '../../components/InputTextComponent'
import { LocalesContext } from '../../contexts/LocalesContext'
import { dateToInputString, timeToInputString } from '../../utils/data'
import { configurationRecalcByTime } from '../../utils/irriapp'

export default function ConfTimeComponent(props) {
  const { t } = useContext(LocalesContext)
  const [formValues, setFormValues] = useState({})

  // EFFECTS
  /////////////////////////////////////////////////////////////////////////////////////////////

  // imposto valori temporali iniziali
  useEffect(() => {
    const start = new Date();
    start.setMinutes (start.getMinutes() + 10)
    const end = new Date(start.getTime())
    end.setSeconds(end.getSeconds() + props.configuration.time_prevision)

    setFormValues({
      date_start: dateToInputString(start),
      time_start: timeToInputString(start),
      date_end: dateToInputString(end),
      time_end: timeToInputString(end)
    })
    props.onChangeValidation(true)
  }, [])

  // ricalcolo la configurazione alla variazione dell'orario di fine
  useEffect(() => {
    if (formValues.date_start && formValues.time_start && formValues.date_end && formValues.time_end) {
      const now = new Date()
      const datetimeStart = new Date(`${formValues.date_start} ${formValues.time_start}`)
      const datetimeEnd = new Date(`${formValues.date_end} ${formValues.time_end}`)
      if (datetimeEnd < datetimeStart || datetimeStart < now) {
        props.onChangeValidation(false)
        return
      }

      let newConfiguration = configurationRecalcByTime(Object.assign({}, props.configuration), datetimeStart.getTime(), datetimeEnd.getTime())
      newConfiguration.datetime_start = datetimeStart.getTime()
      newConfiguration.datetime_end = datetimeEnd.getTime()

      props.onChange(newConfiguration)
      props.onChangeValidation(true)
    }
  }, [formValues.date_end, formValues.time_end])

  // sposto l'orario di fine alla variazione di quello di inizio
  useEffect(() => {
    if (formValues.date_start && formValues.time_start) {
      const newDatetimeEnd = new Date(`${formValues.date_start} ${formValues.time_start}`)
      newDatetimeEnd.setSeconds(newDatetimeEnd.getSeconds() + props.configuration.time_prevision)

      const newFormValues = Object.assign({}, formValues)
      newFormValues.date_end = dateToInputString(newDatetimeEnd)
      newFormValues.time_end = timeToInputString(newDatetimeEnd)
      setFormValues(newFormValues)
    }
  }, [formValues.date_start, formValues.time_start])

  // FUNCTIONS
  /////////////////////////////////////////////////////////////////////////////////////////////

  const onValueChange = (name, value) => {
    const newFormValues = Object.assign({}, formValues)
    newFormValues[name] = value
    setFormValues(newFormValues)
  }

  // RENDER
  /////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="r-conf-time">
      <InputTextComponent type="date" value={formValues.date_start || ''} label={t('application.labels.date_start')} onChange={(v) => onValueChange('date_start', v)} min={dateToInputString(new Date())} />
      <InputTextComponent type="time" value={formValues.time_start || ''} label={t('application.labels.time_start')} onChange={(v) => onValueChange('time_start', v)} />
      <InputTextComponent type="date" value={formValues.date_end || ''} label={t('application.labels.date_end')} onChange={(v) => onValueChange('date_end', v)} min={dateToInputString(new Date())} />
      <InputTextComponent type="time" value={formValues.time_end || ''} label={t('application.labels.time_end')} onChange={(v) => onValueChange('time_end', v)} />
    </div>
  )
}

ConfTimeComponent.propTypes = {
  configuration: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onChangeValidation: PropTypes.func
}

ConfTimeComponent.defaultProps = {
  configuration: {},
  onChange: () => {},
  onChangeValidation: () => {}
}
