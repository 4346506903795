import React, { useEffect, useRef, useState } from "react";
import { getRequest } from "../utils/requests";

export const MachineContext = React.createContext();

// NOTE: Context identico a MqttContext ma con la sola gestione macchina e
// senza connessione MQTT.
export function MachineProvider(props) {
  const [machineParams, setMachineParams] = useState(null); // dati macchina attuale
  const [machineName, setMachineName] = useState(null); // nome macchine attuale
  const [machineRtStatus, setMachineRtStatus] = useState(null); //status realtime macchina attuale

  // EFFECTS
  /////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    // esecuzione iniziale
    getMachineParams();
    const interval = setInterval(
      () => getMachineParams(() => {}, false),
      60000
    );
    return () => clearInterval(interval);
  }, []);

  // FUNCTIONS
  /////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Ritorna i parametri della macchina aggiornati dal server.
   * @param {*} callback
   */
  const getMachineParams = (callback = () => {}, saveParams = true) => {
    getRequest(
      props.apis.machines_show_api,
      { apis_auth_token: props.apis.auth_token, uuid: props.machineUuid },
      (response) => {
        if (response.result) {
          let machineParams = "";
          if (
            typeof response.payload.machine.params === "string" ||
            response.payload.machine.params instanceof String
          ) {
            machineParams = JSON.parse(response.payload.machine.params);
          } else {
            machineParams = response.payload.machine.params;
          }

          const params = Object.assign({}, machineParams);
          setMachineName(response.payload.machine.name);
          setMachineRtStatus(response.payload.machine.rt_status);
          if (saveParams) setMachineParams(params);
          callback(params);
        } else {
          callback(null);
        }
      }
    );
  };

  // RENDER
  /////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <MachineContext.Provider
      value={{
        machineParams,
        machineName,
        machineRtStatus,
        actions: {
          getMachineParams,
          setMachineParams,
          setMachineRtStatus,
        },
      }}
    >
      {props.children}
    </MachineContext.Provider>
  );
}
