import React, { useContext } from "react";
import PropTypes from "prop-types";
import { LocalesContext } from "../../contexts/LocalesContext";
import {
  getOptionsTranslation,
  presetSensibilitaGraficoOptions,
  presetGraficoDiDefaultOptions,
} from "../../utils/miplus";

export default function ConfResumeComponent(props) {
  const { t } = useContext(LocalesContext);

  return (
    <div className="r-conf-resume">
      <div className="r-conf-resume__block">
        <div className="r-conf-resume__block-title">{t("GestioneFile")}</div>

        <ul className="r-conf-resume__block-list">
          <li>
            <span className="label">{t("NumeroFile")}</span>
            <span className="value">
              {props.configuration.setupFile && (
                <>{props.configuration.setupFile.length || props.numeroFile}</>
              )}
            </span>
          </li>
        </ul>
      </div>
      <div className="r-conf-resume__block">
        <div className="r-conf-resume__block-title">{t("Configurazione")}</div>

        <ul className="r-conf-resume__block-list">
          <li>
            <span className="label">{t("Interfila")}</span>
            <span className="value">{props.configuration.interfila} cm</span>
          </li>
          <li>
            <span className="label">{t("DistanzaSemina")}</span>
            <span className="value">
              {props.configuration.distanzaSemina} cm
            </span>
          </li>
          <li>
            <span className="label">{t("Investimento")}</span>
            <span className="value">
              {props.configuration.investimento} kseed/ha
            </span>
          </li>
          <li>
            <span className="label">{t("NumeroForiDisco")}</span>
            <span className="value">
              {props.configuration.numeroForiDisco} n°
            </span>
          </li>
          <li>
            <span className="label">{t("DiametroForiDisco")}</span>
            <span className="value">
              {props.configuration.diametroForiDisco} mm
            </span>
          </li>
          <li>
            <span className="label">{t("GraficoDiDefault")}</span>
            <span className="value">
              {getOptionsTranslation(
                presetGraficoDiDefaultOptions(t),
                props.configuration.graficoDefault
              )}
            </span>
          </li>
          <li>
            <span className="label">{t("SensibilitaGrafico")}</span>
            <span className="value">
              {getOptionsTranslation(
                presetSensibilitaGraficoOptions(t),
                props.configuration.sensibilitaGrafico
              )}
            </span>
          </li>
          <li>
            <span className="label">{t("SogliaAllarmeQualita")}</span>
            <span className="value">
              {props.configuration.sogliaAllarmeQualita}%
            </span>
          </li>
          <li>
            <span className="label">{t("SogliaAllarmePopolazione")}</span>
            <span className="value">
              {props.configuration.sogliaAllarmePopolazione}%
            </span>
          </li>
          <li>
            <span className="label">{t("LivelloAllarmeGrafico")}</span>
            <span className="value">
              {props.configuration.livelloAllarmeGrafico}%
            </span>
          </li>
          <li>
            <span className="label">{t("LivelloDepressioneMin")}</span>
            <span className="value">
              {props.configuration.livelloDepressioneMin} mbar
            </span>
          </li>
          <li>
            <span className="label">{t("LivelloDepressioneMax")}</span>
            <span className="value">
              {props.configuration.livelloDepressioneMax} mbar
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}

ConfResumeComponent.propTypes = {
  configuration: PropTypes.object.isRequired,
  numeroFile: PropTypes.number,
};

ConfResumeComponent.defaultProps = {
  configuration: {},
  numeroFile: 24
};
