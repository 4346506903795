import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { LocalesContext } from "../../contexts/LocalesContext";
import { MqttContext } from '../../contexts/MqttContext';
import HeadComponent from "../../components/HeadComponent";
import LoadingComponent from "../../components/LoadingComponent";
import MessageComponent from "../../components/MessageComponent";
import ButtonComponent from "../../components/ButtonComponent";
import ButtonsGroupComponent from "../../components/ButtonsGroupComponent";
import CardComponent from "../../components/CardComponent";
import { getRequest } from "../../utils/requests";
import { gridSizesClassName } from "../../utils/gridSizes";
import { secondsToPrettyTime } from "../../utils/data";
import { convertUnit } from '../../utils/converter'
import { unitaMisuraIntToString, getMachineParamsValue } from '../../utils/irriapp'

export default function PresetsScreen(props) {
  const { t } = useContext(LocalesContext);
  const [presets, setPresets] = useState(null);
  const { machineParams } = useContext(MqttContext);

  console.log(props)

  useEffect(() => {
    getRequest(
      props.apis.presets_index_api,
      {
        apis_auth_token: props.apis.auth_token,
        machine_uuid: props.machine_uuid,
      },
      (response) => {
        if (response.result) setPresets(response.payload.presets);
      }
    );
  }, []);

  return (
    <div className="c-grid__container-spaced">
      <HeadComponent title={t("application.titles.presets")} />
      {presets ? (
        <>
          {presets.length ? (
            <>
              <CardComponent
                className={`${gridSizesClassName({
                  default: 24,
                })} c-card--hasnext`}
              >
                <table className="c-table">
                  <thead>
                    <tr>
                      <th>{t("application.labels.name")}</th>
                      <th className="c-table__col-not-imp">
                        {t("irriapp.titles.work_mode")}
                      </th>
                      <th className="c-table__col-not-imp">
                        {t("irriapp.titles.estimated_time")}
                      </th>
                      <th className="c-table__col-not-imp">
                        {t("irriapp.labels.pause_start")}
                      </th>
                      <th className="c-table__col-not-imp">
                        {t("irriapp.labels.pause_end")}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {presets.map((preset) => {
                      return (
                        <tr key={preset.uuid}>
                          <th>
                            {preset.name}
                            <div className="c-table__col-mob">
                              <div className="c-table__col-mob-item">
                                <span>{t("irriapp.titles.work_mode")}</span>
                                <span>{convertUnit(preset.params.work_mod, unitaMisuraIntToString(getMachineParamsValue(machineParams, 'conf_unita_misura')))}</span>
                              </div>
                              <div className="c-table__col-mob-item">
                                <span>
                                  {t("irriapp.titles.estimated_time")}
                                </span>
                                <span>
                                  {secondsToPrettyTime(
                                    preset.params.time_prevision
                                  )}
                                </span>
                              </div>
                              <div className="c-table__col-mob-item">
                                <span>{t("irriapp.labels.pause_start")}</span>
                                <span>
                                  {preset.params.pause_start}{" "}
                                  {t("application.units.minutes")}
                                </span>
                              </div>
                              <div className="c-table__col-mob-item">
                                <span>{t("irriapp.labels.pause_end")}</span>
                                <span>
                                  {preset.params.pause_end}{" "}
                                  {t("application.units.minutes")}
                                </span>
                              </div>
                            </div>
                          </th>
                          <td className="c-table__col-not-imp">
                            {convertUnit(preset.params.work_mod, unitaMisuraIntToString(getMachineParamsValue(machineParams, 'conf_unita_misura')))}
                          </td>
                          <td className="c-table__col-not-imp">
                            {secondsToPrettyTime(preset.params.time_prevision)}
                          </td>
                          <td className="c-table__col-not-imp">
                            {preset.params.pause_start}{" "}
                            {t("application.units.minutes")}
                          </td>
                          <td className="c-table__col-not-imp">
                            {preset.params.pause_end}{" "}
                            {t("application.units.minutes")}
                          </td>
                          <td className="c-table__actions">
                            <ButtonComponent
                              icon="fas fa-arrow-right"
                              onClick={() =>
                                props.goTo("editor", {
                                  type: "preset_edit",
                                  params: preset.params,
                                  preset,
                                })
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </CardComponent>

              <ButtonsGroupComponent sizes={{ default: 24, sm: 24 }}>
                <ButtonComponent
                  label={t("application.cta.create_preset")}
                  onClick={() => props.goTo("editor", { type: "preset_new" })}
                />
              </ButtonsGroupComponent>
            </>
          ) : (
            <MessageComponent
              title={t("application.messages.no_preset")}
              message={t("application.messages.no_preset_description")}
              extraContent={() => (
                <ButtonComponent
                  label={t("application.cta.create_preset")}
                  onClick={() => props.goTo("editor", { type: "preset_new" })}
                />
              )}
            />
          )}
        </>
      ) : (
        <LoadingComponent semifullscreen />
      )}
    </div>
  );
}

PresetsScreen.propTypes = {
  goTo: PropTypes.func,
};

PresetsScreen.defaultProps = {
  goTo: () => {},
};
