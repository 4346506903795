import React from 'react'
import PropTypes from 'prop-types'

export default function SectionContentComponent(props) {
  const className = `c-section__content c-grid__container-spaced ${props.className}`

  return (
    <div className={className}>
      {props.children}
    </div>
  )
}

SectionContentComponent.propTypes = {
  className: PropTypes.string
}

SectionContentComponent.defaultProps = {
  className: ''
}
