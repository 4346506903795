import React from "react";
import PropTypes from "prop-types";
import CardComponent from "./CardComponent";
import { gridSizesProptypes, gridSizesClassName } from "../utils/gridSizes";
import Tippy from "@tippyjs/react";
import { convertUnit, convertValue } from '../utils/converter'

export default function StatComponent(props) {
  return (
    <CardComponent
      className={`r-stat ${gridSizesClassName(props.sizes)}`}
      {...props.cardProps}
    >
      <div className="r-stat__main">
        <div
          className={`r-stat__label ${
            props.icon ? "r-stat__label--with-icon" : ""
          }`}
        >
          {props.icon && <span className="icon">{props.icon}</span>}
          {props.label}
        </div>
        <div className="r-stat__values">
          <div className="r-stat__value">
            <div className="r-stat__value-main">
              <span className={`value ${props.small ? "is-small" : ""}`}>
                {props.unit ? convertValue(props.value, props.unit, props.converterType) : props.value}
              </span>
              {props.unit && (
                <span className={`unit ${props.small ? "is-small" : ""}`}>
                  {convertUnit(props.unit, props.converterType)}
                </span>
              )}
            </div>

            {props.subvalue && (
              <div className="r-stat__value-sec">
                <span className="subvalue">{props.subvalue}</span>
              </div>
            )}
          </div>
          {props.value2 != undefined && props.value2 != null && (
            <div className="r-stat__value is-second">
              <div className="r-stat__value-main">
                <span className={`value ${props.small ? "is-small" : ""}`}>
                  {props.unit2 ? convertValue(props.value2, props.unit2, props.converterType) : props.value2}
                </span>
                {props.unit2 && (
                  <span className={`unit ${props.small ? "is-small" : ""}`}>
                    {convertUnit(props.unit2, props.converterType)}
                  </span>
                )}
              </div>

              {props.subvalue2 && (
                <div className="r-stat__value-sec">
                  <span className="subvalue">{props.subvalue2}</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {props.dash && (
        <div className="r-stat__dash">
          {props.dash.map((dashItem, index) => {
            if (!dashItem.status) return null
            return (
              <div className="r-stat__dash-item" key={index}>
                <Tippy
                  content={
                    <span>
                      {dashItem.iconValue} {dashItem.iconUnit}
                    </span>
                  }
                >
                  <div
                    className={`r-stat__dash-item-icon ${
                      dashItem.status ? "" : ""
                    } ${dashItem.flash ? "r-stat__dash-item-icon--active" : ""}`}
                  >
                    {dashItem.icon}
                  </div>
                </Tippy>
                
                {dashItem.value && dashItem.label && (
                  <div className="r-stat__dash-item-content">
                    <span className="value">{dashItem.value}</span>
                    <span className="label">{dashItem.label}</span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </CardComponent>
  );
}

StatComponent.propTypes = {
  sizes: gridSizesProptypes(),
  label: PropTypes.string,
  value: PropTypes.any,
  unit: PropTypes.string,
  value2: PropTypes.any,
  unit2: PropTypes.string,
  subvalue: PropTypes.any,
  subvalue2: PropTypes.any,
  small: PropTypes.bool,
  icon: PropTypes.object,
  dash: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.any,
      status: PropTypes.bool,
      flash: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  cardProps: PropTypes.object,
  converterType: PropTypes.string
};

StatComponent.defaultProps = {
  sizes: {},
  cardProps: {},
  converterType: 'eu'
};
