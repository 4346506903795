import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { LocalesContext } from '../../contexts/LocalesContext'
import { convertUnit, convertValue } from '../../utils/converter'

export default function ConfResumeComponent(props) {
  const { t } = useContext(LocalesContext)

  return (
    <div className="r-conf-resume">
      <div className="r-conf-resume__block">
        <div className="r-conf-resume__block-title">{t('irriapp.titles.work_mode')}</div>

        <ul className="r-conf-resume__block-list">
          <li>
            <span className="label">{t('irriapp.titles.work_mode')}</span>
            <span className="value">{convertUnit(props.configuration.work_mod, props.converterType)}</span>
          </li>
        </ul>
      </div>

      <div className="r-conf-resume__block">
        <div className="r-conf-resume__block-title">{t('irriapp.titles.sectors')}</div>

        <table className="r-conf-resume__block-table">
          <thead>
            <tr>
              <th></th>
              <th>{t(props.configuration.work_mod == 'm/h' ? 'irriapp.labels.speed' : 'irriapp.labels.height')}</th>
              <th>{t('irriapp.labels.length')}</th>
            </tr>
          </thead>
          <tbody>
            {[1,2,3,4].map((number) => {
              const speed = props.configuration[`s${number}speed`]
              const height = props.configuration[`s${number}height`]
              const length = props.configuration[`s${number}length`]
              if (!speed || !length) return null

              return (
                <tr key={number}>
                  <th>{t('irriapp.titles.sector')} {number}</th>
                  <td>{props.configuration.work_mod == 'm/h' ? convertValue(speed, 'm/h', props.converterType) : convertValue(height, 'mm', props.converterType)} {convertUnit(props.configuration.work_mod, props.converterType)}</td>
                  <td>{convertValue(length, 'm', props.converterType)} {convertUnit('m', props.converterType)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className="r-conf-resume__block">
        <div className="r-conf-resume__block-title">{t('irriapp.titles.stops')}</div>

        <ul className="r-conf-resume__block-list">
          <li>
            <span className="label">{t('irriapp.labels.pause_start')}</span>
            <span className="value">{props.configuration.pause_start} {t('application.units.minutes')}</span>
          </li>
          <li>
            <span className="label">{t('irriapp.labels.pause_end')}</span>
            <span className="value">{props.configuration.pause_end} {t('application.units.minutes')}</span>
          </li>
        </ul>
      </div>
      {!!props.configuration.motormaticc_active && (
        <div className="r-conf-resume__block">
          <div className="r-conf-resume__block-title">{t('irriapp.titles.motormatic')}</div>

          <ul className="r-conf-resume__block-list">
            <li>
              <span className="label">{t('irriapp.labels.pressure')}</span>
              <span className="value">{props.configuration.motormaticc_pressure} bar</span>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}

ConfResumeComponent.propTypes = {
  configuration: PropTypes.object.isRequired,
  converterType: PropTypes.string
}

ConfResumeComponent.defaultProps = {
  configuration: {},
  converterType: 'eu'
}
