import SetupScreen from '../screens/SetupScreen'
import useNav from '../../hooks/navHook'

export default function SetupNav(props) {
  const [currentScreenName, currentScreenProps, getCurrentScreen] = useNav({
    setup: SetupScreen
  }, 'setup')

  return getCurrentScreen(props)
}
