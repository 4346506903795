import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { LocalesContext } from '../../contexts/LocalesContext'
import ButtonComponent from '../../components/ButtonComponent'
import InputNumberComponent from '../../components/InputNumberComponent'
import { getPresetSectorsLength } from '../../utils/irriapp'

const isLengthValid = (length) => length != undefined && length != null

export default function InputSectorsComponent(props) {
  const { t } = useContext(LocalesContext)
  const [error, setError] = useState(null)
  const [ctaAddDisabled, setCtaAddDisabled] = useState(true)

  const sectorsMapNumber = {
    1: {
      speed: props.s1speed,
      height: props.s1height,
      length: props.s1length
    },
    2: {
      speed: props.s2speed,
      height: props.s2height,
      length: props.s2length
    },
    3: {
      speed: props.s3speed,
      height: props.s3height,
      length: props.s3length
    },
    4: {
      speed: props.s4speed,
      height: props.s4height,
      length: props.s4length
    }
  }

  // EFFECTS
  /////////////////////////////////////////////////////////////////////////////////////////////

  // Gestisco attivazione cta di aggiunta settore in modo da avere sempre il primo settore compilato
  useEffect(() => {
    setCtaAddDisabled(props.s1length < 1)

    if (props.s1length < 1 && props.s2length !== null) {
      onChangeValue(2, 'length', null)
    }
    if (props.s1length < 1 && props.s3length !== null) {
      onChangeValue(3, 'length', null)
    }
    if (props.s1length < 1 && props.s4length !== null) {
      onChangeValue(4, 'length', null)
    }
  }, [props.s1length])

  // Gestisco la validazione della lunghezza dei settori rispetto al target.
  useEffect(() => {
    if (!props.target) return
    const length = getPresetSectorsLength(props)

    if (length > props.target) { // gestisco errore difetto
      for (let i = 4; i > 0; i--) {
        if (props[`s${i}length`]) {
          setError(i)
          break
        }
      }
    } else if (length < 1) { // gestisco errore eccesso
      let diff = props.target - length

      for (let i = 4; i > 0; i--) {
        setError(i)
        diff = diff - (props[`s${i}length`] || 0) 
        if (diff <= 0) break
      }
    } else { // gestisco assenza di errori
      setError(null)
    } 

  }, [props.s1length, props.s2length, props.s3length, props.s4length])

  useEffect(() => {
    props.onChangeValidation(!error)
  }, [error])

  // FUNCTIONS
  /////////////////////////////////////////////////////////////////////////////////////////////

  const onChangeValue = (number, type, value) => {
    props.onChange(value, `s${number}${type}`)
  }

  const onClickAddSector = () => {
    let indexToAdd = 2
    for (let i = indexToAdd; i <= 4; i++) {
      if (!isLengthValid(sectorsMapNumber[i].length)) {
        indexToAdd = i
        break
      }
    }

    const values = {}
    values[`s${indexToAdd}length`] = props.minLength
    values[`s${indexToAdd}speed`] = props.minSpeed
    values[`s${indexToAdd}height`] = props.minHeight
    props.onChangeValues(values)
  }

  const onClickRemoveSector = (number) => {
    onChangeValue(number, 'length', null)
  }

  // RENDER
  /////////////////////////////////////////////////////////////////////////////////////////////

  const total = Object.values(sectorsMapNumber).filter((v) => isLengthValid(v.length)).length
  return (
    <div className="r-input-sectors">
      {Object.values(sectorsMapNumber).map((data, index) => {
        const number = index + 1
        const isDataValid = data && isLengthValid(data.length)
        const isDataVisible = number === 1 || isDataValid
        const isDataAdd = number === 4 && !isDataValid
        const valueSpeed = data.speed || props.minSpeed
        const valueHeight = data.height || props.minHeight
        const valueLength = data.length || props.minLength

        if (isDataVisible) {
          return (
            <div className="r-input-sectors__sector" key={index}>
              {number > 1 && number === total && (
                <button className="c-button c-button--orange r-input-sectors__sector-remove" onClick={() => onClickRemoveSector(number)}>{t('application.cta.remove')}</button>
              )}
              <div className="r-input-sectors__sector-title">{t('irriapp.titles.sector')} {number}</div>
  
              <div className="r-input-sectors__sector-data">
                <div className="r-input-sectors__sector-item">
                  {props.mod == 'm/h' && <InputNumberComponent value={valueSpeed} min={props.minSpeed} max={props.maxSpeed} label={t('irriapp.labels.speed')} unit={'m/h'} onChange={(v) => onChangeValue(number, 'speed', v)} converterType={props.converterType} />}
                  {props.mod == 'mm' && <InputNumberComponent value={valueHeight} min={props.minHeight} max={props.maxHeight} label={t('irriapp.labels.height')} unit={'mm'} onChange={(v) => onChangeValue(number, 'height', v)} converterType={props.converterType} />}
                </div>
  
                <div className="r-input-sectors__sector-item">
                  <InputNumberComponent value={valueLength} min={props.minLength} label={t('irriapp.labels.length')} unit={'m'} onChange={(v) => onChangeValue(number, 'length', v)} error={error === number} converterType={props.converterType} />
                </div>
              </div>
            </div>
          )
        }

        if (isDataAdd) {
          return (
            <div className="r-input-sectors__sector r-input-sectors__sector--add" key={index}>
              <ButtonComponent label={t('irriapp.cta.add_sector')} onClick={onClickAddSector} disabled={ctaAddDisabled} />
            </div>
          )
        }
        
        return null
      })}
    </div>
  )
}

InputSectorsComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  onChangeValues: PropTypes.func.isRequired,
  onChangeValidation: PropTypes.func,
  s1speed: PropTypes.number,
  s1height: PropTypes.number,
  s1length: PropTypes.number,
  s2speed: PropTypes.number,
  s2height: PropTypes.number,
  s2length: PropTypes.number,
  s3speed: PropTypes.number,
  s3height: PropTypes.number,
  s3length: PropTypes.number,
  s4speed: PropTypes.number,
  s4height: PropTypes.number,
  s4length: PropTypes.number,
  minSpeed: PropTypes.number,
  minLength: PropTypes.number,
  maxSpeed: PropTypes.number,
  maxLength: PropTypes.number,
  minHeight: PropTypes.number,
  maxHeight: PropTypes.number,
  mod: PropTypes.string,
  target: PropTypes.number,
  converterType: PropTypes.string
}

InputSectorsComponent.defaultProps = {
  minLength: 0,
  maxLength: 250,
  minSpeed: 4,
  maxSpeed: 850,
  minHeight: 5,
  maxHeight: 150,
  onChangeValidation: () => {},
  converterType: 'eu'
}
