// Initialize service worker for partial data caching.
if (navigator.serviceWorker) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/serviceworker.js').then(function(reg) {
      console.log('[Page] Service worker registered!')
    })
  })
}

// Import local time to show correct time value
import LocalTime from 'local-time'
LocalTime.start()

// Import matilda code
import '../../../vendor/matilda_core/scripts/main'

// Add custom controllers
import app from '../../../vendor/matilda_core/scripts/app'
import BackController from './controllers/BackController'
app.register('back', BackController)