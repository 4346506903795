import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { convertUnit, convertValue } from '../utils/converter'

export default function InputNumberComponent(props) {
  const [error, setError] = useState(false)
  const input = useRef(null)
  const value = error !== false ? error : (props.value || props.min)
  const step = props.decimal ? (1 / (10 * props.decimal)) : props.step
  const isInputUsable = props.converterType == 'eu'

  // FUNCTIONS
  /////////////////////////////////////////////////////////////////////////////////////////////

  const onClickPlus = () => {
    if (props.disabled) return

    let baseValue = isInputUsable ? input.current.value : value
    let newValue = parseFloat((baseValue)) + step
    onChange(newValue)
  }

  const onClickMinus = () => {
    if (props.disabled) return

    let baseValue = isInputUsable ? input.current.value : value
    let newValue = parseFloat((baseValue)) - step
    if (newValue < 0) return
    onChange(newValue)
  }

  const onChangeInput = (newValue) => {
    if (!isInputUsable) return

    if (newValue == '' || newValue.endsWith('.') || newValue.endsWith(',')) {
      setError(newValue)
      return
    }

    onChange(parseFloat(newValue.replace(',', '.')))
  }

  const onChange = (newValue) => {
    newValue = parseFloat(newValue.toFixed(props.decimal || 0))
    if (newValue > props.max || newValue < props.min) {
      setError(newValue)
    } else {
      setError(false)
      props.onChange(newValue)
    }
  }

  // RENDER
  /////////////////////////////////////////////////////////////////////////////////////////////

  const valueConverted = convertValue(value, props.unit, props.converterType)

  return (
    <div className="r-input-number">
      {props.label && <div className="r-input-number__label">{props.label}</div>}
      <div className="r-input-number__input">
        <input type="text" ref={input} className={`${props.error || error ? 'is-error' : ''}`} value={valueConverted} min={0} step={step} onChange={(e) => onChangeInput(e.target.value)} disabled={props.disabled} readOnly={!isInputUsable} />
        {props.unit && <span>{convertUnit(props.unit, props.converterType)}</span>}
        <button className="r-input-number__btn is-minus" onClick={onClickMinus}>-</button>
        <button className="r-input-number__btn is-plus" onClick={onClickPlus}>+</button>
      </div>
    </div>
  )
}

InputNumberComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  unit: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  label: PropTypes.string,
  error: PropTypes.bool,
  decimal: PropTypes.number,
  step: PropTypes.number,
  disabled: PropTypes.bool,
  converterType: PropTypes.string
}

InputNumberComponent.defaultProps = {
  min: 0,
  max: 99999999999,
  decimal: 0,
  step: 1,
  converterType: 'eu'
}
