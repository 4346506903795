import React, { useContext } from 'react'
import { LocalesContext } from '../../contexts/LocalesContext'
import ListItemComponent from '../../components/ListItemComponent'

export default function SessionStatsComponent(props) {
  const { t } = useContext(LocalesContext)

  const { statAverageSpeed, statSuperficieLavorata, statVariations, statSingulation, statDoubles, statSkips, statTotalTarget } = props.session.params
  
  return (
    <>
      <ListItemComponent title={t('VelocitaMedia')}>
        {statAverageSpeed ? `${statAverageSpeed} km/h` : ' - '}
      </ListItemComponent>
      <ListItemComponent title={t('SuperficieLavorata')}>
        {statSuperficieLavorata ? `${statSuperficieLavorata} ha` : ' - '}
      </ListItemComponent>
      <ListItemComponent title={t('DistanzaSemina')}>
        {statVariations ? `${statVariations} cm` : ' - '}
      </ListItemComponent>
      <ListItemComponent title={t('Qualita')}>
        {statSingulation ? `${statSingulation} %` : ' - '}
      </ListItemComponent>
      <ListItemComponent title={t('SemiDoppi')}>
        {statDoubles ? `${statDoubles} %` : ' - '}
      </ListItemComponent>
      <ListItemComponent title={t('SemiMancanti')}>
        {statSkips ? `${statSkips} %` : ' - '}
      </ListItemComponent>
      <ListItemComponent title={t('Obiettivo')}>
        {statTotalTarget ? `${statTotalTarget} kseed/ha` : ' - '}
      </ListItemComponent>
    </>
  )
}
