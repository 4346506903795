import React, { useContext } from 'react'
import { LocalesContext } from '../contexts/LocalesContext'
import ListItemComponent from './ListItemComponent'
import { datetimeToPrettyString } from '../utils/data'

export default function SessionInfosComponent(props) {
  const { t } = useContext(LocalesContext)

  const { machine_name, profile_name, client_name, field_name, duration_string, timestamp_creation } = props.session
  
  return (
    <>
      <ListItemComponent title={t('Macchina')}>
        {machine_name}
      </ListItemComponent>
      <ListItemComponent title={t('Profilo')}>
        {profile_name}
      </ListItemComponent>
      <ListItemComponent title={t('Cliente')}>
        {client_name}
      </ListItemComponent>
      <ListItemComponent title={t('Campo')}>
        {field_name}
      </ListItemComponent>
      <ListItemComponent title={t('Durata')}>
        {duration_string}
      </ListItemComponent>
      <ListItemComponent title={t('Inizio')}>
        {datetimeToPrettyString(new Date(timestamp_creation))}
      </ListItemComponent>
    </>
  )
}
