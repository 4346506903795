import React from "react";
import PropTypes from "prop-types";

export default function RowsPresetButtonComponent(props) {
  const onClick = () => {
    props.onClick();
  };
  let className = "r-rowsPresetBtn";

  if (props.active) {
    className = `r-rowsPresetBtn r-rowsPresetBtn__active`;
  }

  return (
    <button className={className} onClick={onClick}>
      {props.label}
    </button>
  );
}

RowsPresetButtonComponent.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

RowsPresetButtonComponent.defaultProps = {
  className: "",
  label: "",
  onClick: () => {},
};
