import React, { useState } from 'react'

export const LocalesContext = React.createContext()

export function LocalesProvider (props) {
  const [locale, setLocale] = useState('en')
  const [translations, setTranslations] = useState({})

  const translate = (key) => translations[key] ? translations[key][locale] : key
  const t = (key) => translate(key)

  return (
    <LocalesContext.Provider value={{ locale, translations, t, actions: { setLocale, setTranslations } }} >
      {props.children}
    </LocalesContext.Provider>
  )
}
