import React, { useContext, useMemo, useState } from "react"
import PropTypes from "prop-types"
import ChartLineComponent from "../../components/ChartLineComponent"
import { LocalesContext } from "../../contexts/LocalesContext"
import {
  getDatesPerMinuteBetweenDates,
  timeToPrettyString,
  secondsToPrettyTime
} from "../../utils/data"

export default function SessionChartComponent(props) {
  const { snap } = props
  const { t, translations } = useContext(LocalesContext)

  const chartColors = [
    "#2980b9",
    "#16a085",
    "#f39c12",
    "#8e44ad",
    "#e67e22",
    "#2c3e50",
    "#27ae60",
    "#c60055",
  ]
  const chartUnits = ["km/h", "kseed/ha", "mbar", "%", "%", "%", "ha", "ha/h"]
  const chartLabels = [
    t("VelocitaAvanzamento"),
    t("Investimento"),
    t("Depressione"),
    t("Qualita"),
    t("SemiDoppi"),
    t("SemiMancanti"),
    t("AreaLavorata"),
    t("Produttivita"),
  ]
  const chartKeys = [
    "speed",
    "target",
    "depression",
    "quality",
    "doubles",
    "skips",
    "area",
    "productivity",
  ]
  const chartBarColors = []
  const chartBarLabels = []
  const chartBarKeys = []

  const chartAreaColors = ["#c0392b"]
  const chartAreaLabels = [t("Allarme")]
  const chartAreaKeys = ["alarm"]

  // const [alarmStep, setAlarmStep] = useState([])

  const chartData = useMemo(() => {
    if (snap) {
      if (snap.length < 1) return []

      const timeStart = new Date(snap[0].timestamp)
      const timeEnd = new Date(snap[snap.length - 1].timestamp)
      const minutes = getDatesPerMinuteBetweenDates(timeStart, timeEnd)

      const data = []
      let alarms = []
      minutes.map((minute, index) => {

        // if(alarmStep && alarmStep.length > 0){
        //   console.log(index, alarmStep)

        //   alarmStep.map((alarm) => {
        //     if(alarm.alarm_step > 0){
        //       console.log(alarm.alarm_id + '> 0')
        //       alarm.alarm_step -= 1
        //     } else {
        //       console.log(alarm.alarm_id +'< 0')
        //       // setAlarmStep(alarmStep.filter(item => item !== alarm))
        //       alarmStep()
        //     }
        //   })
        // }

        if(alarms && alarms.length > 0){
          alarms.map((alarm) => {
            if(alarm.alarm_step > 0){
              alarm.alarm_step--
            } else {
              alarms = alarms.filter(item => item !== alarm)
            }
          })
        }

        const snapshots = snap.filter((s) => {
          if (
            !chartKeys.includes(s.typology) &&
            !chartBarKeys.includes(s.typology) &&
            !chartAreaKeys.includes(s.typology)
          )
            return false
          const snapshotTimestamp = new Date(s.timestamp)
          return (
            snapshotTimestamp >= minute &&
            (minutes[index + 1] ? snapshotTimestamp < minutes[index + 1] : true)
          )
        })

        const dataItem = { x: timeToPrettyString(minute) }
        snapshots.map((snapshot) => {
          if (chartBarKeys.includes(snapshot.typology)) {
            dataItem[snapshot.typology] = 1
            let label = snapshot.value_elaborated
            if (snapshot.lifetime && snapshot.lifetime > 60) label = `${label} (x ${secondsToPrettyTime(snapshot.lifetime)})`
            dataItem[`${snapshot.typology}_string`] = label
          } else if(chartAreaKeys.includes(snapshot.typology)){
            dataItem[snapshot.typology] = 1
            // let label = snapshot.value_elaborated
            // if (snapshot.lifetime && snapshot.lifetime > 60) label = `${label} (x ${secondsToPrettyTime(snapshot.lifetime)})`
            // dataItem[`${snapshot.typology}_string`] = label


            // dataItem[`${snapshot.typology}_string`] = snapshot.value_elaborated
            // dataItem[`${snapshot.typology}_lifetime`] = snapshot.lifetime

            let step = 0
            if(snapshot.lifetime > 60){
              for (let i = 0; i < snapshot.lifetime; i += 60){
                step++
              }
            }

            // dataItem[`${snapshot.typology}_step`] = step

            alarms.push({
              alarm_typology: snapshot.typology,
              alarm_string: snapshot.value_elaborated, 
              alarm_step: step, 
              alarm_id: snapshot.id
            })
            // setAlarmStep(alarmStep => [
            //   ...alarmStep, 
            //   {
            //     alarm_typology: snapshot.typology,
            //     alarm_string: snapshot.value_elaborated, 
            //     alarm_step: step, 
            //     alarm_id: snapshot.id
            //   }
            // ])

            // let alarms = ''
            // alarmStep.map((alarm) => alarms += alarm.alarm_string + ', ')
            // alarms += snapshot.value_elaborated

            // dataItem[`${snapshot.typology}_string`] = alarms
          }else {
            dataItem[snapshot.typology] = snapshot.value
          }

          // if(alarmStep && alarmStep.length > 0){
          //   dataItem['alarm'] = 1
          //   let alarms = ''
          //   alarmStep.map((alarm) => alarms += alarm.alarm_string + ', ')
          //   dataItem['alarm_string'] = alarms
          // }

          if(alarms && alarms.length > 0){
            dataItem['alarm'] = 1
            dataItem['alarm_string'] = alarms.map((alarm) => alarm.alarm_string).join(', ')
          } else {
            dataItem['alarm'] = 0
          }
        })

        // console.log(dataItem)

        data.push(dataItem)
      })

      return data
    }
  }, [translations, snap])

  return (
    <ChartLineComponent
      data={chartData}
      keys={chartKeys}
      colors={chartColors}
      units={chartUnits}
      labels={chartLabels}
      barKeys={chartBarKeys}
      barColors={chartBarColors}
      barLabels={chartBarLabels}
      areaKeys={chartAreaKeys}
      areaColors={chartAreaColors}
      areaLabels={chartAreaLabels}
    />
  )
}

SessionChartComponent.propTypes = {
  snap: PropTypes.array,
}

SessionChartComponent.defaultProps = {
  snap: [],
}
