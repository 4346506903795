import PresetsScreen from '../screens/PresetsScreen'
import EditorScreen from '../screens/EditorScreen'
import PresetsConfirmScreen from '../screens/PresetsConfirmScreen'
import useNav from '../../hooks/navHook'

export default function PresetsNav(props) {
  const [currentScreenName, currentScreenProps, getCurrentScreen] = useNav({
    presets: PresetsScreen,
    presetsConfirm: PresetsConfirmScreen,
    editor: EditorScreen,
  }, 'presets')

  return getCurrentScreen(props)
}
