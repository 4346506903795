import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { LocalesContext } from "../../contexts/LocalesContext";
import ModalComponent from "../../components/ModalComponent";
import MessageComponent from "../../components/MessageComponent";
import LoadingComponent from "../../components/LoadingComponent";
import { getRequest } from "../../utils/requests";
import { timeToPrettyString } from "../../utils/data";

export default function ModalAlarms(props) {
  const { t } = useContext(LocalesContext);
  const [loading, setLoading] = useState(false);
  const [alarms, setAlarms] = useState([]);

  useEffect(() => {
    if (props.status) {
      setLoading(true);
      setTimeout(() => {
        getRequest(
          props.apis.machines_snapshots_api,
          { uuid: props.machine_uuid, typology: "alarm" },
          (response) => {
            if (response.result) {
              setAlarms(response.payload.snapshots);
              setLoading(false);
            }
          }
        );
      }, 1000);
    }
  }, [props.status]);

  return (
    <ModalComponent status={props.status} onClose={props.onClose}>
      {loading ? (
        <LoadingComponent semifullscreen />
      ) : (
        <>
          {alarms.length > 0 ? (
            <table className="c-table">
              <tbody>
                {alarms.map((alarm) => {
                  const value = alarm.value_string;
                  const date = new Date(alarm.timestamp);
                  if (!value || !date) return null;

                  return (
                    <tr key={alarm.id}>
                      <td>{value}</td>
                      <td>{timeToPrettyString(date)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <MessageComponent title={t("application.titles.no_alarms")} />
          )}
        </>
      )}
    </ModalComponent>
  );
}

ModalAlarms.propTypes = {
  status: PropTypes.bool,
  onClose: PropTypes.func,
};

ModalAlarms.defaultProps = {};
