import React, { useContext, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { LocalesContext } from "../../contexts/LocalesContext";
import { MachineContext } from "../../contexts/MachineContext";
import SectionContentComponent from "../../components/SectionContentComponent";
import SectionContentBlockComponent from "../../components/SectionContentBlockComponent";
import CardComponent from "../../components/CardComponent";
import ConfResumeComponent from "../../miplus/components/ConfResumeComponent";
import SessionChartComponent from "../../miplus/components/SessionChartComponent";
import { getRequest } from "../../utils/requests";
import MapComponent from "../../components/MapComponent";

const CHART_TIME_LAST_HOURS = 4

export default function MachineScreen(props) {
  const { t, translations } = useContext(LocalesContext);
  const [snap, setSnap] = useState(null);
  const [preset, setPreset] = useState(null);
  const [geolocation, setGeolocation] = useState(null);
  const { machineParams } = useContext(MachineContext);

  useEffect(() => {
    // esecuzione iniziale
    getMachineSnapshots();
    const interval = setInterval(() => getMachineSnapshots(), 10000);
    return () => clearInterval(interval);
  }, []);

  const getMachineSnapshots = () => {
    getRequest(
      props.apis.machines_snapshots_api,
      {
        apis_auth_token: props.apis.auth_token,
        uuid: props.machine_uuid,
        to_hours: CHART_TIME_LAST_HOURS,
        per_page: 500
      },
      (response) => {
        if (response.result) {
          const snapshots = response.payload.snapshots.reverse();
          setSnap(snapshots);
        }
      }
    );

    getRequest(
      props.apis.machines_snapshots_api,
      {
        apis_auth_token: props.apis.auth_token,
        uuid: props.machine_uuid,
        typology: "preset_loaded",
        to_hours: 99999,
        per_page: 1
      },
      (response) => {
        if (response.result && response.payload.snapshots[0]) {
          const preset_uuid = response.payload.snapshots[0].value_string;

          getRequest(
            props.apis.presets_show_api,
            {
              apis_auth_token: props.apis.auth_token,
              preset_uuid: preset_uuid,
            },
            (response) => {
              if (response.result) {
                const preset = response.payload.preset;
                setPreset(preset);
              }
            }
          );
        }
      }
    );

    getRequest(
      props.apis.snapshots_index_api,
      {
        apis_auth_token: props.apis.auth_token,
        machine_uuid: props.machine_uuid,
        typology: "geolocation_retrieve",
      },
      (response) => {
        if (response.result && response.payload.snapshots[0]) {
          const geo = JSON.parse(response.payload.snapshots[0].value_string);
          setGeolocation(geo);
        }
      }
    );
  };

  return (
    <SectionContentComponent>
      <SectionContentBlockComponent sizes={{ default: 24 }}>
        {snap && snap.length > 0 && (
          <CardComponent
            title={
              t("application.titles.session_work_history")
            }
          >
            <SessionChartComponent snap={snap} />
          </CardComponent>
        )}
      </SectionContentBlockComponent>
      {geolocation && (
        <SectionContentBlockComponent sizes={{ default: 24, md: 14 }}>
          <CardComponent title={t("application.titles.session_map")}>
            <MapComponent coords={geolocation} />
          </CardComponent>
        </SectionContentBlockComponent>
      )}

      {preset && (
        <SectionContentBlockComponent sizes={{ default: 24, md: 10 }}>
          <CardComponent title={t("application.titles.session_preset")}>
            <ConfResumeComponent configuration={preset.params} numeroFile={machineParams?.setup?.numeroFile} />
          </CardComponent>
        </SectionContentBlockComponent>
      )}
    </SectionContentComponent>
  );
}

MachineScreen.propTypes = {
  goTo: PropTypes.func,
};

MachineScreen.defaultProps = {
  goTo: () => {},
};
