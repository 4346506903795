import React from "react";
import PropTypes from "prop-types";

export default function ModalComponent(props) {
  return (
    <div className={`c-modal ${props.status ? "is-active" : ""} `}>
      <div
        className="c-modal__overlay"
        onClick={props.onClose ? props.onClose : () => {}}
      ></div>

      <div
        className={`c-modal__box ${props.large ? "c-modal__box--large" : ""}`}
      >
        <div className="c-card">
          <div className="c-card__header">
            {props.title ? (
              <div className="c-card__header-title">{props.title}</div>
            ) : (
              <div />
            )}

            {props.onClose && (
              <ul className="c-card__header-actions">
                <li className="c-card__header-action">
                  <a
                    className="c-button c-button--black"
                    onClick={props.onClose}
                  >
                    X
                  </a>
                </li>
              </ul>
            )}
          </div>
          <div className="c-card__body">{props.children}</div>
        </div>
      </div>
    </div>
  );
}

ModalComponent.propTypes = {
  status: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func,
  large: PropTypes.bool,
};

ModalComponent.defaultProps = {};
