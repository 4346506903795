import React from 'react'
import PropTypes from 'prop-types'

export default function ActionbarComponent(props) {
  return (
    <ul className="c-card r-actionbar">
      {props.actions.map((action, i) => {
        return (
          <li className={`r-actionbar__action ${action.active ? 'is-active' : ''}`} key={i} disabled={action.disabled}>
            <button className="r-actionbar__action-button" onClick={action.onClick} disabled={action.disabled}>
              {action.icon && <span className="r-actionbar__action-icon">{action.icon}</span>}
              <span className="r-actionbar__action-label">{action.label}</span>
            </button>
          </li>
        )
      })}
    </ul>
  )
}

ActionbarComponent.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      icon: PropTypes.object,
      active: PropTypes.bool
    })
  )
}

ActionbarComponent.defaultProps = {
  actions: []
}
