import React from "react";
import PropTypes from "prop-types";
import RowsPresetButtonComponent from "./RowsPresetButtonComponent";

export default function RowsPresetComponent(props) {
  if (props.rowsActive.length == 0) {
    Array.from(Array(props.rows), (e, i) => {
      props.rowsActive.push(i + 1);
    });
  }
  let rows = props.rowsActive;

  const removeEl = (el) => {
    rows.forEach((ele, i) => {
      if (el === ele) {
        rows.splice(i, 1);
      }
    });
  };

  return (
    <div className="r-rowsPreset">
      {props.rows &&
        Array.from(Array(props.rows), (e, i) => {
          const index = i + 1;
          return (
            <RowsPresetButtonComponent
              key={index}
              label={index.toString()}
              onClick={() => {
                if (props.rowsActive.includes(index)) {
                  removeEl(index);
                } else {
                  rows.push(index);
                }
                props.onRowsChange(rows);
              }}
              active={
                props.rowsActive.length < 1 || props.rowsActive.includes(i + 1)
              }
            />
          );
        })}
    </div>
  );
}

RowsPresetComponent.propTypes = {
  rows: PropTypes.number,
  rowsActive: PropTypes.array,
  onRowsChange: PropTypes.func,
};

RowsPresetComponent.defaultProps = {};
