import React from 'react'
import PropTypes from 'prop-types'
import { gridSizesProptypes, gridSizesClassName } from '../utils/gridSizes'

export default function ButtonsGroupComponent(props) {
  let className = `r-buttonsgroup ${gridSizesClassName(props.sizes)}`

  return (
    <div className={className}>
      {props.children}
    </div>
  )
}

ButtonsGroupComponent.propTypes = {
  sizes: gridSizesProptypes()
}

ButtonsGroupComponent.defaultProps = {
  sizes: {}
}
