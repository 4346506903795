import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { LocalesContext } from '../../contexts/LocalesContext'
import { MqttContext } from '../../contexts/MqttContext'
import HeadComponent from '../../components/HeadComponent'
import CardComponent from '../../components/CardComponent'
import ListItemComponent from '../../components/ListItemComponent'
import InputNumberComponent from '../../components/InputNumberComponent'
import InputSelectComponent from '../../components/InputSelectComponent'
import FixedBarComponent from '../../components/FixedBarComponent'
import LoadingComponent from '../../components/LoadingComponent'
import ModalComponent from '../../components/ModalComponent'
import MessageComponent from '../../components/MessageComponent'
import ButtonsGroupComponent from '../../components/ButtonsGroupComponent'
import ButtonComponent from '../../components/ButtonComponent'
import { gridSizesClassName } from '../../utils/gridSizes'
import { runPromisesSequence } from '../../utils/data'
import { getMachineParamsValue } from '../../utils/irriapp'

export default function SetupScreen(props) {
  const isLevel3 = props.permissions.includes('assistance.setup_3')
  const isLevel2 = props.permissions.includes('assistance.setup_2')
  const isLevel1 = props.permissions.includes('assistance.setup_1')
  const { t } = useContext(LocalesContext)
  const { machineParams, actions: mqttActions } = useContext(MqttContext)
  const [ctaEnabled, setCtaEnabled] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [noteEnabled, setNoteEnabled] = useState(false)

  const formValuesObj = {}
  Object.keys(machineParams).map((key) => { if (key.startsWith('conf_')) { formValuesObj[key] =getMachineParamsValue(machineParams, key) } })
  const [formValues, setFormValues] = useState(formValuesObj)

  // EFFECTS
  /////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setCtaEnabled(!!getChangedValues().length)
  }, [formValues])

  // FUNCTIONS
  /////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Funzione eseguita alla conferma di invio delle modifiche alla macchina.
   */
  const onConfirm = () => {
    const changedValues = getChangedValues()
    const changedRequests = []
    setLoadingModal(true)

    const updateSingleValue = (key) => {
      return new Promise((resolve, reject) => {
        mqttActions.sendMessageWithAnswer('UPD_CONF', { key: key, value: formValues[key] }, 'COMMAND_ACK', () => {
          reject()
        })
      })
    }

    mqttActions.sendMessageWithAnswer('RMT_WRT', { active: true }, 'COMMAND_ACK', (response) => {
      console.log('TOGLIMI', response)
      if (!response.data.result) return setLoadingModal(false)

      runPromisesSequence(changedValues, updateSingleValue).then(() => {
        mqttActions.sendMessageWithAnswer('RMT_WRT', { active: false }, 'COMMAND_ACK', () => {
          setLoadingModal(false)
        })
      })
    })
  }

  /**
   * Funzione eseguita per aggiornare un valore del form
   * @param {*} name 
   * @param {*} value 
   */
  const updateFormValue = (name, value) => {
    const newFormValues = Object.assign({}, formValues)
    newFormValues[name] = parseFloat(value)
    setFormValues(newFormValues)
  }

  /**
   * Ritorna un array con tutti i valori che sono stati modificati rispetto a quelli originali.
   */
  const getChangedValues = () => {
    let changedValues = []

    Object.keys(formValues).map((key) => {
      if (getMachineParamsValue(machineParams, key) != formValues[key]) {
        changedValues.push(key)
      }
    })

    return changedValues
  }

  // RENDER
  /////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="c-grid__container-spaced">
      <HeadComponent title={t('application.titles.setup')} />

      <FixedBarComponent
        active={!!noteEnabled}
        onClose={() => setNoteEnabled(false)}
        renderContent={() => {
          return (
            <div>
              <h4>{noteEnabled.title}</h4>
              <p>{noteEnabled.note}</p>
            </div>
          )
        }}
      >

        {/* GRUPPO 1 */}
        {/* /////////////////////////////////////////////////////////////////// */}
        {isLevel1 && (
          <CardComponent
            className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
            title={t('irriapp.setup.title_conf_card_gruppo_1')}
          >
            {isLevel3 && ( // INDICE 47
              <ListItemComponent
                title={t('irriapp.setup.title_conf_unita_misura')}
                number={'#A47'}
              >
                <InputSelectComponent 
                  options={[
                    {label: t('irriapp.setup.list_item_l8_0'), value: 0}, 
                    {label: t('irriapp.setup.list_item_l8_1'), value: 1},
                    {label: t('irriapp.setup.list_item_l8_2'), value: 2}
                  ]} 
                  onChange={(v) => updateFormValue('conf_unita_misura', v)} 
                  value={formValues.conf_unita_misura || ''} />
              </ListItemComponent>
            )}
            {isLevel3 && ( // INDICE 48
              <ListItemComponent
                title={t('irriapp.setup.title_conf_lingua_testi')}
                number={'#A48'}
              >
                <InputSelectComponent 
                  options={[
                    {label: t('irriapp.setup.list_item_l9_0'), value: 0}, 
                    {label: t('irriapp.setup.list_item_l9_1'), value: 1},
                    {label: t('irriapp.setup.list_item_l9_2'), value: 2},
                    {label: t('irriapp.setup.list_item_l9_3'), value: 3},
                    {label: t('irriapp.setup.list_item_l9_4'), value: 4},
                    {label: t('irriapp.setup.list_item_l9_5'), value: 5},
                    {label: t('irriapp.setup.list_item_l9_6'), value: 6},
                    {label: t('irriapp.setup.list_item_l9_7'), value: 7},
                    {label: t('irriapp.setup.list_item_l9_8'), value: 8},
                  ]} 
                  onChange={(v) => updateFormValue('conf_lingua_testi', v)} 
                  value={formValues.conf_lingua_testi || ''} />
              </ListItemComponent>
            )}
            {isLevel3 && ( // INDICE 55
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_modem_gsm_attivo')} 
                note={t('irriapp.setup.note_conf_modem_gsm_attivo')}
                number={'#A55'}
                onPressInfo={(data) => setNoteEnabled(data)}
              >
                <InputSelectComponent 
                    options={[
                      {label: t('irriapp.setup.list_item_l10_0'), value: 0}, 
                      {label: t('irriapp.setup.list_item_l10_1'), value: 1}
                    ]} 
                    onChange={(v) => updateFormValue('conf_modem_gsm_attivo', v)} 
                    value={formValues.conf_modem_gsm_attivo || ''} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 56
              <ListItemComponent
                title={t('irriapp.setup.title_conf_logo')}
                number={'#A56'}
              >
                <InputSelectComponent 
                  options={[
                    {label: 'MATERMACC', value: 0}, 
                    {label: 'IDROFOGLIA', value: 1},
                    {label: 'NETTUNO', value: 2},
                    {label: 'ITAL\'AGRI (COMAI)', value: 3},
                    {label: 'OCMIS', value: 4},
                    {label: 'CASELLA', value: 5},
                    {label: 'HAG', value: 6},
                    {label: 'EUROMACCHINE', value: 7},
                    {label: 'IRRIGABRASIL', value: 8},
                    {label: 'HUDIG', value: 9},
                    {label: 'OASI', value: 10},
                    {label: 'A.R. RUBES', value: 11},
                    {label: 'GOUMANS', value: 12},
                    {label: 'MOON SOON', value: 13},
                    {label: 'GIUNTI (CO.MI.R.)', value: 14},
                    {label: 'PIOGGIA CARNEVALI', value: 15},
                    {label: 'VINERBINI ing. GASTONE', value: 16},
                    {label: 'OFFICINA CECCHIN', value: 17},
                    {label: 'ETS CHESNEAU', value: 18},
                    {label: 'GE.BR SMITS BV', value: 19},
                    {label: 'IRRILAND', value: 20},
                    {label: 'EUROMACCHINE LT', value: 21},
                    {label: 'RM', value: 22},
                    {label: 'SALINA PAOLO', value: 23},
                    {label: 'AQUA PRO', value: 24},
                    {label: 'VALLEY FAB', value: 25},
                    {label: 'TRAILCO', value: 26},
                    {label: 'ROMANO', value: 27},
                    {label: 'ORMA', value: 28},
                    {label: 'IRDEV', value: 29}
                  ]} 
                  onChange={(v) => updateFormValue('conf_logo', v)} 
                  value={formValues.conf_logo || ''} />
              </ListItemComponent>
            )}
            {isLevel3 && ( // INDICE 57
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_tempo_spegnimento_scheda')} 
                note={t('irriapp.setup.note_conf_tempo_spegnimento_scheda')}
                number={'#A57'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_tempo_spegnimento_scheda} onChange={(v) => updateFormValue('conf_tempo_spegnimento_scheda', v)} min={0} max={240} step={5} unit={t('application.units.minutes')} />
              </ListItemComponent>
            )}
            {isLevel3 && ( // INDICE 58
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_lunghezza_tubo_allarme')} 
                note={t('irriapp.setup.note_conf_lunghezza_tubo_allarme')}
                number={'#A58'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_lunghezza_tubo_allarme} onChange={(v) => updateFormValue('conf_lunghezza_tubo_allarme', v)} min={0} max={1500} unit={'m'} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 65a
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_min_attesa_per_pressione_nulla')} 
                note={t('irriapp.setup.note_conf_min_attesa_per_pressione_nulla')}
                number={'#A65a'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_min_attesa_per_pressione_nulla} onChange={(v) => updateFormValue('conf_min_attesa_per_pressione_nulla', v)} min={0} max={120} unit={t('application.units.minutes')} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 65b
            <ListItemComponent 
              title={t('irriapp.setup.title_conf_escludi_flux_se_pressione_nulla')} 
              note={t('irriapp.setup.note_conf_escludi_flux_se_pressione_nulla')}
              number={'#A65b'}
              onPressInfo={(data) => setNoteEnabled(data)}
            >
              <InputSelectComponent 
                  options={[
                    {label: t('irriapp.setup.list_item_l12_0'), value: 0}, 
                    {label: t('irriapp.setup.list_item_l12_1'), value: 1}
                  ]} 
                  onChange={(v) => updateFormValue('conf_escludi_flux_se_pressione_nulla', v)} 
                  value={formValues.conf_escludi_flux_se_pressione_nulla || ''} />
            </ListItemComponent>)}
            {isLevel2 && ( // INDICE 66a
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_tipo_flussometro')} 
                note={t('irriapp.setup.note_conf_tipo_flussometro')}
                number={'#A66a'}
                onPressInfo={(data) => setNoteEnabled(data)}
              >
                <InputSelectComponent 
                  options={[
                    {label: t('irriapp.setup.list_item_l15_0'), value: 0}, 
                    {label: t('irriapp.setup.list_item_l15_1'), value: 1},
                    {label: t('irriapp.setup.list_item_l15_2'), value: 2},
                  ]} 
                  onChange={(v) => updateFormValue('conf_tipo_flussometro', v)} 
                  value={formValues.conf_tipo_flussometro || ''} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 66b
              <ListItemComponent
                title={t('irriapp.setup.title_conf_flussometro_digitale')}
                number={'#A66b'}
                big
              >
                <InputNumberComponent value={formValues.conf_flussometro_digitale} onChange={(v) => updateFormValue('conf_flussometro_digitale', v)} min={1} max={1000} unit={'l/i'} />
              </ListItemComponent>
            )}
            {isLevel3 && ( // INDICE 67
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_min_assenza_impulsi_di_controllo')} 
                note={t('irriapp.setup.note_conf_min_assenza_impulsi_di_controllo')}
                number={'#A67'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_min_assenza_impulsi_di_controllo} onChange={(v) => updateFormValue('conf_min_assenza_impulsi_di_controllo', v)} min={0} max={60} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 18
              <ListItemComponent
                title={t('irriapp.setup.title_conf_modello_dispositivo')}
                number={'#B18'}
              >
                <InputSelectComponent 
                    options={[
                      {label: t('irriapp.setup.list_item_l17_0'), value: 0}, 
                      {label: t('irriapp.setup.list_item_l17_1'), value: 1}
                    ]} 
                    onChange={(v) => updateFormValue('conf_modello_dispositivo', v)} 
                    value={formValues.conf_modello_dispositivo || ''} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 19
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_soglia_batteria_scarica')}
                number={'#B19'}
                big
              >
                <InputNumberComponent value={formValues.conf_soglia_batteria_scarica} onChange={(v) => updateFormValue('conf_soglia_batteria_scarica', v)} min={10.5} max={12.5} decimal={1} unit={'V'} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 20
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_modello_scheda')} 
                note={t('irriapp.setup.note_conf_modello_scheda')}
                number={'#B20'}
                onPressInfo={(data) => setNoteEnabled(data)}
              >
                <InputSelectComponent 
                  options={[
                    {label: t('irriapp.setup.list_item_l18_0'), value: 0}, 
                    {label: t('irriapp.setup.list_item_l18_1'), value: 1},
                    {label: t('irriapp.setup.list_item_l18_2'), value: 2}
                  ]} 
                  onChange={(v) => updateFormValue('conf_modello_scheda', v)} 
                  value={formValues.conf_modello_scheda || ''} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 21
              <ListItemComponent
                title={t('irriapp.setup.title_conf_costruttore')}
                number={'#B21'}
              >
                <InputSelectComponent 
                  options={[
                    {label: 'MATERMACC', value: 0}, 
                    {label: 'IDROFOGLIA', value: 1},
                    {label: 'NETTUNO', value: 2},
                    {label: 'ITAL\'AGRI (COMAI)', value: 3},
                    {label: 'OCMIS', value: 4},
                    {label: 'CASELLA', value: 5},
                    {label: 'HAG', value: 6},
                    {label: 'EUROMACCHINE', value: 7},
                    {label: 'IRRIGABRASIL', value: 8},
                    {label: 'HUDIG', value: 9},
                    {label: 'OASI', value: 10},
                    {label: 'A.R. RUBES', value: 11},
                    {label: 'GOUMANS', value: 12},
                    {label: 'MOON SOON', value: 13},
                    {label: 'GIUNTI (CO.MI.R.)', value: 14},
                    {label: 'PIOGGIA CARNEVALI', value: 15},
                    {label: 'VINERBINI ing. GASTONE', value: 16},
                    {label: 'OFFICINA CECCHIN', value: 17},
                    {label: 'ETS CHESNEAU', value: 18},
                    {label: 'GE.BR SMITS BV', value: 19},
                    {label: 'IRRILAND', value: 20},
                    {label: 'EUROMACCHINE LT', value: 21},
                    {label: 'RM', value: 22},
                    {label: 'SALINA PAOLO', value: 23},
                    {label: 'AQUA PRO', value: 24},
                    {label: 'VALLEY FAB', value: 25},
                    {label: 'TRAILCO', value: 26},
                    {label: 'ROMANO', value: 27},
                    {label: 'ORMA', value: 28},
                    {label: 'IRDEV', value: 29}
                  ]} 
                  onChange={(v) => updateFormValue('conf_costruttore', v)} 
                  value={formValues.conf_costruttore || ''}
                />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 30
              <ListItemComponent
                title={t('irriapp.setup.title_conf_percentuale_pwm_lcd')}
                big
                number={'#30'}
              >
                <InputNumberComponent value={formValues.conf_percentuale_pwm_lcd} onChange={(v) => updateFormValue('conf_percentuale_pwm_lcd', v)} min={0} max={100} step={5} unit={'%'} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 31
              <ListItemComponent
                title={t('irriapp.setup.title_conf_tipo_display')}
                number={'#31'}
              >
                <InputSelectComponent 
                    options={[
                      {label: t('irriapp.setup.list_item_l21_0'), value: 0}, 
                      {label: t('irriapp.setup.list_item_l21_1'), value: 1}
                    ]} 
                    onChange={(v) => updateFormValue('conf_tipo_display', v)} 
                    value={formValues.conf_tipo_display || ''} />
              </ListItemComponent>
            )}
          </CardComponent>
        )}

        {/* GRUPPO 2 */}
        {/* /////////////////////////////////////////////////////////////////// */}
        {isLevel1 && (
          <CardComponent
            className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
            title={t('irriapp.setup.title_conf_card_gruppo_2')}
          >
            {isLevel2 && ( // INDICE 12
              <ListItemComponent
                title={t('irriapp.setup.title_conf_timeout_pressione_nulla')}
                number={'#A12'}
                big
              >
                <InputNumberComponent value={formValues.conf_timeout_pressione_nulla} onChange={(v) => updateFormValue('conf_timeout_pressione_nulla', v)} min={0} max={15} unit={t('application.units.minutes')} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 13
              <ListItemComponent
                title={t('irriapp.setup.title_conf_posizione_pressostato')}
                number={'#A13'}
              >
                <InputSelectComponent 
                  options={[
                    {label: t('irriapp.setup.list_item_l2_0'), value: 0}, 
                    {label: t('irriapp.setup.list_item_l2_1'), value: 1},
                    {label: t('irriapp.setup.list_item_l2_2'), value: 2},
                    {label: t('irriapp.setup.list_item_l2_3'), value: 3},
                    {label: t('irriapp.setup.list_item_l2_4'), value: 4}
                  ]} 
                  onChange={(v) => updateFormValue('conf_posizione_pressostato', v)} 
                  value={formValues.conf_posizione_pressostato || ''} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 14
              <ListItemComponent
                title={t('irriapp.setup.title_conf_posizione_sensore_velocita')}
                number={'#A14'}
              >
                <InputSelectComponent 
                    options={[
                      {label: t('irriapp.setup.list_item_l3_0'), value: 0}, 
                      {label: t('irriapp.setup.list_item_l3_1'), value: 1}
                    ]} 
                    onChange={(v) => updateFormValue('conf_posizione_sensore_velocita', v)} 
                    value={formValues.conf_posizione_sensore_velocita || ''} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 15
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_decimatore_sensore_velocita')} 
                note={t('irriapp.setup.note_conf_decimatore_sensore_velocita')}
                number={'#A15'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_decimatore_sensore_velocita} onChange={(v) => updateFormValue('conf_decimatore_sensore_velocita', v)} min={0} max={10} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 16
              <ListItemComponent
                title={t('irriapp.setup.title_conf_sensore_fine_srotolamento')}
                number={'#A16'}
              >
                <InputSelectComponent 
                    options={[
                      {label: t('irriapp.setup.list_item_l4_0'), value: 0}, 
                      {label: t('irriapp.setup.list_item_l4_1'), value: 1}
                    ]} 
                    onChange={(v) => updateFormValue('conf_sensore_fine_srotolamento', v)} 
                    value={formValues.conf_sensore_fine_srotolamento || ''} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 17
              <ListItemComponent
                title={t('irriapp.setup.title_conf_sensore_fine_riavvolgimento')}
                number={'#A17'}
              >
                <InputSelectComponent 
                  options={[
                    {label: t('irriapp.setup.list_item_l5_0'), value: 0}, 
                    {label: t('irriapp.setup.list_item_l5_1'), value: 1},
                    {label: t('irriapp.setup.list_item_l5_2'), value: 2},
                    {label: t('irriapp.setup.list_item_l5_3'), value: 3},
                    {label: t('irriapp.setup.list_item_l5_4'), value: 4},
                    {label: t('irriapp.setup.list_item_l5_5'), value: 5},
                    {label: t('irriapp.setup.list_item_l5_6'), value: 6},
                    {label: t('irriapp.setup.list_item_l5_7'), value: 7},
                    {label: t('irriapp.setup.list_item_l5_8'), value: 8},
                  ]} 
                  onChange={(v) => updateFormValue('conf_sensore_fine_riavvolgimento', v)} 
                  value={formValues.conf_sensore_fine_riavvolgimento || ''} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 18a
              <ListItemComponent
                title={t('irriapp.setup.title_conf_sensore_pioggia_vento')}
                number={'#A18a'}
              >
                <InputSelectComponent 
                  options={[
                    {label: t('irriapp.setup.list_item_l6_0'), value: 0}, 
                    {label: t('irriapp.setup.list_item_l6_1'), value: 1},
                    {label: t('irriapp.setup.list_item_l6_2'), value: 2}
                  ]} 
                  onChange={(v) => updateFormValue('conf_sensore_pioggia_vento', v)} 
                  value={formValues.conf_sensore_pioggia_vento || ''} />
              </ListItemComponent>
            )}
            {formValues.conf_sensore_pioggia_vento == 2 && isLevel3 && ( // INDICE 18b
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_iv_coefficiente_retta')} 
                note={t('irriapp.setup.note_conf_iv_coefficiente_retta')}
                number={'#A18b'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_iv_coefficiente_retta} onChange={(v) => updateFormValue('conf_iv_coefficiente_retta', v)} min={1} max={10} />
              </ListItemComponent>
            )}
            {formValues.conf_sensore_pioggia_vento == 2 && isLevel3 && ( // INDICE 18c
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_iv_soglia_intervento')} 
                note={t('irriapp.setup.note_conf_iv_soglia_intervento')}
                number={'#A18c'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_iv_soglia_intervento} onChange={(v) => updateFormValue('conf_iv_soglia_intervento', v)} min={5} max={50} unit={'km/h'} />
              </ListItemComponent>
            )}
            {formValues.conf_sensore_pioggia_vento == 2 && isLevel3 && ( // INDICE 18d
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_iv_isteresi_cambio_stati')} 
                note={t('irriapp.setup.note_conf_iv_isteresi_cambio_stati')}
                number={'#A18d'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_iv_isteresi_cambio_stati} onChange={(v) => updateFormValue('conf_iv_isteresi_cambio_stati', v)} min={0} max={60} unit={t('application.units.seconds')} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 20
              <ListItemComponent
                title={t('irriapp.setup.title_conf_portata_media')}
                number={'#A20'}
                big
              >
                <InputNumberComponent value={formValues.conf_portata_media} onChange={(v) => updateFormValue('conf_portata_media', v)} min={500} max={3000} unit={'l/min'} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 21
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_portata_minima')} 
                note={t('irriapp.setup.note_conf_portata_minima')}
                number={'#A21'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_portata_minima} onChange={(v) => updateFormValue('conf_portata_minima', v)} min={0} max={3000} unit={'l/min'} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 22
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_portata_massima')} 
                note={t('irriapp.setup.note_conf_portata_massima')}
                number={'#A22'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_portata_massima} onChange={(v) => updateFormValue('conf_portata_massima', v)} min={500} max={3000} unit={'l/min'} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 23
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_portata_isteresi_simmetrica')} 
                note={t('irriapp.setup.note_conf_portata_isteresi_simmetrica')}
                number={'#A23'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_portata_isteresi_simmetrica} onChange={(v) => updateFormValue('conf_portata_isteresi_simmetrica', v)} min={0} max={100} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 24
              <ListItemComponent
                title={t('irriapp.setup.title_conf_dmm_impulso_rullo_tastatore')}
                number={'#A24'}
                big
              >
                <InputNumberComponent debug value={formValues.conf_dmm_impulso_rullo_tastatore} onChange={(v) => updateFormValue('conf_dmm_impulso_rullo_tastatore', v)} decimal={1} min={5} max={250} unit={'mm/i'} />
              </ListItemComponent>
            )}
            {isLevel3 && ( // INDICE 61
              <ListItemComponent
                title={t('irriapp.setup.title_conf_modalita_terzo_motore')}
                number={'#A61'}
              >
                <InputSelectComponent 
                  options={[
                    {label: t('irriapp.setup.list_item_l11_0'), value: 0}, 
                    {label: t('irriapp.setup.list_item_l11_1'), value: 1},
                    {label: t('irriapp.setup.list_item_l11_2'), value: 2}
                  ]} 
                  onChange={(v) => updateFormValue('conf_modalita_terzo_motore', v)} 
                  value={formValues.conf_modalita_terzo_motore || ''} />
              </ListItemComponent>
            )}
            {(formValues.conf_modalita_terzo_motore == 0 || formValues.conf_modalita_terzo_motore == 1) && isLevel3 && ( // INDICE 62a
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_m_fw_terzo_motore')} 
                note={t('irriapp.setup.note_conf_m_fw_terzo_motore')}
                number={'#A62a'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_m_fw_terzo_motore} onChange={(v) => updateFormValue('conf_m_fw_terzo_motore', v)} min={0} max={1500} unit={'m'} />
              </ListItemComponent>
            )}
            {formValues.conf_modalita_terzo_motore == 0 && isLevel3 && ( // INDICE 62b
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_m_rv_terzo_motore')} 
                note={t('irriapp.setup.note_conf_m_rv_terzo_motore')}
                number={'#A62b'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_m_rv_terzo_motore} onChange={(v) => updateFormValue('conf_m_rv_terzo_motore', v)} min={0} max={1500} unit={'m'} />
              </ListItemComponent>
            )}
            {formValues.conf_modalita_terzo_motore == 1 && isLevel3 && ( // INDICE 63
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_min_fw_terzo_motore')} 
                note={t('irriapp.setup.note_conf_min_fw_terzo_motore')}
                number={'#A63'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_min_fw_terzo_motore} onChange={(v) => updateFormValue('conf_min_fw_terzo_motore', v)} min={0} max={1500} step={5} unit={t('application.units.minutes')} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 13
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_lunghezza_buffer_sensore_velocita')} 
                note={t('irriapp.setup.note_conf_lunghezza_buffer_sensore_velocita')}
                number={'#B13'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_lunghezza_buffer_sensore_velocita} onChange={(v) => updateFormValue('conf_lunghezza_buffer_sensore_velocita', v)} min={2} max={16} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 14
              <ListItemComponent
                title={t('irriapp.setup.title_conf_polarita_pressostato')}
                number={'#B14'}
              >
                <InputSelectComponent 
                    options={[
                      {label: t('irriapp.setup.list_item_l14_0'), value: 0}, 
                      {label: t('irriapp.setup.list_item_l14_1'), value: 1}
                    ]} 
                    onChange={(v) => updateFormValue('conf_polarita_pressostato', v)} 
                    value={formValues.conf_polarita_pressostato || ''} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 15
              <ListItemComponent
                title={t('irriapp.setup.title_conf_polarita_sensore_fine_srotolamento')}
                number={'#B15'}
              >
                <InputSelectComponent 
                    options={[
                      {label: t('irriapp.setup.list_item_l14_0'), value: 0}, 
                      {label: t('irriapp.setup.list_item_l14_1'), value: 1}
                    ]} 
                    onChange={(v) => updateFormValue('conf_polarita_sensore_fine_srotolamento', v)} 
                    value={formValues.conf_polarita_sensore_fine_srotolamento || ''} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 16
              <ListItemComponent
                title={t('irriapp.setup.title_conf_polarita_sensore_fine_riavvolgimento')}
                number={'#B16'}
              >
                <InputSelectComponent 
                    options={[
                      {label: t('irriapp.setup.list_item_l14_0'), value: 0}, 
                      {label: t('irriapp.setup.list_item_l14_1'), value: 1}
                    ]} 
                    onChange={(v) => updateFormValue('conf_polarita_sensore_fine_riavvolgimento', v)} 
                    value={formValues.conf_polarita_sensore_fine_riavvolgimento || ''} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 17
              <ListItemComponent
                title={t('irriapp.setup.title_conf_polarita_sensore_pioggia_vento')}
                number={'#B17'}
              >
                <InputSelectComponent 
                    options={[
                      {label: t('irriapp.setup.list_item_l14_0'), value: 0}, 
                      {label: t('irriapp.setup.list_item_l14_1'), value: 1}
                    ]} 
                    onChange={(v) => updateFormValue('conf_polarita_sensore_pioggia_vento', v)} 
                    value={formValues.conf_polarita_sensore_pioggia_vento || ''} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 22
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_ms_filtro_impulsi')} 
                note={t('irriapp.setup.note_conf_ms_filtro_impulsi')}
                number={'#B22'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_ms_filtro_impulsi} onChange={(v) => updateFormValue('conf_ms_filtro_impulsi', v)} min={0} max={150} unit={'ms'} />
              </ListItemComponent>
            )}

            {/** AGGIORNAMENTI FEBBRAIO 2023 */}
            {isLevel2 && ( // INDICE 93
              <ListItemComponent
                title={t('irriapp.setup.title_conf_bar_trasduttore_min')}
                number={'#A13A'}
              >
                <InputNumberComponent debug value={formValues.conf_bar_trasduttore_min} onChange={(v) => updateFormValue('conf_bar_trasduttore_min', v)} decimal={1} min={0} max={3} unit={'bar'} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 94
              <ListItemComponent
                title={t('irriapp.setup.title_conf_bar_trasduttore_max')}
                number={'#A13B'}
              >
                <InputNumberComponent debug value={formValues.conf_bar_trasduttore_max} onChange={(v) => updateFormValue('conf_bar_trasduttore_max', v)} decimal={1} min={3} max={50} unit={'bar'} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 95
              <ListItemComponent
                title={t('irriapp.setup.title_conf_bar_trasduttore_soglia')}
                number={'#A13C'}
              >
                <InputNumberComponent debug value={formValues.conf_bar_trasduttore_soglia} onChange={(v) => updateFormValue('conf_bar_trasduttore_soglia', v)} decimal={1} min={0} max={50} unit={'bar'} />
              </ListItemComponent>
            )}
          </CardComponent>
        )}

        {/* GRUPPO 3 */}
        {/* /////////////////////////////////////////////////////////////////// */}
        {isLevel1 && (
          <CardComponent
            className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
            title={t('irriapp.setup.title_conf_card_gruppo_3')}
          >
            {isLevel3 && (
              <ListItemComponent // INDICE 01
                title={t('irriapp.setup.title_conf_posizione_valvola_flusso')}
                number={'#A1'}
              >
                <InputSelectComponent 
                  options={[
                    {label: t('irriapp.setup.list_item_l1_0'), value: false}, 
                    {label: t('irriapp.setup.list_item_l1_1'), value: true}
                  ]}
                  onChange={(v) => updateFormValue('conf_posizione_valvola_flusso', v)} 
                  value={formValues.conf_posizione_valvola_flusso || ''} />
              </ListItemComponent>
              
            )}
            {isLevel2 && ( // INDICE 02
              <ListItemComponent
                title={t('irriapp.setup.title_conf_timeout_apertura_flusso')}
                number={'#A2'}
                big
              >
                <InputNumberComponent value={formValues.conf_timeout_apertura_flusso} onChange={(v) => updateFormValue('conf_timeout_apertura_flusso', v)} min={30} max={480} unit={t('application.units.seconds')} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 03
              <ListItemComponent
                title={t('irriapp.setup.title_conf_timeout_chiusura_flusso')}
                number={'#A3'}
                big
              >
                <InputNumberComponent value={formValues.conf_timeout_chiusura_flusso} onChange={(v) => updateFormValue('conf_timeout_chiusura_flusso', v)} min={30} max={480} unit={t('application.units.seconds')} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 06
              <ListItemComponent
                title={t('irriapp.setup.title_conf_tempo_apertura_flusso_pwm')}
                number={'#A6'}
                big
              >
                <InputNumberComponent value={formValues.conf_tempo_apertura_flusso_pwm} onChange={(v) => updateFormValue('conf_tempo_apertura_flusso_pwm', v)} min={80} max={480} unit={t('application.units.seconds')} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 07
              <ListItemComponent
                title={t('irriapp.setup.title_conf_tempo_chiusura_flusso_no_pwm')}
                number={'#A7'}
                big
              >
                <InputNumberComponent value={formValues.conf_tempo_chiusura_flusso_no_pwm} onChange={(v) => updateFormValue('conf_tempo_chiusura_flusso_no_pwm', v)} min={80} max={480} unit={t('application.units.seconds')} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 10
              <ListItemComponent
                title={t('irriapp.setup.title_conf_timeout_apertura_bypass')}
                number={'#A10'}
                big
              >
                <InputNumberComponent value={formValues.conf_timeout_apertura_bypass} onChange={(v) => updateFormValue('conf_timeout_apertura_bypass', v)} min={30} max={480} unit={t('application.units.seconds')} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 11
              <ListItemComponent
                title={t('irriapp.setup.title_conf_timeout_chiusura_bypass')}
                number={'#A11'}
                big
              >
                <InputNumberComponent value={formValues.conf_timeout_chiusura_bypass} onChange={(v) => updateFormValue('conf_timeout_chiusura_bypass', v)} min={30} max={480} unit={t('application.units.seconds')} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 01
              <ListItemComponent
                title={t('irriapp.setup.title_conf_tempo_apertura_flusso_pwm_on')}
                number={'#B1'}
                big
              >
                <InputNumberComponent value={formValues.conf_tempo_apertura_flusso_pwm_on} onChange={(v) => updateFormValue('conf_tempo_apertura_flusso_pwm_on', v)} min={10} max={25000} step={5} unit={'ms'} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 02
              <ListItemComponent
                title={t('irriapp.setup.title_conf_tempo_apertura_flusso_pwm_off')}
                number={'#B2'}
                big
              >
                <InputNumberComponent value={formValues.conf_tempo_apertura_flusso_pwm_off} onChange={(v) => updateFormValue('conf_tempo_apertura_flusso_pwm_off', v)} min={10} max={25000} step={5} unit={'ms'} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 03
              <ListItemComponent
                title={t('irriapp.setup.title_conf_tempo_chiusura_flusso_pwm_on')}
                number={'#B3'}
                big
              >
                <InputNumberComponent value={formValues.conf_tempo_chiusura_flusso_pwm_on} onChange={(v) => updateFormValue('conf_tempo_chiusura_flusso_pwm_on', v)} min={10} max={25000} step={5} unit={'ms'} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 04
              <ListItemComponent
                title={t('irriapp.setup.title_conf_tempo_chiusura_flusso_pwm_off')}
                number={'#B4'}
                big
              >
                <InputNumberComponent value={formValues.conf_tempo_chiusura_flusso_pwm_off} onChange={(v) => updateFormValue('conf_tempo_chiusura_flusso_pwm_off', v)} min={10} max={25000} step={5} unit={'ms'} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 05
              <ListItemComponent
                title={t('irriapp.setup.title_conf_tempo_pwm_lungo_on')}
                number={'#B5'}
                big
              >
                <InputNumberComponent value={formValues.conf_tempo_pwm_lungo_on} onChange={(v) => updateFormValue('conf_tempo_pwm_lungo_on', v)} min={10} max={25000} step={5} unit={'ms'} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 06
              <ListItemComponent
                title={t('irriapp.setup.title_conf_tempo_pwm_lungo_off')}
                number={'#B6'}
                big
              >
                <InputNumberComponent value={formValues.conf_tempo_pwm_lungo_off} onChange={(v) => updateFormValue('conf_tempo_pwm_lungo_off', v)} min={10} max={25000} step={5} unit={'ms'} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 07
              <ListItemComponent
                title={t('irriapp.setup.title_conf_tempo_pwm_corto_on')}
                number={'#B7'}
                big
              >
                <InputNumberComponent value={formValues.conf_tempo_pwm_corto_on} onChange={(v) => updateFormValue('conf_tempo_pwm_corto_on', v)} min={10} max={25000} step={5} unit={'ms'} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 08
              <ListItemComponent
                title={t('irriapp.setup.title_conf_tempo_pwm_corto_off')}
                number={'#B8'}
                big
              >
                <InputNumberComponent value={formValues.conf_tempo_pwm_corto_off} onChange={(v) => updateFormValue('conf_tempo_pwm_corto_off', v)} min={10} max={25000} step={5} unit={'ms'} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 09
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_tempo_pwm_molto_lungo_on')} 
                note={t('irriapp.setup.note_conf_tempo_pwm_molto_lungo_on')}
                number={'#B9'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_tempo_pwm_molto_lungo_on} onChange={(v) => updateFormValue('conf_tempo_pwm_molto_lungo_on', v)} min={10} max={25000} step={5} unit={'ms'} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 10
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_tempo_pwm_molto_lungo_off')} 
                note={t('irriapp.setup.note_conf_tempo_pwm_molto_lungo_off')}
                number={'#B10'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_tempo_pwm_molto_lungo_off} onChange={(v) => updateFormValue('conf_tempo_pwm_molto_lungo_off', v)} min={10} max={25000} step={5} unit={'ms'} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 24
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_adc_min_current_sense')} 
                note={t('irriapp.setup.note_conf_adc_min_current_sense')}
                number={'#B24'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_adc_min_current_sense} onChange={(v) => updateFormValue('conf_adc_min_current_sense', v)} min={2} max={250} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 25
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_tempo_azionamento_flusso')} 
                note={t('irriapp.setup.note_conf_tempo_azionamento_flusso')}
                number={'#B25'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_tempo_azionamento_flusso} onChange={(v) => updateFormValue('conf_tempo_azionamento_flusso', v)} min={0} max={120} unit={t('application.units.seconds')} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 29
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_tipo_gestione_doppio_bypass')} 
                note={t('irriapp.setup.note_conf_tipo_gestione_doppio_bypass')}
                number={'#29'}
                onPressInfo={(data) => setNoteEnabled(data)}
              >
                <InputSelectComponent 
                  options={[
                    {label: t('irriapp.setup.list_item_l20_0'), value: 0}, 
                    {label: t('irriapp.setup.list_item_l20_1'), value: 1},
                    {label: t('irriapp.setup.list_item_l20_2'), value: 2}
                  ]} 
                  onChange={(v) => updateFormValue('conf_tipo_gestione_doppio_bypass', v)} 
                  value={formValues.conf_tipo_gestione_doppio_bypass || ''} />
              </ListItemComponent>
            )}
          </CardComponent>
        )}

        {/* GRUPPO 4 */}
        {/* /////////////////////////////////////////////////////////////////// */}
        {isLevel2 && (
          <CardComponent
            className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
            title={t('irriapp.setup.title_conf_card_gruppo_4')}
          >
            {isLevel2 && ( // INDICE 49
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_diametro_naspo')} 
                note={t('irriapp.setup.note_conf_diametro_naspo')}
                number={'#A49'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_diametro_naspo} onChange={(v) => updateFormValue('conf_diametro_naspo', v)} min={500} max={2500} unit={'mm'} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 50
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_larghezza_naspo')} 
                note={t('irriapp.setup.note_conf_larghezza_naspo')}
                number={'#A50'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_larghezza_naspo} onChange={(v) => updateFormValue('conf_larghezza_naspo', v)} min={500} max={2500} unit={'mm'} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 51
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_diametro_tubo')} 
                note={t('irriapp.setup.note_conf_diametro_tubo')}
                number={'#A51'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_diametro_tubo} onChange={(v) => updateFormValue('conf_diametro_tubo', v)} min={40} max={250} unit={'mm'} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 52
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_lunghezza_tubo')} 
                note={t('irriapp.setup.note_conf_lunghezza_tubo')}
                number={'#A52'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_lunghezza_tubo} onChange={(v) => updateFormValue('conf_lunghezza_tubo', v)} min={0} max={1500} unit={'m'} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 53a
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_allungamento_tubo_calibrazione_matematica')} 
                note={t('irriapp.setup.note_conf_allungamento_tubo_calibrazione_matematica')}
                number={'#A53a'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_allungamento_tubo_calibrazione_matematica} onChange={(v) => updateFormValue('conf_allungamento_tubo_calibrazione_matematica', v)} min={0} max={20} unit={'%'} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 53b
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_fattore_riempimento_calibrazione_matematica')} 
                note={t('irriapp.setup.note_conf_fattore_riempimento_calibrazione_matematica')}
                number={'#A53b'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_fattore_riempimento_calibrazione_matematica} onChange={(v) => updateFormValue('conf_fattore_riempimento_calibrazione_matematica', v)} min={50} max={100} unit={'%'} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 54
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_impulsi_giro_calibrazione_matematica')} 
                note={t('irriapp.setup.note_conf_impulsi_giro_calibrazione_matematica')}
                number={'#A54'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_impulsi_giro_calibrazione_matematica} onChange={(v) => updateFormValue('conf_impulsi_giro_calibrazione_matematica', v)} min={10} max={1000} />
              </ListItemComponent>
            )}
            {isLevel3 && ( // INDICE 64
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_m_tubo_srotolato_per_attivazione_rele')} 
                note={t('irriapp.setup.note_conf_m_tubo_srotolato_per_attivazione_rele')}
                number={'#A64'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_m_tubo_srotolato_per_attivazione_rele} onChange={(v) => updateFormValue('conf_m_tubo_srotolato_per_attivazione_rele', v)} min={0} max={1500} unit={'m'} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 68
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_escludi_rele_aux_per_processo_interrotto')} 
                note={t('irriapp.setup.note_conf_escludi_rele_aux_per_processo_interrotto')}
                number={'#A68'}
                onPressInfo={(data) => setNoteEnabled(data)}
              >
                <InputSelectComponent 
                    options={[
                      {label: t('irriapp.setup.list_item_l16_0'), value: 0}, 
                      {label: t('irriapp.setup.list_item_l16_1'), value: 1}
                    ]} 
                    onChange={(v) => updateFormValue('conf_escludi_rele_aux_per_processo_interrotto', v)} 
                    value={formValues.conf_escludi_rele_aux_per_processo_interrotto || ''} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 71
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_tempo_isteresi_fine_riavvolgimento')} 
                note={t('irriapp.setup.note_conf_tempo_isteresi_fine_riavvolgimento')}
                number={'#A71'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_tempo_isteresi_fine_riavvolgimento} onChange={(v) => updateFormValue('conf_tempo_isteresi_fine_riavvolgimento', v)} min={0} max={240} unit={t('application.units.seconds')} />
              </ListItemComponent>
            )}
          </CardComponent>
        )}

        {/* GRUPPO 5 */}
        {/* /////////////////////////////////////////////////////////////////// */}
        {isLevel1 && (
          <CardComponent
            className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
            title={t('irriapp.setup.title_conf_card_gruppo_5')}
          >
            {isLevel2 && ( // INDICE 19
              <ListItemComponent
                title={t('irriapp.setup.title_conf_criterio_regolazione')}
                number={'#A19'}
              >
                <InputSelectComponent 
                  options={[
                    {label: t('irriapp.setup.list_item_l7_0'), value: 0}, 
                    {label: t('irriapp.setup.list_item_l7_1'), value: 1},
                    {label: t('irriapp.setup.list_item_l7_2'), value: 2}
                  ]} 
                  onChange={(v) => updateFormValue('conf_criterio_regolazione', v)} 
                  value={formValues.conf_criterio_regolazione || ''} />
              </ListItemComponent>
            )}
            {isLevel3 && ( // INDICE 59
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_portata_fissa')} 
                note={t('irriapp.setup.note_conf_portata_fissa')}
                number={'#A59'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_portata_fissa} onChange={(v) => updateFormValue('conf_portata_fissa', v)} min={0} max={2700} step={10} unit={'l/min'} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 72
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_tempo_massimo_errore_regolazione')} 
                note={t('irriapp.setup.note_conf_tempo_massimo_errore_regolazione')}
                number={'#A72'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_tempo_massimo_errore_regolazione} onChange={(v) => updateFormValue('conf_tempo_massimo_errore_regolazione', v)} min={0} max={60} unit={t('application.units.minutes')} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 11
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_soglia_min')}
                note={t('irriapp.setup.note_conf_soglia_min')}
                number={'#B11'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_soglia_min} onChange={(v) => updateFormValue('conf_soglia_min', v)} min={0} max={100} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 12
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_soglia_max')} 
                note={t('irriapp.setup.note_conf_soglia_max')}
                number={'#B12'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_soglia_max} onChange={(v) => updateFormValue('conf_soglia_max', v)} min={0} max={100} />
              </ListItemComponent>
            )}
            {isLevel1 && ( // INDICE 23
              <ListItemComponent
                title={t('irriapp.setup.title_conf_modalita_regolazione')}
                number={'#B23'}
              >
                <InputSelectComponent 
                    options={[
                      {label: t('irriapp.setup.list_item_l19_0'), value: 0}, 
                      {label: t('irriapp.setup.list_item_l19_1'), value: 1}
                    ]} 
                    onChange={(v) => updateFormValue('conf_modalita_regolazione', v)} 
                    value={formValues.conf_modalita_regolazione || ''} />
              </ListItemComponent>
            )}
          </CardComponent>
        )}

        {/* GRUPPO 6 */}
        {/* /////////////////////////////////////////////////////////////////// */}
        {isLevel2 && (
          <CardComponent
            className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
            title={t('irriapp.setup.title_conf_card_gruppo_6')}
          >
            {isLevel2 && ( // INDICE 08
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_fattore_minimo_carrello_perso')} 
                note={t('irriapp.setup.note_conf_fattore_minimo_carrello_perso')}
                number={'#A8'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_fattore_minimo_carrello_perso} onChange={(v) => updateFormValue('conf_fattore_minimo_carrello_perso', v)} min={1} max={10} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 09
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_fattore_massimo_carrello_perso')} 
                note={t('irriapp.setup.note_conf_fattore_massimo_carrello_perso')}
                number={'#A9'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_fattore_massimo_carrello_perso} onChange={(v) => updateFormValue('conf_fattore_massimo_carrello_perso', v)} min={1} max={200} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 70
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_tempo_sollevamento_carrello')} 
                note={t('irriapp.setup.note_conf_tempo_sollevamento_carrello')}
                number={'#A70'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_tempo_sollevamento_carrello} onChange={(v) => updateFormValue('conf_tempo_sollevamento_carrello', v)} min={0} max={240} unit={t('application.units.seconds')} />
              </ListItemComponent>
            )}
          </CardComponent>
        )}

        {/* GRUPPO 7 */}
        {/* /////////////////////////////////////////////////////////////////// */}
        {isLevel2 && (
          <CardComponent
            className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
            title={t('irriapp.setup.title_conf_card_gruppo_7')}
          >
            {isLevel2 && ( // INDICE 26a
              <ListItemComponent
                title={t('irriapp.setup.title_conf_mtr_abilitata')}
                number={'#B26a'}
              >
                <InputSelectComponent 
                    options={[
                      {label: t('irriapp.setup.list_item_l4_0'), value: 0}, 
                      {label: t('irriapp.setup.list_item_l4_1'), value: 1}
                    ]} 
                    onChange={(v) => updateFormValue('conf_mtr_abilitata', v)} 
                    value={formValues.conf_mtr_abilitata || ''} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 26b
              <ListItemComponent
                title={t('irriapp.setup.title_conf_mtr_soglia_minima')}
                number={'#B26b'}
                big
              >
                <InputNumberComponent value={formValues.conf_mtr_soglia_minima} onChange={(v) => updateFormValue('conf_mtr_soglia_minima', v)} min={100} max={1000} step={5} unit={'rpm'} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 26c
              <ListItemComponent
                title={t('irriapp.setup.title_conf_mtr_eccitazione_ma')}
                number={'#B26c'}
                big
              >
                <InputNumberComponent value={formValues.conf_mtr_eccitazione_ma} onChange={(v) => updateFormValue('conf_mtr_eccitazione_ma', v)} min={5} max={100} unit={t('application.units.seconds')} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 26d
              <ListItemComponent
                title={t('irriapp.setup.title_conf_mtr_impulsi_per_giro')}
                number={'#B26d'}
                big
              >
                <InputNumberComponent value={formValues.conf_mtr_impulsi_per_giro} onChange={(v) => updateFormValue('conf_mtr_impulsi_per_giro', v)} min={1} max={1000}  />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 26e
            <ListItemComponent
              title={t('irriapp.setup.title_conf_mtr_sonda_gasolio_presente')}
              number={'#B26e'}
            >
              <InputSelectComponent 
                  options={[
                    {label: t('irriapp.setup.list_item_l4_0'), value: 0}, 
                    {label: t('irriapp.setup.list_item_l4_1'), value: 1}
                  ]} 
                  onChange={(v) => updateFormValue('conf_mtr_sonda_gasolio_presente', v)} 
                  value={formValues.conf_mtr_sonda_gasolio_presente || ''} />
            </ListItemComponent>)}
            {isLevel2 && ( // INDICE 26f
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_mtr_livello_gasolio_basso')} 
                note={t('irriapp.setup.note_conf_mtr_livello_gasolio_basso')}
                number={'#B26f'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_mtr_livello_gasolio_basso} onChange={(v) => updateFormValue('conf_mtr_livello_gasolio_basso', v)} min={0} max={100} unit={'%'} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 26g
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_mtr_livello_gasolio_finito')} 
                note={t('irriapp.setup.note_conf_mtr_livello_gasolio_finito')}
                number={'#B26g'}
                onPressInfo={(data) => setNoteEnabled(data)}
                big
              >
                <InputNumberComponent value={formValues.conf_mtr_livello_gasolio_finito} onChange={(v) => updateFormValue('conf_mtr_livello_gasolio_finito', v)} min={0} max={100} unit={'%'} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 26h
              <ListItemComponent
                title={t('irriapp.setup.title_conf_mtr_off_con_attuatore')}
                number={'#B26h'}
              >
                <InputSelectComponent 
                    options={[
                      {label: '0', value: false}, 
                      {label: '1', value: true}
                    ]} 
                    onChange={(v) => updateFormValue('conf_mtr_off_con_attuatore', v)} 
                    value={formValues.conf_mtr_off_con_attuatore || ''} />
              </ListItemComponent>
            )}
            {isLevel2 && ( // INDICE 26i
              <ListItemComponent
                title={t('irriapp.setup.title_conf_mtr_pressostato_olio_presente')}
                number={'#B26i'}
              >
                <InputSelectComponent 
                    options={[
                      {label: '0', value: false}, 
                      {label: '1', value: true}
                    ]} 
                    onChange={(v) => updateFormValue('conf_mtr_pressostato_olio_presente', v)} 
                    value={formValues.conf_mtr_pressostato_olio_presente || ''} />
              </ListItemComponent>
            )}
          </CardComponent>
        )}

        {/* GRUPPO 8 */}
        {/* /////////////////////////////////////////////////////////////////// */}
        {isLevel1 && (
          <CardComponent
            className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
            title={t('irriapp.setup.title_conf_card_gruppo_8')}
          >
            {isLevel1 && ( // INDICE 60
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_modulo_mm_presente')} 
                note={t('irriapp.setup.note_conf_modulo_mm_presente')}
                number={'#A60'}
                onPressInfo={(data) => setNoteEnabled(data)}
              >
                <InputSelectComponent 
                    options={[
                      {label: t('irriapp.setup.list_item_l4_0'), value: 0}, 
                      {label: t('irriapp.setup.list_item_l4_1'), value: 1}
                    ]} 
                    onChange={(v) => updateFormValue('conf_modulo_mm_presente', v)} 
                    value={formValues.conf_modulo_mm_presente || ''} />
              </ListItemComponent>
            )}
            {isLevel3 && ( // INDICE 69
              <ListItemComponent 
                title={t('irriapp.setup.title_conf_avvio_remoto_mm_attivo')} 
                note={t('irriapp.setup.note_conf_avvio_remoto_mm_attivo')}
                number={'#A69'}
                onPressInfo={(data) => setNoteEnabled(data)}
              >
                <InputSelectComponent 
                  options={[
                    {label: t('irriapp.setup.list_item_l13_0'), value: 0}, 
                    {label: t('irriapp.setup.list_item_l13_1'), value: 1},
                    {label: t('irriapp.setup.list_item_l13_2'), value: 2},
                    {label: t('irriapp.setup.list_item_l13_3'), value: 3}
                  ]} 
                  onChange={(v) => updateFormValue('conf_avvio_remoto_mm_attivo', v)} 
                  value={formValues.conf_avvio_remoto_mm_attivo || ''} />
              </ListItemComponent>
            )}
          </CardComponent>
        )}

        <ButtonsGroupComponent sizes={{ default: 24, sm: 24 }} >
          <ButtonComponent label={t('application.cta.confirm')} onClick={onConfirm} disabled={!ctaEnabled} />
        </ButtonsGroupComponent>

      </FixedBarComponent>

      <ModalComponent status={loadingModal}>
        <MessageComponent title={t('application.messages.loading_communication')} extraContent={() =>  <LoadingComponent semifullscreen />} />
      </ModalComponent>
    </div>
  )
}

SetupScreen.propTypes = {
  goBack: PropTypes.func
}

SetupScreen.defaultProps = {
  goBack: () => {}
}
