import React, { useState, useEffect, useContext, useMemo } from "react"
import SectionContentComponent from "./components/SectionContentComponent"
import SectionContentBlockComponent from "./components/SectionContentBlockComponent"
import CardComponent from "./components/CardComponent"
import { LocalesProvider } from "./contexts/LocalesContext"

import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  Line,
  Area,
  ResponsiveContainer,
} from "recharts"

const LABELS = {
  velocita: "Speed",
  qualita: "Quality",
  distanza_semina: "Seed distance",
  singulator: "Singulator",
  superficie_lavorata: "Worked area",
  produttivita: "Productivity",
  doppi: "Doubles",
  mancanti: "Missing",
  investimento: "Investment",
  depressione: "Vacuum",
  rpm: "RPM",
}

const COLORS = {
  velocita: "#f9ca24",
  qualita: "#6ab04c",
  distanza_semina: "#be2edd",
  singulator: "#22a6b3",
  superficie_lavorata: "#00b894",
  produttivita: "#6c5ce7",
  doppi: "#00cec9",
  mancanti: "#0984e3",
  investimento: "#535c68",
  depressione: "#e74c3c",
  rpm: "#2ecc71",
}

const UNITS = {
  velocita: "km/h",
  qualita: "%",
  distanza_semina: "cm",
  singulator: 'pos',
  superficie_lavorata: "m2",
  produttivita: "ha/h",
  doppi: "%",
  mancanti: "%",
  investimento: "kseed/ha",
  depressione: "mbar",
  rpm: "rpm",
}

const B_LABELS = {
  snapshot: "Snapshot",
  events: "Events",
  alarmsIn: "Alarms in",
  alarmsOut: "Alarms out",
}

const B_COLORS = {
  snapshot: "#2c3e50",
  events: "#34495e",
  alarmsIn: "#e74c3c",
  alarmsOut: "#2ecc71",
}

const PAGINATION = 25

function Miplus2Session(props) {
  const [session, setSession] = useState(null)
  const [bar, setBar] = useState(1)
  const [fan, setFan] = useState(1)
  const [lines, setLines] = useState([])
  const [bars, setBars] = useState([])
  const [page, setPage] = useState(1)
  const [viewType, setViewType] = useState('global')

  useEffect(() => {
    fetch(props.data_json_url)
    .then(response => response.json())
    .then(data => {
      setSession(data)
    })
  }, [])

  const chartData = useMemo(() => {
    if (!session) return []
    const _chartData = []
    const timestampCreation = (new Date(props.details.timestamp_creation)).getTime() / 1000
    const timestampEnd = props.details.timestamp_end ? (new Date(props.details.timestamp_end)).getTime() / 1000 : null

    // generate content
    let contents = {}
    // create contents keys to get one key every 30 seconds between timestamp creation and timestamp end
    for (let i = 0; i < (timestampEnd - timestampCreation); i += 30) {
      contents[i] = {
        timestamp: secondsToString(i),
      }
    }

    session.logs[session.serials[0]].forEach((log, index) => {
      const seconds = log.timestamp - timestampCreation
      // generate seconds key to save one content every 30 seconds
      const secondsKey = Math.floor(seconds / 30) * 30
      if (!contents[secondsKey]) {
        contents[secondsKey] = {
          timestamp: secondsToString(secondsKey),
        }
      }

      if (log.event == 'work_data_update') {
        if (lines.includes('velocita')) {
          contents[secondsKey].velocita = round(log.params.velocita_attuale ? (log.params.velocita_attuale / 1000) : 0, 2)
          contents[secondsKey].velocita_note = log.params.velocita_tipo ? 'simulated' : 'real'
        }
        if (lines.includes('qualita')) {
          const value = log.params[`barra_${bar}_qualita_istantaneo`]
          contents[secondsKey].qualita = round(value ? value : 0, 2)
        }
        if (lines.includes('distanza_semina')) {
          const value = log.params[`barra_${bar}_distanza_semina_istantaneo`]
          contents[secondsKey].distanza_semina = round(value ? (value / 10) : 0, 2)

          const target = log.params[`barra_${bar}_distanza_semina`]
          contents[secondsKey].distanza_semina_target = round(target ? (target / 10) : 0, 2) 
        }
        if (lines.includes('singulator')) {
          contents[secondsKey].singulator = round(log.params.singulator ? log.params.singulator : 0, 2)
        }
        if (lines.includes('superficie_lavorata')) {
          contents[secondsKey].superficie_lavorata = round(log.params.superficie_lavorata ? (log.params.superficie_lavorata / 100) : 0, 2)
        }
        if (lines.includes('produttivita')) {
          contents[secondsKey].produttivita = round(log.params.produttivita ? (log.params.produttivita / 10000000) : 0, 2)
        }
        if (lines.includes('doppi')) {
          const value = log.params[`barra_${bar}_doppi_istantaneo`]
          contents[secondsKey].doppi = round(value ? value : 0, 2)
        }
        if (lines.includes('mancanti')) {
          const value = log.params[`barra_${bar}_mancanti_istantaneo`]
          contents[secondsKey].mancanti = round(value ? value : 0, 2)
        }
        if (lines.includes('investimento')) {
          const value = log.params[`barra_${bar}_investimento_istantaneo`]
          contents[secondsKey].investimento = round(value ? (value / 10) : 0, 2)

          const target = log.params[`barra_${bar}_investimento`]
          contents[secondsKey].investimento_target = round(target ? (target / 10) : 0, 2)
        }
        if (lines.includes('depressione')) {
          const value = log.params[`ventola_${fan}_depressione`]
          contents[secondsKey].depressione = round(value ? value : 0, 2)
        }
        if (lines.includes('rpm')) {
          const value = log.params[`ventola_${fan}_rpm`]
          contents[secondsKey].rpm = round(value ? value : 0, 2)
        }
      } else if (log.event == 'work_status_update') {
        contents[secondsKey].events = 1
        if (!contents[secondsKey]._events) contents[secondsKey]._events = []
        contents[secondsKey]._events.push(`Status changed from ${log.params.work_status_from} to ${log.params.work_status_to}`)
      } else if (log.event == 'snapshot') {
        contents[secondsKey].snapshot = 1
        contents[secondsKey]._snapshot = log.params
      } else if (log.event == 'work_alarm_enter') {
        contents[secondsKey].alarmsIn = 1
        if (!contents[secondsKey]._alarmsIn) contents[secondsKey]._alarmsIn = []
        contents[secondsKey]._alarmsIn.push(`UNIT ${log.params.alarmUnit.toString(16)} | CODE ${log.params.code}.${log.params.subcode} | PARAMS ${log.params.params.map((p) => p.toString(16))}`)
      } else if (log.event == 'work_alarm_exit') {
        contents[secondsKey].alarmsOut = 1
        if (!contents[secondsKey]._alarmsOut) contents[secondsKey]._alarmsOut = []
        contents[secondsKey]._alarmsOut.push(`UNIT ${log.params.alarmUnit.toString(16)} | CODE ${log.params.code}.${log.params.subcode} | PARAMS ${log.params.params.map((p) => p.toString(16))}`)
      }
    })

    // add start
    _chartData.push({
      timestamp: secondsToString(0),
      events: 1,
      _events: ['Start work']
    })

    // add content
    Object.keys(contents).forEach(key => {
      _chartData.push(contents[key])
    })

    // add end
    if (timestampEnd) {
      _chartData.push({
        timestamp: secondsToString(timestampEnd - timestampCreation),
        events: 1,
        _events: ['End work']
      })
    }

    return _chartData
  }, [bar, fan, lines, !!session])

  const totalPages = useMemo(() => {
    if (!chartData) return 1
    return Math.ceil(chartData.length / PAGINATION)
  }, [chartData])

  const pageChartData = useMemo(() => {
    if (!chartData) return []
    if (viewType == 'global') return chartData

    const start = (page - 1) * PAGINATION
    const end = start + PAGINATION
    return chartData.slice(start, end)
  }, [chartData, page, viewType])

  const avgData = useMemo(() => {
    const _avgData = {}
    Object.keys(LABELS).forEach(key => {
      let total = 0
      let count = 0
      pageChartData.forEach(data => {
        if (data[key]) {
          total += data[key]
          count++
        }
      })

      _avgData[key] = round(total / count, 2)
    })

    return _avgData
  }, [chartData])

  const TooltipItemAlarms = ({ item }) => {
    if (!item.payload._alarmsIn) return null

    return (
      <div style={{ fontSize: 14, color: item.color }}>
        {item.payload._alarmsIn.map((alarm, index) => (
          <div key={`alarm-${index}`}>{alarm}</div>
        ))}
      </div>
    )
  }

  const TooltipItemEvents = ({ item }) => {
    if (!item.payload._events) return null

    return (
      <div style={{ fontSize: 14, color: item.color }}>
        {item.payload._events.map((event, index) => (
          <div key={`event-${index}`}>{event}</div>
        ))}
      </div>
    )
  }

  const TooltipItemSnapshot = ({ item }) => {
    if (!item.payload._snapshot) return null

    return (
      <div style={{ fontSize: 14, color: item.color }}>
        <div>Data snapshot</div>
        <div style={{ fontSize: 12, color: '#222222', marginTop: 5 }}>
          {Object.keys(item.payload._snapshot).map((key, index) => (
            <div key={`snapshot-${index}`}>{key}: {item.payload._snapshot[key]}</div>
          ))}
        </div>
      </div>
    )
  }

  const TooltipItem = ({ item }) => {
    const renderTarget = () => {
      const target = item.payload[`${item.name}_target`]
      if (!target) return null
      return <span style={{ fontSize: 12, color: '#222222' }}>(target: {target} {UNITS[item.name]})</span>
    }

    const renderNote = () => {
      const note = item.payload[`${item.name}_note`]
      if (!note) return null

      return <span style={{ fontSize: 12, color: '#222222' }}>({note})</span>
    }

    return (
      <div style={{ fontSize: 14, color: item.color, marginTop: 3 }}>
        {LABELS[item.name]}: {item.value} {UNITS[item.name]} {renderTarget()} {renderNote()}
      </div>
    )
  }

  const TooltipContent = ({ active, payload, label }) => {
    if (!active || !label || !payload) return null

    const renderItem = (item) => {
      if (item.name == 'events') return <TooltipItemEvents item={item} />
      if (item.name == 'snapshot') return <TooltipItemSnapshot item={item} />
      if (item.name == 'alarmsIn' || item.name == 'alarmsOut') return <TooltipItemAlarms item={item} />

      return <TooltipItem item={item} />
    }

    return (
      <div style={{ backgroundColor: '#fff', border: 'solid 1px #D8D8D8', borderRadius: 5, padding: '5px 10px' }}>
        <time style={{ fontSize: 12, color: '#222222' }} className="custom-tooltip-time">{label}</time>

        <div style={{ marginTop: 5, paddingTop: 5, borderTop: 'solid 1px #D8D8D8' }}>
          {payload.map((item, index) => (
            <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: 12, color: '#222222', marginBottom: 10 }}>
              {renderItem(item)}
            </div>
          ))}
        </div>
      </div>
    )
  }

  const CheckBox = ({ active, onChange, label, color }) => {
    return (
      <div
        style={{
          cursor: 'pointer',
          opacity: active ? 1 : 0.5,
          transition: 'opacity 0.2s ease',
          padding: '5px 10px',
          borderRadius: 5,
          backgroundColor: active ? color : '#fff',
          color: active ? '#fff' : color,
          border: `solid 1px ${color}`,
          marginRight: 5,
          marginBottom: 5,
          width: '100%',
          textAlign: 'center',
        }}
        onClick={onChange}
      >
        {label}
      </div>
    )
  }

  console.log(pageChartData)

  return (
    <SectionContentComponent>
      <SectionContentBlockComponent sizes={{ default: 24, md: 18 }}>
        <CardComponent
          title={'Session details'}
          className="c-card--hasnext"
          actions={[
            {
              icon: () => <i className="fas fa-tablet"></i>,
              onClick: () => {
                window.open('/miplus2')
              },
            },
          ]}
        >
        <div className="r-conf-resume">
          <div className="r-conf-resume__block">
            <ul className="r-conf-resume__block-list">
              <li>
                <span className="label">Time start</span>
                <span className="value">
                  {new Date(props.details.timestamp_creation).toLocaleString()}
                </span>
              </li>
              <li>
                <span className="label">Timestamp end</span>
                <span className="value">
                  {props.details.timestamp_end ? new Date(props.details.timestamp_end).toLocaleString() : ' - '}
                </span>
              </li>
            </ul>
          </div>
        </div>
        </CardComponent>
        <CardComponent title={'Session history'}>
          <ResponsiveContainer width="100%" height={550}>
            <ComposedChart data={pageChartData}>
              {/* Lines */}
              {lines.map((line) => (
                <Line
                  key={`Line-${line}`}
                  yAxisId={`YAxis-${line}`}
                  dataKey={line}
                  type="linear"
                  stroke={COLORS[line] || "#000000"}
                  name={line}
                  strokeWidth={3}
                  dot={{ strokeWidth: 2 }}
                  connectNulls
                  unit={UNITS[line]}
                />
              ))}
              {lines.map((line) => (
                <YAxis
                  key={`YAxis-${line}`}
                  yAxisId={`YAxis-${line}`}
                  dataKey={line}
                  stroke={COLORS[line] || "#000000"}
                  orientation="right"
                  // domain={[0, 1]}
                />
              ))}

              {/* Bars */}
              {bars.map((bar) => (
                <Bar
                  dataKey={bar}
                  barSize={20}
                  fill={B_COLORS[bar] || "#000000"}
                  yAxisId={`YAxis-bars`}
                />
              ))}
              <YAxis
                key={`YAxis-bars`}
                yAxisId={`YAxis-bars`}
                orientation="right"
                domain={[0, 10]}
                hide
              />

              <Tooltip
                content={<TooltipContent />}
              />
              <XAxis
                dataKey="timestamp"
              />
              <CartesianGrid
                stroke="#efefef"
              />
            </ComposedChart>
          </ResponsiveContainer>
          {totalPages > 1 && viewType == 'zoom' && (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 10,
            }}>
              <button
                style={{
                  color: '#fff',
                  backgroundColor: '#000',
                  border: 'none',
                  padding: '5px 10px',
                  borderRadius: 5,
                  opacity: page == 1 ? 0.5 : 1,
                  cursor: page == 1 ? 'not-allowed' : 'pointer',
                }}
                disabled={page == 1}
                onClick={() => setPage(page - 1)}
              >Prev</button>
              <button
                style={{
                  color: '#fff',
                  backgroundColor: '#000',
                  border: 'none',
                  padding: '5px 10px',
                  borderRadius: 5,
                  opacity: totalPages == page ? 0.5 : 1,
                  cursor: totalPages == page ? 'not-allowed' : 'pointer',
                }}
                disabled={page == totalPages}
                onClick={() => setPage(page + 1)}
              >Next</button>
            </div> 
          )}
        </CardComponent>
      </SectionContentBlockComponent>
      <SectionContentBlockComponent sizes={{ default: 24, md: 6 }}>
        <CardComponent title={'Filters'}>
          <h5 style={{ margin: 0, marginBottom: 10 }}>View type</h5>
          <div style={{ display: 'flex', justifyContent: 'stretch', marginBottom: 10 }}>
            {['global', 'zoom'].map((index) => (
              <CheckBox
                key={`viewtype-${index}`}
                active={viewType == index}
                onChange={() => setViewType(index)}
                label={index.toUpperCase()}
                color={'#000'}
              />
            ))}
          </div>
          <h5 style={{ margin: 0, marginBottom: 10 }}>Boom</h5>
          <div style={{ display: 'flex', justifyContent: 'stretch', marginBottom: 10 }}>
            {[1, 2].map((index) => (
              <CheckBox
                key={`bar-${index}`}
                active={bar == index}
                onChange={() => setBar(index)}
                label={`Boom ${index}`}
                color={'#000'}
              />
            ))}
          </div>
          <h5 style={{ margin: 0, marginBottom: 10 }}>Fan</h5>
          <div style={{ display: 'flex', justifyContent: 'stretch', marginBottom: 10 }}>
            {[1, 2, 3, 4, 5].map((index) => (
              <CheckBox
                key={`fan-${index}`}
                active={fan == index}
                onChange={() => setFan(index)}
                label={`Fan ${index}`}
                color={'#000'}
              />
            ))}
          </div>
          <h5 style={{ margin: 0, marginBottom: 10 }}>Lines</h5>
          <div style={{ display: 'flex', justifyContent: 'stretch', flexWrap: 'wrap', marginBottom: 10 }}>
            {Object.keys(LABELS).map((key) => (
              <CheckBox
                key={`data-${key}`}
                active={lines.includes(key)}
                onChange={() => setLines(lines.includes(key) ? lines.filter((line) => line != key) : [...lines, key])}
                label={`${LABELS[key]} ${lines.includes(key) ? `(AVG: ${avgData[key] || 0} ${UNITS[key]})` : ''}`}
                color={COLORS[key]}
              />
            ))}
          </div>
          <h5 style={{ margin: 0, marginBottom: 10 }}>Bars</h5>
          <div style={{ display: 'flex', justifyContent: 'stretch', flexWrap: 'wrap', marginBottom: 10 }}>
            {Object.keys(B_LABELS).map((key) => (
              <CheckBox
                key={`data-${key}`}
                active={bars.includes(key)}
                onChange={() => setBars(bars.includes(key) ? bars.filter((bar) => bar != key) : [...bars, key])}
                label={B_LABELS[key]}
                color={B_COLORS[key]}
              />
            ))}
          </div>
        </CardComponent>
      </SectionContentBlockComponent>
    </SectionContentComponent>
  )
}

export default (props) => {
  return (
    <LocalesProvider>
      <Miplus2Session {...props} />
    </LocalesProvider>
  )
}

// HELPERS
////////////////////////////////////////////////////////////////////////////////

function secondsToString(seconds) {
  const date = new Date(0)
  date.setSeconds(seconds)
  return date.toISOString().substr(11, 8)
}

function round(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
}