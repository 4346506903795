import React, { useState } from "react";
import PropTypes from "prop-types";

export default function InputSelectComponent(props) {
  const optionsDefault = props.placeholder
    ? [{ value: "", label: props.placeholder, placeholder: true }]
    : [];
  const options = optionsDefault.concat(props.options);

  return (
    <div className={`c-input ${props.className}`}>
      <label className="c-input__label">{props.label}</label>
      <select
        className="c-input__field"
        value={props.value}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e.target.value)}
        disabled={props.disabled}
      >
        {options.map((option) => {
          return (
            <option
              key={option.value}
              value={option.value}
              disabled={option.placeholder}
            >
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
}

InputSelectComponent.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  disabled: PropTypes.bool,
};

InputSelectComponent.defaultProps = {
  className: "",
  options: [],
};
