import React, { useState } from "react"
import PropTypes from "prop-types"

export default function CheckboxComponent(props) {
  let className = `c-checkbox__button ${props.className}`;

  return (
    <div className="c-checkbox" onClick={() => props.onChange(!props.value)}>
      <button className={className}>
        {props.value ? (
          <i className="fas fa-check-square"></i>
        ) : (
          <i className="far fa-square"></i>
        )}
      </button>
      <div>{props.label}</div>
    </div>
  );
}

CheckboxComponent.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

CheckboxComponent.defaultProps = {
  className: "",
  label: "",
};
