import React, { useState, useEffect, useContext } from "react";
import { LocalesProvider, LocalesContext } from "./contexts/LocalesContext";
import { MachineProvider, MachineContext } from "./contexts/MachineContext";
import SectionContentComponent from "./components/SectionContentComponent";
import SectionContentBlockComponent from "./components/SectionContentBlockComponent";
import ActionbarComponent from "./components/ActionbarComponent";
import LoadingComponent from "./components/LoadingComponent";
import DashboardNav from "./miplus/navs/DashboardNav";
import PresetsNav from "./miplus/navs/PresetsNav";
import SetupNav from "./miplus/navs/SetupNav";
import DiagnosticNav from "./miplus/navs/DiagnosticNav";
import RealtimeComponent from "./miplus/components/RealtimeComponent";
import {
  SvgWork,
  SvgPreset,
  SvgSetup,
  SvgDiagnostic,
} from "./components/SvgIcons";

const navsMap = {
  dashboard: DashboardNav,
  presets: PresetsNav,
  setup: SetupNav,
  diagnostic: DiagnosticNav,
};

function Miplus(props) {
  const { t, actions: localesActions } = useContext(LocalesContext);
  const { machineParams } = useContext(MachineContext);
  const [currentScreen, setCurrentScreen] = useState("dashboard");
  const [loadingMachine, setLoadingMachine] = useState(true);

  // EFFECTS ******************************************************************

  // inizializzazione
  useEffect(() => {
    localesActions.setLocale(props.locale);
    localesActions.setTranslations(props.translations);
    setLoadingMachine(false);
  }, []);

  // RENDER *******************************************************************

  const actionbarActions = [
    {
      label: t("application.app_actionbar.work"),
      active: currentScreen === "dashboard",
      onClick: () => setCurrentScreen("dashboard"),
      icon: <SvgWork />,
    },
    {
      label: t("application.app_actionbar.presets"),
      active: currentScreen === "presets",
      onClick: () => setCurrentScreen("presets"),
      icon: <SvgPreset />,
    },
    {
      label: t("application.app_actionbar.setup"),
      active: currentScreen === "setup",
      onClick: () => setCurrentScreen("setup"),
      icon: <SvgSetup />,
      disabled: !machineParams || !machineParams.setup ? true : false,
    },
    {
      label: t("application.app_actionbar.diagnostic"),
      active: currentScreen === "diagnostic",
      onClick: () => setCurrentScreen("diagnostic"),
      icon: <SvgDiagnostic />,
      disabled: !machineParams || !machineParams.diagnostic ? true : false,
    },
  ];

  const CurrentNavComponent = navsMap[currentScreen];

  if (loadingMachine) {
    return <LoadingComponent fullscreen />;
  } else {
    return (
      <>
        <SectionContentComponent className="r-app-structure">
          <SectionContentBlockComponent
            className="r-app-structure__bar"
            sizes={{ default: 24, md: 6 }}
          >
            <ActionbarComponent actions={actionbarActions} />
          </SectionContentBlockComponent>
          <SectionContentBlockComponent
            className="r-app-structure__content"
            sizes={{ default: 24, md: 18 }}
          >
            <div className="r-app-structure__content-head">
              <RealtimeComponent {...props} />
            </div>
            <div className="r-app-structure__content-main">
              <CurrentNavComponent {...props} />
            </div>
          </SectionContentBlockComponent>
        </SectionContentComponent>
      </>
    );
  }
}

export default (props) => {
  return (
    <LocalesProvider>
      <MachineProvider apis={props.apis} machineUuid={props.machine_uuid}>
        <Miplus {...props} />
      </MachineProvider>
    </LocalesProvider>
  );
};
