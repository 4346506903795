import React, { useContext, useState } from "react";
import { MachineContext } from "../../contexts/MachineContext";
import CardComponent from "../../components/CardComponent";
import { gridSizesClassName } from "../../utils/gridSizes";
import { datetimeToPrettyString } from "../../utils/data";
import { getMachineParamsStatusValue } from "../../utils/miplus";
import { LocalesContext } from "../../contexts/LocalesContext";

export default function RealtimeComponent(props) {
  const { machineName, machineParams, machineRtStatus } =
    useContext(MachineContext);
  const { t } = useContext(LocalesContext);

  let status = machineRtStatus;
  let classNameStatus = "";
  let labelDataStatus =
    machineParams && machineParams.statLastUsage
      ? `Last usage: ${datetimeToPrettyString(
          new Date(machineParams.statLastUsage)
        )}`
      : "Not used yet";
  let labelAction = "";
  let functionAction = null;

  if (status == "connected") {
    classNameStatus = "is-connected";
    if (machineParams && machineParams.diagnostic) {
      labelDataStatus = `${labelDataStatus} - Total worked area: ${machineParams.diagnostic.superficieTotale} ha`;
    }
  } else if (status == "disconnected") {
    classNameStatus = "is-disconnected";
    labelDataStatus = t("application.connestion_statuses.disconnected");
  } else if (status == "working") {
    classNameStatus = "is-working";
    if (machineParams && machineParams.diagnostic) {
      labelDataStatus = `${labelDataStatus} - Total worked area: ${machineParams.diagnostic.superficieTotale} ha`;
    }
  }

  return (
    <CardComponent
      className={`r-realtime ${gridSizesClassName({ defaut: 24 })}`}
    >
      <div className={`r-realtime__status ${classNameStatus}`}></div>
      <div className="r-realtime__container">
        <div className="r-realtime__data">
          <div className="r-realtime__data-name">{machineName}</div>
          <div className="r-realtime__data-status">{labelDataStatus}</div>
        </div>
        {labelAction && functionAction && (
          <button
            className="r-realtime__action c-button"
            onClick={functionAction}
            disabled={!ctaEnabled}
          >
            {labelAction}
          </button>
        )}
      </div>
    </CardComponent>
  );
}
