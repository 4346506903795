import React, { useState } from 'react'
import PropTypes from 'prop-types'

export default function InputTextComponent(props) {
  return (
    <div className={`c-input ${props.className}`}>
      <label className="c-input__label">{props.label}</label>
      <input className="c-input__field" type={props.type} value={props.value} placeholder={props.placeholder} min={props.min} onChange={(e) => props.onChange(e.target.value)} />
    </div>
  )
}

InputTextComponent.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  min: PropTypes.string
}

InputTextComponent.defaultProps = {
  className: '',
  type: 'text'
}
