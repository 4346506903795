import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import HeadComponent from '../../components/HeadComponent'
import CardComponent from '../../components/CardComponent'
import InputSelectComponent from '../../components/InputSelectComponent'
import ButtonsGroupComponent from '../../components/ButtonsGroupComponent'
import ButtonComponent from '../../components/ButtonComponent'
import ListItemComponent from '../../components/ListItemComponent'
import InputNumberComponent from '../../components/InputNumberComponent'
import { LocalesContext } from '../../contexts/LocalesContext'
import { MqttContext } from '../../contexts/MqttContext'
import { gridSizesClassName } from '../../utils/gridSizes'
import { getRequest } from '../../utils/requests'
import { getMachineParamsValue, getPresetSectorsLength, unitaMisuraIntToString } from '../../utils/irriapp'
import { objValueOrDefault } from '../../utils/data'
 
export default function ConfigurationScreen(props) {
  const { t } = useContext(LocalesContext)
  const { machineParams } = useContext(MqttContext)
  const [profiles, setProfiles] = useState(null)
  const [clients, setClients] = useState(null)
  const [fields, setFields] = useState(null)
  const [presets, setPresets] = useState(null)
  const [formValid, setFormValid] = useState(false)
  const [formValues, setFormValues] = useState({
    profile_uuid: objValueOrDefault(props, ['params', 'profile_uuid'], ''),
    client_uuid: objValueOrDefault(props, ['params', 'client_uuid'], ''),
    field_uuid: objValueOrDefault(props, ['params', 'field_uuid'], ''),
    preset_uuid: objValueOrDefault(props, ['params', 'preset_uuid'], ''),
    tube_length: objValueOrDefault(props, ['params', 'tube_length'], (machineParams.work_tube_length ? machineParams.work_tube_length.value : 100))
  })

  // EFFECTS
  /////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    loadProfiles()
    loadClients()
    loadPresets()
    if (formValues.client_uuid) loadClientFields(formValues.client_uuid)
  }, [])

  useEffect(() => {
    if (formValues.tube_length > 0) {
      setFormValid(true)
    } else {
      setFormValid(false)
    }
  }, [formValues])

  // FUNCTIONS
  /////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Funzione eseguita alla conferma della macchina selezionata.
   */
  const onConfirm = () => {
    const preset = presets.filter((p) => p.uuid == formValues.preset_uuid)[0]

    if (!preset) {
      props.goTo('editor', { type: 'configuration_new', params: Object.assign({}, formValues) })
    } else if (getPresetSectorsLength(preset.params) !== formValues.tube_length) {
      props.goTo('editor', { type: 'configuration_new', params: Object.assign(preset.params, formValues) })
    } else {
      props.goTo('configurationConfirm', { params: Object.assign(preset.params, formValues) })
    }
  }

  /**
   * Funzione alla selezione di un profilo.
   */
  const onSelectProfile = (profileUuid) => {
    updateFormValue('profile_uuid', profileUuid)
  }

  /**
   * Funzione alla selezione di un cliente.
   */
  const onSelectClient = (clientUuid) => {
    updateFormValue('client_uuid', clientUuid)
    loadClientFields(clientUuid)
  }

  /**
   * Funzione alla selezione di un campo.
   */
  const onSelectField = (fieldUuid) => {
    updateFormValue('field_uuid', fieldUuid)
  }

  /**
   * Funzione alla selezione di un preset.
   */
  const onSelectPreset = (presetUuid) => {
    updateFormValue('preset_uuid', presetUuid)
  }

  /**
   * Funzione eseguita per aggiornare un valore del form.
   * @param {*} name 
   * @param {*} value 
   */
  const updateFormValue = (name, value) => {
    const newFormValues = Object.assign({}, formValues)
    newFormValues[name] = value
    setFormValues(newFormValues)
  }

  /**
   * Esegue il caricamento dei campi dei profili.
   */
  const loadProfiles = () => {
    getRequest(props.apis.profiles_index_api, { apis_auth_token: props.apis.auth_token }, (response) => {
      if (!response.result) return
      setProfiles(response.payload.profiles.map((d) => Object.assign(d, { label: d.name, value: d.uuid })))
    })
  }

  /**
   * Esegue il caricamento dei clienti.
   */
  const loadClients = () => {
    getRequest(props.apis.clients_index_api, { apis_auth_token: props.apis.auth_token }, (response) => {
      if (!response.result) return
      setClients(response.payload.clients.map((d) => Object.assign(d, { label: d.name, value: d.uuid })))
    })
  }

  /**
   * Esegue il caricamento dei campi di un cliente specifico.
   * @param {*} clientUuid 
   */
  const loadClientFields = (clientUuid) => {
    getRequest(props.apis.fields_index_api, { apis_auth_token: props.apis.auth_token, machine_uuid: props.machine_uuid, client_uuid: clientUuid }, (response) => {
      if (!response.result) return
      setFields(response.payload.fields.map((d) => ({ label: d.name, value: d.uuid })))
    })
  }

  /**
   * Esegue il caricamento dei presets.
   */
  const loadPresets = () => {
    getRequest(props.apis.presets_index_api, { apis_auth_token: props.apis.auth_token, machine_uuid: props.machine_uuid }, (response) => {
      if (!response.result) return
      setPresets(response.payload.presets.map((d) => Object.assign(d, { label: d.name, value: d.uuid })).concat([{ label: t('application.titles.configuration_new'), value: '' }]))
    })
  }

  // RENDER
  /////////////////////////////////////////////////////////////////////////////////////////////

  const maxLength = getMachineParamsValue(machineParams, 'work_tube_max_length') || 5000

  return (
    <div className="c-grid__container-spaced">
      <HeadComponent title={t('application.titles.configuration')} onBack={props.goBack} />

      <CardComponent title={t('application.titles.configuration_new')} className={`c-card--hasnext ${gridSizesClassName({ default: 24 })}`}>
        <ListItemComponent title={t('application.labels.select_preset')}>
          <InputSelectComponent options={presets || []} onChange={onSelectPreset} value={formValues.preset_uuid} disabled={!presets} />
        </ListItemComponent>
        <ListItemComponent title={t('application.labels.select_profile')}>
          <InputSelectComponent placeholder={t('application.cta.tap_to_select')} options={profiles || []} onChange={onSelectProfile} value={formValues.profile_uuid} disabled={!profiles} />
        </ListItemComponent>
        <ListItemComponent title={t('application.labels.select_client')}>
          <InputSelectComponent placeholder={t('application.cta.tap_to_select')} options={clients || []} onChange={onSelectClient} value={formValues.client_uuid} disabled={!clients} />
        </ListItemComponent>
        <ListItemComponent title={t('application.labels.select_field')}>
          <InputSelectComponent placeholder={t('application.cta.tap_to_select')} options={fields || []} onChange={onSelectField} value={formValues.field_uuid} disabled={!fields} />
        </ListItemComponent>
        <ListItemComponent title={t('irriapp.titles.unrolling_pipe')}>
          <InputNumberComponent unit='m' value={formValues.tube_length} converterType={unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura"))} onChange={(v) => updateFormValue('tube_length', v)} min={1} max={maxLength} />
        </ListItemComponent>
      </CardComponent>

      <ButtonsGroupComponent sizes={{ default: 24, sm: 24 }} >
        <ButtonComponent label={t('application.cta.continue')} onClick={onConfirm} disabled={!formValid} />
        <ButtonComponent label={t('application.cta.cancel')} onClick={() => props.goBack()} warning />
      </ButtonsGroupComponent>
    </div>
  )
}

ConfigurationScreen.propTypes = {
  goBack: PropTypes.func
}

ConfigurationScreen.defaultProps = {
  goBack: () => {}
}
