import React, { useContext, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { LocalesContext } from "../../contexts/LocalesContext";
import HeadComponent from "../../components/HeadComponent";
import ConfResumeComponent from "../components/ConfResumeComponent";
import CardComponent from "../../components/CardComponent";
import InputTextComponent from "../../components/InputTextComponent";
import ButtonsGroupComponent from "../../components/ButtonsGroupComponent";
import ButtonComponent from "../../components/ButtonComponent";
import { gridSizesClassName } from "../../utils/gridSizes";
import { flashDanger } from "../../utils/flash";
import { postRequest } from "../../utils/requests";
import { uuidv4 } from "../../utils/data";

export default function PresetsConfirmScreen(props) {
  const isEdit = props.preset && props.preset.uuid;
  const [name, setName] = useState(props.preset.name || "");
  const { t } = useContext(LocalesContext);

  const onConfirm = () => {
    if (!name) {
      flashDanger(t("application.messages.name_not_compiled"));
      return;
    }

    if (isEdit) {
      postRequest(
        props.apis.presets_manage_actions_action,
        {
          apis_auth_token: props.apis.auth_token,
          actions: [
            {
              uuid: props.preset.uuid,
              type: "update",
              timestamp: new Date(),
              data: { name, params: props.preset.params },
            },
          ],
        },
        (response) => {
          if (response.result) {
            props.goTo("presets");
          }
        }
      );
    } else {
      postRequest(
        props.apis.presets_create_action,
        {
          apis_auth_token: props.apis.auth_token,
          uuid: uuidv4(),
          name,
          machine_uuid: props.machine_uuid,
          timestamp_creation: new Date(),
          params: props.preset.params,
        },
        (response) => {
          if (response.result) {
            props.goTo("presets");
          }
        }
      );
    }
  };

  const onRemove = () => {
    postRequest(
      props.apis.presets_manage_actions_action,
      {
        apis_auth_token: props.apis.auth_token,
        actions: [{ uuid: props.preset.uuid, type: "remove" }],
      },
      (response) => {
        if (response.result) {
          props.goTo("presets");
        }
      }
    );
  };

  return (
    <div className="c-grid__container-spaced">
      <HeadComponent
        title={t("application.titles.presets_confirm")}
        onBack={() =>
          props.goBack({
            type: props.type,
            params: props.preset.params,
            preset: props.preset,
          })
        }
      />

      <CardComponent
        className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
        title={t("application.titles.configuration_resume")}
      >
        <ConfResumeComponent configuration={props.preset.params} />
      </CardComponent>

      <CardComponent
        className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
        title={t("application.titles.presets_name")}
      >
        <InputTextComponent
          value={name}
          onChange={(v) => setName(v)}
        />
      </CardComponent>

      <ButtonsGroupComponent sizes={{ default: 24, sm: 24 }}>
        <ButtonComponent
          label={t("application.cta.confirm")}
          onClick={onConfirm}
        />
        <ButtonComponent
          label={t("application.cta.cancel")}
          confirm={t("application.messages.confirm_cancel")}
          onClick={() => props.goTo("presets")}
          warning
        />
        {isEdit && (
          <ButtonComponent
            label={t("application.cta.remove")}
            confirm={t("application.messages.confirm_remove")}
            onClick={onRemove}
            danger
          />
        )}
      </ButtonsGroupComponent>
    </div>
  );
}

PresetsConfirmScreen.propTypes = {
  goBack: PropTypes.func,
};

PresetsConfirmScreen.defaultProps = {
  goBack: () => {},
};
