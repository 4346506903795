import React, { useContext } from "react";
import PropTypes from "prop-types";
import { LocalesContext } from '../contexts/LocalesContext'

export default function HistoricalComponent({ data }) {
  const { t } = useContext(LocalesContext)

  return (
    <div className="r-history">
      {data && data.length != 0 ? (
        data.map((d, i) => {
          return (
            <div key={i} className="r-history__item">
              <div className="r-history__item-value">
                {d.value}
                {d.subvalue && (
                  <>
                    <br />
                    <small>{d.subvalue}</small>
                  </>
                )}
              </div>
              <div className="r-history__item-date">{d.date}</div>
            </div>
          );
        })
      ) : (
        <div className="r-history__empty">{t("application.messages.no_data_to_show")}</div>
      )}
    </div>
  );
}

HistoricalComponent.propTypes = {
  data: PropTypes.array,
};

HistoricalComponent.defaultProps = {
  data: [],
};
