import React from "react";
import PropTypes from "prop-types";

export default function ButtonComponent(props) {
  let className = `c-button ${props.className}`;
  if (props.block) className = `${className} c-button--block`;
  if (props.danger) className = `${className} c-button--orange`;
  if (props.warning) className = `${className} c-button--yellow`;
  if (props.success) className = `${className} c-button--green`;

  const onClick = () => {
    if (props.confirm && !confirm(props.confirm)) return;
    props.onClick();
  };

  return (
    <button className={className} onClick={onClick} disabled={props.disabled}>
      {props.label}
      {props.icon && <i className={props.icon}></i>}
    </button>
  );
}

ButtonComponent.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  block: PropTypes.bool,
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  warning: PropTypes.bool,
  success: PropTypes.bool,
  confirm: PropTypes.string,
  icon: PropTypes.string,
};

ButtonComponent.defaultProps = {
  className: "",
  label: "",
  block: false,
  onClick: () => {},
};
