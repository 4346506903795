import React, { useState } from 'react'

export default function useNav (screens, defaultScreenName) {
  const [currentScreenData, setCurrentScreenData] = useState({ name: defaultScreenName, props: {} })
  const [history, setHistory] = useState([defaultScreenName])

  const goTo = (screenName, screenProps = {}) => {
    setCurrentScreenData({ name: screenName, props: screenProps })
    setHistory(history.concat([screenName]))
  }

  const goBack = (screenProps = {}) => {
    const prevView = history[history.length - 2]
    if (prevView) {
      setCurrentScreenData({ name: prevView, props: screenProps })
      setHistory(history.slice(0, -1))
    }
  }

  const getCurrentScreen = (props) => {
    const Screen = screens[currentScreenData.name]
    const screenProps = Object.assign({}, props, currentScreenData.props)
    return <Screen goTo={goTo} goBack={goBack} {...screenProps}  />
  }

  return [currentScreenData.name, currentScreenData.props, getCurrentScreen]
}
