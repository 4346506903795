import React from 'react'
import PropTypes from 'prop-types'
import CardComponent from './CardComponent'
import { gridSizesProptypes, gridSizesClassName } from '../utils/gridSizes'

export default function IconComponent(props) {
  return (
    <CardComponent className={`r-icon ${gridSizesClassName(props.sizes)} ${props.danger ? 'is-danger' : ''}`}>
      <div className="r-icon__icon">
        <span className="icon">{props.icon}</span>
      </div>

      <div className="r-icon__main">
        <div className="r-icon__label">{props.label}</div>
      </div>
    </CardComponent>
  )
}

IconComponent.propTypes = {
  sizes: gridSizesProptypes(),
  label: PropTypes.string,
  icon: PropTypes.object,
  danger: PropTypes.bool
}

IconComponent.defaultProps = {
  sizes: {}
}
