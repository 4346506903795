import React from 'react'
import PropTypes from 'prop-types'
import { gridSizesProptypes, gridSizesClassName } from '../utils/gridSizes'

export default function HeadComponent(props) {
  let className = `r-head ${gridSizesClassName(props.sizes)}`

  return (
    <div className={className}>
      <div className="r-head__content">
        {props.onBack && <button className="r-head__back c-button c-button--cenere" onClick={props.onBack}><i className="fas fa-arrow-left"></i></button>}
        <h2 className="r-head__title">{props.title}</h2>
      </div>
    </div>
  )
}

HeadComponent.propTypes = {
  sizes: gridSizesProptypes(),
  title: PropTypes.string,
  onBack: PropTypes.func
}

HeadComponent.defaultProps = {
  sizes: {},
  title: ''
}
