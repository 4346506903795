import PropTypes from 'prop-types'

export function gridSizesProptypes() {
  return PropTypes.shape({
    default: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number
  })
}

export function gridSizesClassName(sizesProps, defaultClassName = '') {
  let className = `${defaultClassName} c-grid__col`
  if (!sizesProps) return className

  if (sizesProps.default) className += ` c-grid__col--${sizesProps.default}`
  if (sizesProps.sm) className += ` c-grid__col--sm-${sizesProps.sm}`
  if (sizesProps.md) className += ` c-grid__col--md-${sizesProps.md}`

  return className
}
