export function convertValue(value, unit, type = 'eu') {
  let valueOut = value

  if (type == 'uk' || type == 'us') {
    switch (unit) {
      case 'm':
        valueOut = valueOut * 3281
        valueOut = valueOut / 1000
        break
      case 'm/h':
        valueOut = valueOut * 3281
        valueOut = valueOut / 1000
        break
      case 'atm':
        valueOut = valueOut * 1470
        valueOut = valueOut / 100
        break
      case 'mc/h':
        valueOut = valueOut * (type == 'uk' ? 3666 : 4403)
        valueOut = valueOut / 1000
        break
      case 'mm':
        valueOut = valueOut * 3937
        valueOut = valueOut / 1000
        break
      case 'mc':
        valueOut = valueOut * (type == 'uk' ? 220 : 264)
        break
    }
  }

  return valueOut
}

export function convertUnit(unit, type = 'eu') {
  let unitOut = unit

  if (type == 'uk' || type == 'us') {
    switch (unit) {
      case 'm':
        unitOut = 'ft'
        break
      case 'm/h':
        unitOut = 'ft/h'
        break
      case 'atm':
        unitOut = 'psi'
        break
      case 'mc/h':
        unitOut = 'gpm'
        break
      case 'mm':
        unitOut = 'pt'
        break
      case 'mc':
        unitOut = 'gal'
        break
    }
  }

  return unitOut
}