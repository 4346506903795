import '../../../vendor/matilda_core/loader_assets.js'

import 'mapbox-gl/dist/mapbox-gl.css'
import 'tippy.js/dist/tippy.css'
import '../style/main.scss'
import '../scripts/main.js'

// Includo componenti React
const componentRequireContext = require.context("react", true)
const ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)
