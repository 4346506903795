import React from "react";
import PropTypes from "prop-types";
import ButtonComponent from "./ButtonComponent";

export default function InputLabelButtonComponent(props) {
  // RENDER
  /////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="r-input-label-button">
      <div className="r-input-label-button__label">{props.label}</div>
      <div className="r-input-label-button__button">
        <ButtonComponent
          label={props.btnCta}
          onClick={() => props.btnOnClick()}
        />
      </div>
    </div>
  );
}

InputLabelButtonComponent.propTypes = {
  btnOnClick: PropTypes.func,
  label: PropTypes.string,
  btnCta: PropTypes.string,
};

InputLabelButtonComponent.defaultProps = {};
