import React, { useEffect, useRef, useState } from 'react'
import mqtt from 'mqtt'
import { getRequest } from '../utils/requests'
import { stringFromArrayOfBytes } from '../utils/data'

let MQTTListeners = {}

export const MqttContext = React.createContext()

export function MqttProvider (props) {
  const [status, setStatus] = useState(null) // stato connessione con il broker
  const [credentials, setCredentials] = useState(null) // credenziali attuali
  const [client, setClient] = useState(null) // client attuale
  const [lastMessage, setLastMessage] = useState(null) // ultimo messaggio ricevuto
  const [machineParams, setMachineParams] = useState(null) // dati macchina attuale
  const [machineName, setMachineName] = useState(null) // nome macchine attuale
  const [machineRtStatus, setMachineRtStatus] = useState(null) //status realtime macchina attuale

  // EFFECTS
  /////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => { // esecuzione iniziale
    getMachineParams()
    setInterval(() => getMachineParams(() => {}, false), 60000)
  }, [])

  useEffect(() => { // gestore della connessione
    if (credentials && client && status === null) {

      const connectionTimeout = setTimeout(() => {
        if (status) return
        setStatus(false)
      }, 15000)

      client.on('error', (err) => {
        console.error('Connection error: ', err)
        client.end()

        setClient(null)
        setCredentials(null)
        setStatus(false)
      })
  
      client.on('connect', () => {
        client.subscribe(`T${credentials.mqtt_username}`, (err) => {
          if (err) {
            console.log(err)
            return
          }

          client.subscribe(`FJSON${props.machineMqtt}`, (err) => {
            if (err) {
              console.log(err)
              return
            }
  
            clearTimeout(connectionTimeout)
            setStatus(true)
          })
        })
      })
  
      client.on('message', (topic, message) => {
        try {
          const messageJson = JSON.parse(stringFromArrayOfBytes(Array.prototype.slice.call(message, 0)))
          console.log('<---', messageJson)
          setLastMessage(messageJson)
        } catch {
          console.log('errore messaggio', message)
        }
      })
    }
  }, [credentials, client, status])

  useEffect(() => { // gestore dei messaggi
    if (lastMessage) {
      Object.keys(MQTTListeners).map((listenerId) => {
        const listener = MQTTListeners[listenerId]
        if (listener.type === lastMessage.type) {
          listener.callback(lastMessage, machineParams, machineRtStatus)
          if (listener.oneTime) delete MQTTListeners[listenerId]
        }
      })

      setLastMessage(null)
    }
  }, [lastMessage, machineParams])

  // FUNCTIONS
  /////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Esegue la registrazione delle credenziali e si connette alla macchina.
   * @param {*} connectCredentials 
   */
  const connect = (connectCredentials) => {
    setStatus(null)
    setCredentials(connectCredentials)
    setClient(mqtt.connect(`wss://${connectCredentials.mqtt_host}`, { port: 1884, username: connectCredentials.mqtt_username, password: connectCredentials.mqtt_password }))
  }

  /**
   * Specifica lo stato di connessione tra i possibili :loading, :disconnected, :connected
   * @param {*} statusString 
   */
  const connectStatus = (statusString) => {
    const map = { loading: null, disconnected: false, connected: true }
    return map[statusString] === status
  }

  /**
   * Invia un messaggio alla macchina.
   * @param {*} type 
   * @param {*} payload 
   * @param {*} callback 
   */
  const sendMessage = (type, payload, callback = () => {}) => {
    const message = { receiver: props.machineMqtt, type, payload }
    console.log('--->', message)
    client.publish(`F${credentials.mqtt_username}`, JSON.stringify(message), {}, callback)
  }

  /**
   * Invia un messaggio alla macchina attendendo una risposta.
   * @param {*} type 
   * @param {*} payload 
   * @param {*} answerType 
   * @param {*} callback 
   * @param {*} timeout 
   */
  const sendMessageWithAnswer = (type, payload, answerType, callback = () => {}, timeout = 15000) => {
    sendMessage(type, payload, () => {
      let answered = false
      let listenerId = addMessageListener(answerType, (msg) => {
        answered = true
        callback(msg)
      })
      setTimeout(() => {
        if (!answered) {
          removeMessageListener(listenerId)
          callback(null)
        }
      }, timeout)
    })
  }

  /**
   * Aggiunge un ascoltatore da eseguire quando si riceve un messaggio dalla macchina.
   * @param {*} messageType 
   * @param {*} callback 
   * @param {*} oneTime 
   */
  const addMessageListener = (type, callback = () => {}, oneTime = true) => {
    const listenerId = Math.random().toString(36).substring(7)
    MQTTListeners[listenerId] = { type, callback, oneTime }
    return listenerId
  }

  /**
   * Rimuove un ascoltatore dalla lista ascoltatori messaggi.
   * @param {*} listenerId 
   */
  const removeMessageListener = (listenerId) => {
    delete MQTTListeners[listenerId]
  }

  /**
   * Ritorna i parametri della macchina aggiornati dal server.
   * @param {*} callback 
   */
  const getMachineParams = (callback = () => {}, saveParams = true) => {
    getRequest(props.apis.machines_show_api, { apis_auth_token: props.apis.auth_token, uuid: props.machineUuid }, (response) => {
      if (response.result) {
        const params = Object.assign({}, response.payload.machine.params) // TEMP: Togliere connection a true. , { connection: true }
        setMachineName(response.payload.machine.name)
        setMachineRtStatus(response.payload.machine.rt_status)
        if (saveParams) setMachineParams(params)
        callback(params)
      } else {
        callback(null)
      }
    })
  }

  // RENDER
  /////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <MqttContext.Provider value={{
      status,
      machineParams,
      machineName,
      machineRtStatus,
      actions: {
        connect,
        connectStatus,
        getMachineParams,
        sendMessage,
        sendMessageWithAnswer,
        addMessageListener,
        removeMessageListener,
        setMachineParams,
        setMachineRtStatus
      }
    }} >
      {props.children}
    </MqttContext.Provider>
  )
}
