import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { LocalesContext } from "../../contexts/LocalesContext";
import { MachineContext } from "../../contexts/MachineContext";
import HeadComponent from "../../components/HeadComponent";
import CardComponent from "../../components/CardComponent";
import ListItemComponent from "../../components/ListItemComponent";
import InputNumberComponent from "../../components/InputNumberComponent";
import InputSelectComponent from "../../components/InputSelectComponent";
import InputSwitchComponent from "../../components/InputSwitchComponent";
import FixedBarComponent from "../../components/FixedBarComponent";
import ButtonsGroupComponent from "../../components/ButtonsGroupComponent";
import ButtonComponent from "../../components/ButtonComponent";
import { gridSizesClassName } from "../../utils/gridSizes";
import { postRequest } from "../../utils/requests";
import { datetimeToPrettyString } from '../../utils/data'

export default function SetupScreen(props) {
  const isAdmin =
    props.permissions.includes("assistance.setup_3") ||
    props.permissions.includes("assistance.setup_2")
  const { t } = useContext(LocalesContext);
  const {
    machineParams,
    actions: { getMachineParams },
  } = useContext(MachineContext);

  const [formValues, setFormValues] = useState({});

  // EFFECTS
  /////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (machineParams.newSetup) {
      // aggiorna setup con i campi di new setup
      setFormValues(machineParams.newSetup);
    } else {
      setFormValues(machineParams.setup);
    }
  }, [machineParams]);

  // FUNCTIONS
  /////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Funzione eseguita alla conferma di invio delle modifiche alla macchina.
   */
  const onConfirm = () => {
    postRequest(
      props.apis.machines_manage_actions_action,
      {
        apis_auth_token: props.apis.auth_token,
        actions: [
          {
            uuid: props.machine_uuid,
            type: "update",
            timestamp: new Date(),
            data: { params: { newSetup: formValues } },
          },
        ],
      },
      (response) => {
        if (response.result) {
          getMachineParams(() => {}, true);
          // props.goTo("setup");
        }
      }
    );
  };

  /**
   * Funzione eseguita per aggiornare un valore del form
   * @param {*} name
   * @param {*} value
   */
  const updateFormValue = (name, value, number = false) => {
    const newFormValues = Object.assign({}, formValues);
    if (number) {
      newFormValues[name] = parseFloat(value);
    } else {
      newFormValues[name] = value;
    }
    setFormValues(newFormValues);
  };

  // RENDER
  /////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="c-grid__container-spaced">
      <HeadComponent title={t("application.titles.setup")} />

      <FixedBarComponent
        active={true}
        renderContent={() => {
          return (
            <div className="shame-miplus-setup-informations">
              {machineParams && machineParams.newSetup ? (
                <span>The setup will be written to the machine at the next connection</span>
              ) : (
                <>
                  {machineParams && machineParams.setup && machineParams.timestamp ? (
                    <span>Setup synchronized with the machine on {datetimeToPrettyString(new Date(machineParams.timestamp))}</span>
                  ) : (
                    <span>Setup not synchronized with the machine</span>
                  )}
                </>
              )}
            </div>
          );
        }}
      >
        {/** SETUP */}
        <CardComponent
          className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
          title={t("SetupMacchina")}
        >
          <ListItemComponent title={t("NumeroElementiSemina")}>
            <InputNumberComponent
              onChange={(v) =>
                updateFormValue(
                  "numeroFile",
                  formValues.accessoriTwin ? v * 2 : v,
                  true
                )
              }
              value={
                formValues.accessoriTwin
                  ? formValues.numeroFile / 2
                  : formValues.numeroFile
              }
              max={formValues.accessoriTwin ? 12 : 24}
              min={1}
              disabled={!isAdmin}
            />
          </ListItemComponent>

          <ListItemComponent title={t("TipoElemento")}>
            <InputSelectComponent
              placeholder={t("application.cta.tap_to_select")}
              options={[
                { value: false, label: t("Standard") },
                { value: true, label: t("Twin") },
              ]}
              onChange={(v) => updateFormValue("accessoriTwin", v)}
              value={formValues.accessoriTwin}
              disabled={!isAdmin}
            />
          </ListItemComponent>
        </CardComponent>

        {/** SENSORE VELOCITA */}
        <CardComponent
          className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
          title={t("SensoreVelocita")}
        >
          <ListItemComponent title={t("SensoreVelocita")}>
            <InputSelectComponent
              placeholder={t("application.cta.tap_to_select")}
              options={[
                { value: "encoder", label: t("Encoder") },
                { value: "radar", label: t("Radar") },
              ]}
              onChange={(v) => updateFormValue("sensoreVelocitaTipologia", v)}
              value={formValues.sensoreVelocitaTipologia}
              disabled={!isAdmin}
            />
          </ListItemComponent>

          {formValues.sensoreVelocitaTipologia === "encoder" && (
            <>
              <ListItemComponent title={t("NumeroFasi")}>
                <InputSelectComponent
                  placeholder={t("application.cta.tap_to_select")}
                  options={[
                    { value: 0, label: t("UnaFase") },
                    { value: 1, label: t("DueFasi") },
                  ]}
                  onChange={(v) => updateFormValue("phasesNumber", v, true)}
                  value={formValues.phasesNumber}
                  disabled={!isAdmin}
                />
              </ListItemComponent>
              <ListItemComponent title={t("CalibrazioneAutomatica")}>
                <InputSwitchComponent
                  onChange={(v) => updateFormValue("calibrazioneAutomatica", v)}
                  value={formValues.calibrazioneAutomatica}
                  val1={false}
                  val2={true}
                  lab1={t("application.labels.not_active")}
                  lab2={t("application.labels.active")}
                  disabled={!isAdmin}
                />
              </ListItemComponent>

              {formValues.calibrazioneAutomatica && (
                <ListItemComponent title={t("ImpulsiMetro")}>
                  <InputNumberComponent
                    onChange={(v) => updateFormValue("impulseAutoCal", v, true)}
                    value={formValues.impulseAutoCal}
                    max={1000}
                    min={0.1}
                    // step={0.1}
                    decimal={1}
                    disabled={!isAdmin}
                  />
                </ListItemComponent>
              )}
              {!formValues.calibrazioneAutomatica && (
                <>
                  <ListItemComponent title={t("ValorePPR")}>
                    <InputNumberComponent
                      onChange={(v) => updateFormValue("impulsePpr", v, true)}
                      value={formValues.impulsePpr}
                      max={1000}
                      min={1}
                      step={1}
                      disabled={!isAdmin}
                    />
                  </ListItemComponent>
                  {formValues.phasesNumber === 2 && (
                    <ListItemComponent title={t("Rotazione")}>
                      <InputSelectComponent
                        placeholder={t("application.cta.tap_to_select")}
                        options={[
                          { value: "cw", label: t("CW") },
                          { value: "cww", label: t("CCW") },
                        ]}
                        onChange={(v) => updateFormValue("cwOcww", v)}
                        value={formValues.cwOcww}
                        disabled={!isAdmin}
                      />
                    </ListItemComponent>
                  )}
                  <ListItemComponent title={t("GearA")}>
                    <InputNumberComponent
                      onChange={(v) =>
                        updateFormValue("gearASpeed", v, true)
                      }
                      value={formValues.gearASpeed}
                      max={100}
                      min={1}
                      step={1}
                      disabled={!isAdmin}
                    />
                  </ListItemComponent>
                  <ListItemComponent title={t("GearB")}>
                    <InputNumberComponent
                      onChange={(v) =>
                        updateFormValue("gearBSpeed", v, true)
                      }
                      value={formValues.gearBSpeed}
                      max={100}
                      min={1}
                      step={1}
                      disabled={!isAdmin}
                    />
                  </ListItemComponent>
                  <ListItemComponent title={t("Ruota")}>
                    <InputSelectComponent
                      placeholder={t("application.cta.tap_to_select")}
                      options={[
                        {
                          value: 159,
                          label: `${t("Pneumatico")} 20 x 8.00 - 10`,
                        },
                        {
                          value: 180,
                          label: `${t("Pneumatico")} 23 x 10.50 - 12`,
                        },
                        {
                          value: 205,
                          label: `${t("Pneumatico")} 26 x 12.00 - 12`,
                        },
                        {
                          value: 257,
                          label: `${t("Pneumatico")} 31 x 15.50 - 15`,
                        },
                        { value: 1, label: t("Personalizzato") },
                      ]}
                      onChange={(v) =>
                        updateFormValue("circonferenzaRotolamento", v)
                      }
                      value={formValues.circonferenzaRotolamento}
                      disabled={!isAdmin}
                    />
                  </ListItemComponent>
                  <ListItemComponent title={t("CirconferenzaRotolamento")}>
                    <InputNumberComponent
                      onChange={(v) =>
                        updateFormValue("circonferenzaRotolamento", v, true)
                      }
                      value={formValues.circonferenzaRotolamento}
                      max={1000}
                      min={1}
                      step={1}
                      disabled={!isAdmin}
                    />
                  </ListItemComponent>
                </>
              )}
            </>
          )}

          {formValues.sensoreVelocitaTipologia === "radar" && (
            <>
              <ListItemComponent title={t("CalibrazioneAutomatica")}>
                <InputSwitchComponent
                  onChange={(v) => updateFormValue("calibrazioneAutomatica", v)}
                  value={formValues.calibrazioneAutomatica}
                  val1={false}
                  val2={true}
                  lab1={t("application.labels.not_active")}
                  lab2={t("application.labels.active")}
                  disabled={!isAdmin}
                />
              </ListItemComponent>
              {!formValues.calibrazioneAutomatica && (
                <ListItemComponent title={t("ImpulsiMetro")}>
                  <InputNumberComponent
                    onChange={(v) => updateFormValue("impulseMetro", v, true)}
                    value={formValues.impulseMetro}
                    max={1000}
                    min={1}
                    // step={0.1}
                    decimal={1}
                    disabled={!isAdmin}
                  />
                </ListItemComponent>
              )}
              {formValues.calibrazioneAutomatica && (
                <ListItemComponent title={t("Radar")}>
                  <InputNumberComponent
                    onChange={(v) => updateFormValue("impulseAutoCal", v, true)}
                    value={formValues.impulseAutoCal}
                    max={1000}
                    min={1}
                    // step={0.1}
                    decimal={1}
                    disabled={!isAdmin}
                  />
                </ListItemComponent>
              )}
            </>
          )}
        </CardComponent>

        {/** SENSORI SEME */}
        <CardComponent
          className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
          title={t("SensoreSeme")}
        >
          <ListItemComponent title={t("TipoFotocellula")}>
            <InputSelectComponent
              placeholder={t("application.cta.tap_to_select")}
              options={[
                { value: "dj", label: t("Dickey john") },
                { value: "mc_uniseeder", label: t("MC Uniseeder") },
              ]}
              onChange={(v) => updateFormValue("photocellsVoltage", v)}
              value={formValues.photocellsVoltage}
              disabled={!isAdmin}
            />
          </ListItemComponent>
        </CardComponent>

        {/** IMPOSTAZIONE VENTOLA */}
        <CardComponent
          className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
          title={t("ImpostazioniVentola")}
        >
          <ListItemComponent title={t("Ventola")}>
            <InputSwitchComponent
              onChange={(v) => updateFormValue("sensoriVentola", v)}
              value={formValues.sensoriVentola}
              val1={false}
              val2={true}
              lab1={t("application.labels.not_active")}
              lab2={t("application.labels.active")}
              disabled={!isAdmin}
            />
          </ListItemComponent>
          {formValues.sensoriVentola && (
            <>
              <ListItemComponent title={t("GearA")}>
                <InputNumberComponent
                  onChange={(v) => updateFormValue("gearAVentola", v, true)}
                  value={formValues.gearAVentola}
                  max={100}
                  min={1}
                  step={1}
                  disabled={!isAdmin}
                />
              </ListItemComponent>
              <ListItemComponent title={t("GearB")}>
                <InputNumberComponent
                  onChange={(v) => updateFormValue("gearBVentola", v, true)}
                  value={formValues.gearBVentola}
                  max={100}
                  min={1}
                  step={1}
                  disabled={!isAdmin}
                />
              </ListItemComponent>
              <ListItemComponent title={t("ImpulsiPerGiro")}>
                <InputNumberComponent
                  onChange={(v) =>
                    updateFormValue("impulsiGiroVentola", v, true)
                  }
                  value={formValues.impulsiGiroVentola}
                  max={1000}
                  min={1}
                  step={1}
                  disabled={!isAdmin}
                />
              </ListItemComponent>
              <ListItemComponent title={t("RPM Min")}>
                <InputNumberComponent
                  onChange={(v) => updateFormValue("ventolaRpmMin", v, true)}
                  value={formValues.ventolaRpmMin}
                  max={10000}
                  min={1}
                  step={1}
                  disabled={!isAdmin}
                />
              </ListItemComponent>
              <ListItemComponent title={t("RPM Max")}>
                <InputNumberComponent
                  onChange={(v) => updateFormValue("ventolaRpmMax", v, true)}
                  value={formValues.ventolaRpmMax}
                  max={10000}
                  min={0}
                  step={1}
                  disabled={!isAdmin}
                />
              </ListItemComponent>
            </>
          )}
        </CardComponent>

        {/** DEPRESSIONE */}
        <CardComponent
          className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
          title={t("ImpostazioniDepressione")}
        >
          <ListItemComponent title={t("DepressioneSensore")}>
            <InputSwitchComponent
              onChange={(v) => updateFormValue("sensoriDepressione", v)}
              value={formValues.sensoriDepressione}
              val1={false}
              val2={true}
              lab1={t("application.labels.not_active")}
              lab2={t("application.labels.active")}
              disabled={!isAdmin}
            />
          </ListItemComponent>

          {formValues.sensoriDepressione && (
            <>
              <ListItemComponent title={t("DepressioneMinima")}>
                <InputNumberComponent
                  onChange={(v) =>
                    updateFormValue("depressioneMinima", v, true)
                  }
                  value={formValues.depressioneMinima}
                  max={1000}
                  min={0}
                  step={1}
                  disabled={!isAdmin}
                />
              </ListItemComponent>
              <ListItemComponent title={t("DepressioneMassima")}>
                <InputNumberComponent
                  onChange={(v) =>
                    updateFormValue("depressioneMassima", v, true)
                  }
                  value={formValues.depressioneMassima}
                  max={1000}
                  min={0}
                  step={1}
                  disabled={!isAdmin}
                />
              </ListItemComponent>
            </>
          )}
        </CardComponent>

        {/** ACCESSORI */}
        <CardComponent
          className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
          title={t("Accessori")}
        >
          <ListItemComponent title={t("Esclusioni")}>
            <InputSwitchComponent
              onChange={(v) => updateFormValue("accessoriEsclusioni", v)}
              value={formValues.accessoriEsclusioni}
              val1={false}
              val2={true}
              lab1={t("application.labels.not_active")}
              lab2={t("application.labels.active")}
              disabled={!isAdmin}
            />
          </ListItemComponent>
          <ListItemComponent title={t("Micro1")}>
            <InputSwitchComponent
              onChange={(v) => updateFormValue("accessoriMicro1", v)}
              value={formValues.accessoriMicro1}
              val1={false}
              val2={true}
              lab1={t("application.labels.not_active")}
              lab2={t("application.labels.active")}
              disabled={!isAdmin}
            />
          </ListItemComponent>
          <ListItemComponent title={t("Micro2")}>
            <InputSwitchComponent
              onChange={(v) => updateFormValue("accessoriMicro2", v)}
              value={formValues.accessoriMicro2}
              val1={false}
              val2={true}
              lab1={t("application.labels.not_active")}
              lab2={t("application.labels.active")}
              disabled={!isAdmin}
            />
          </ListItemComponent>
          <ListItemComponent title={t("Spandi")}>
            <InputSwitchComponent
              onChange={(v) => updateFormValue("accessoriSpandi", v)}
              value={formValues.accessoriSpandi}
              val1={false}
              val2={true}
              lab1={t("application.labels.not_active")}
              lab2={t("application.labels.active")}
              disabled={!isAdmin}
            />
          </ListItemComponent>
          <ListItemComponent title={t("ConcimazioneLiquida")}>
            <InputSwitchComponent
              onChange={(v) =>
                updateFormValue("accessoriConcimazioneLiquida", v)
              }
              value={formValues.accessoriConcimazioneLiquida}
              val1={false}
              val2={true}
              lab1={t("application.labels.not_active")}
              lab2={t("application.labels.active")}
              disabled={!isAdmin}
            />
          </ListItemComponent>
          <ListItemComponent title={t("Diserbo")}>
            <InputSwitchComponent
              onChange={(v) => updateFormValue("accessoriDiserbo", v)}
              value={formValues.accessoriDiserbo}
              val1={false}
              val2={true}
              lab1={t("application.labels.not_active")}
              lab2={t("application.labels.active")}
              disabled={!isAdmin}
            />
          </ListItemComponent>
          <ListItemComponent title={t("Modem")}>
            <InputSwitchComponent
              onChange={(v) => updateFormValue("accessoriModem", v)}
              value={formValues.accessoriModem}
              val1={false}
              val2={true}
              lab1={t("application.labels.not_active")}
              lab2={t("application.labels.active")}
              disabled={!isAdmin}
            />
          </ListItemComponent>
        </CardComponent>

        {/** SENSORI */}
        <CardComponent
          className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
          title={t("Sensori")}
        >
          <ListItemComponent title={t("Usura")}>
            <InputSwitchComponent
              onChange={(v) => updateFormValue("sensoriUsura", v)}
              value={formValues.sensoriUsura}
              val1={false}
              val2={true}
              lab1={t("application.labels.not_active")}
              lab2={t("application.labels.active")}
              disabled={!isAdmin}
            />
          </ListItemComponent>
          <ListItemComponent title={t("WorkSenseTyp")}>
            <InputSelectComponent
              placeholder={t("application.cta.tap_to_select")}
              options={[
                { value: true, label: t("WorkSenseTyp1") },
                { value: false, label: t("WorkSenseTyp2") },
              ]}
              onChange={(v) => updateFormValue("tipoSensoreLavoro", v)}
              value={formValues.tipoSensoreLavoro}
              disabled={!isAdmin}
            />
          </ListItemComponent>
        </CardComponent>

        <ButtonsGroupComponent sizes={{ default: 24, sm: 24 }}>
          <ButtonComponent
            label={t("Conferma")}
            disabled={false}
            onClick={onConfirm}
          />
        </ButtonsGroupComponent>
      </FixedBarComponent>
    </div>
  );
}

SetupScreen.propTypes = {
  goBack: PropTypes.func,
};

SetupScreen.defaultProps = {
  goBack: () => {},
};
