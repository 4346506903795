import React, { useContext } from "react";
import PropTypes from "prop-types";
import { LocalesContext } from "../../contexts/LocalesContext";
import { MqttContext } from "../../contexts/MqttContext";
import HeadComponent from "../../components/HeadComponent";
import CardComponent from "../../components/CardComponent";
import DiagnosticStatComponent from "../../components/DiagnosticStatComponent";
import ListItemComponent from "../../components/ListItemComponent";
import { gridSizesClassName } from "../../utils/gridSizes";
import {
  getMachineParamsValue,
  getMachineParamsComplete,
} from "../../utils/irriapp";

export default function DiagnosticScreen(props) {
  const { t } = useContext(LocalesContext);
  const { machineParams } = useContext(MqttContext);

  let manual = props.manuals.filter((m) => m.language.toUpperCase() === props.locale.toUpperCase() )[0]
  if (!manual) manual = props.manuals.filter((m) => m.language.toUpperCase() === 'EN' )[0]

  // RENDER
  /////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="c-grid__container-spaced">
      <HeadComponent title={t("application.titles.diagnostic")} />

      <CardComponent
        className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
      >
        <ListItemComponent
          title={t(
            "irriapp.diagnostic.title_diagnostic_stato_ingresso_digitali"
          )}
          big
        >
          <DiagnosticStatComponent
            data={getMachineParamsValue(
              machineParams,
              "diagnostic_stato_ingresso_digitali"
            )}
          />
        </ListItemComponent>
        <ListItemComponent
          title={t("irriapp.diagnostic.title_diagnostic_stato_uscite_digitali")}
          big
        >
          <DiagnosticStatComponent
            data={getMachineParamsValue(
              machineParams,
              "diagnostic_stato_uscite_digitali"
            )}
          />
        </ListItemComponent>
        <ListItemComponent
          title={t("irriapp.diagnostic.title_diagnostic_ingresso_analogico")}
          big
        >
          {getMachineParamsComplete(
            machineParams,
            "diagnostic_ingresso_analogico"
          )}
        </ListItemComponent>
        <ListItemComponent
          title={t("irriapp.diagnostic.title_diagnostic_flussometro_acqua")}
          big
        >
          {getMachineParamsComplete(
            machineParams,
            "diagnostic_flussometro_acqua"
          )}
        </ListItemComponent>
        <ListItemComponent
          title={t("irriapp.diagnostic.title_diagnostic_tensione_batteria")}
          big
        >
          {getMachineParamsComplete(
            machineParams,
            "diagnostic_tensione_batteria"
          )}
        </ListItemComponent>
        {[1, 2, 3, 4].map((i) => {
          return (
            <div key={i}>
              <ListItemComponent
                title={`${t(
                  "irriapp.diagnostic.title_diagnostic_stato_motore"
                )} ${i}`}
                big
              >
                {getMachineParamsValue(
                  machineParams,
                  `irriapp.diagnostic.diagnostic_stato_motore_${i}`
                ) ? (
                  <i className="fas fa-check"></i>
                ) : (
                  <i className="fas fa-times"></i>
                )}
              </ListItemComponent>
              <ListItemComponent
                title={`${t(
                  "irriapp.diagnostic.title_diagnostic_adc_motore"
                )} ${i}`}
                big
              >
                {getMachineParamsComplete(
                  machineParams,
                  `diagnostic_adc_motore_${i}`
                )}
              </ListItemComponent>
            </div>
          );
        })}
        <ListItemComponent
          title={t("irriapp.diagnostic.title_diagnostic_totale_utilizzo")}
          big
        >
          {getMachineParamsComplete(
            machineParams,
            "diagnostic_totale_utilizzo"
          )}
        </ListItemComponent>
        <ListItemComponent
          title={t("irriapp.diagnostic.title_diagnostic_parziale_utilizzo")}
          big
        >
          {getMachineParamsComplete(
            machineParams,
            "diagnostic_parziale_utilizzo"
          )}
        </ListItemComponent>
        <ListItemComponent
          title={t(
            "irriapp.diagnostic.title_diagnostic_contatore_accensioni_scheda"
          )}
          big
        >
          {getMachineParamsComplete(
            machineParams,
            "diagnostic_contatore_accensioni_scheda"
          )}
        </ListItemComponent>
        <ListItemComponent
          title={t("irriapp.diagnostic.title_diagnostic_totale_acqua")}
          big
        >
          {getMachineParamsComplete(machineParams, "diagnostic_totale_acqua")}
        </ListItemComponent>
        <ListItemComponent
          title={t("irriapp.diagnostic.title_diagnostic_parziale_acqua")}
          big
        >
          {getMachineParamsComplete(machineParams, "diagnostic_parziale_acqua")}
        </ListItemComponent>
        {machineParams?.conf_bar_trasduttore_soglia?.value > 0 && (
          <ListItemComponent
            title={t("irriapp.diagnostic.title_diagnostic_pressione_acqua")}
            big
          >
            {getMachineParamsComplete(machineParams, "diagnostic_pressione_acqua")}
          </ListItemComponent>
        )}
      </CardComponent>

      {manual && (
        <>
          <HeadComponent title={t("application.titles.manuals")} />
          <CardComponent
            className={`${gridSizesClassName({
              default: 24,
            })} c-card--hasnext`}
          >
            <ListItemComponent
              title={t("application.titles.download_manual")}
            >
              <a href={manual.url} target="_blank" className="c-button">
                Download
              </a>
            </ListItemComponent>
          </CardComponent>
        </>
      )}
    </div>
  );
}

DiagnosticScreen.propTypes = {
  goBack: PropTypes.func,
};

DiagnosticScreen.defaultProps = {
  goBack: () => {},
};
