import React from 'react'
import PropTypes from 'prop-types'

export default function MessageComponent(props) {
  return (
    <div className="c-message" style={{ width: '100%' }}>
      {props.title && <div className="c-message__title">{props.title}</div>}
      {(props.message || props.extraContent) && (
        <div className="c-message__content c-message__content--small">
          {props.message}
          {props.extraContent && <><br /><br />{props.extraContent()}</>}
        </div>
      )}
    </div>
  )
}

MessageComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  extraContent: PropTypes.func
}

MessageComponent.defaultProps = {}
