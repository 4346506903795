import React, { useEffect, useContext, useMemo } from "react";
import { LocalesProvider, LocalesContext } from "./contexts/LocalesContext";
import SectionContentComponent from "./components/SectionContentComponent";
import SectionContentBlockComponent from "./components/SectionContentBlockComponent";
import CardComponent from "./components/CardComponent";
import SessionInfosComponent from "./components/SessionInfosComponent";
import ChartLineComponent from "./components/ChartLineComponent";
import ConfResumeComponent from "./irriapp/components/ConfResumeComponent";
import HistoricalComponent from "./components/HistoricalComponent";
import {
  getDatesPerMinuteBetweenDates,
  timeToPrettyString,
} from "./utils/data";
import { getMachineParamsValue } from "./utils/irriapp";

function IrriappSession(props) {
  const { t, actions: localesActions } = useContext(LocalesContext);

  // MEMOS ******************************************************************

  const chartKeys = [
    "work_tube_length",
    "work_water_used",
    "work_sector_speed",
    "work_sector_height",
  ];
  const chartColors = ["#8e44ad", "#2980b9", "#f39c12", "#e67e22"];
  const chartUnits = ["m", "m3", "m/h", "mm"];
  const chartLabels = [
    t("irriapp.labels.work_tube_length"),
    t("irriapp.labels.work_water_used"),
    t("irriapp.labels.work_sector_speed"),
    t("irriapp.labels.work_sector_height"),
  ];
  const chartBarKeys = ["event", "connection", "disconnection"];
  const chartBarColors = ["#2c3e50", "#2ecc71", "#c0392b"];
  const chartBarLabels = [t("irriapp.labels.work_event"), t("irriapp.labels.work_connection"), t("irriapp.labels.work_disconnection")];

  const chartAreaKeys = ["alarm"]
  const chartAreaColors = ["#c0392b"]
  const chartAreaLabels = [t("application.labels.alarm")]

  const chartData = useMemo(() => {
    if (props.session_snapshots.length < 1) return [];

    const timeStart = new Date(props.session_snapshots[0].timestamp);
    const timeEnd = new Date(
      props.session_snapshots[props.session_snapshots.length - 1].timestamp
    );
    const minutes = getDatesPerMinuteBetweenDates(timeStart, timeEnd);

    const data = [];
    const dataPrev = {};
    minutes.map((minute, index) => {
      const snapshots = props.session_snapshots.filter((s) => {
        if (
          !chartKeys.includes(s.typology) &&
          !chartBarKeys.includes(s.typology) &&
          !chartAreaKeys.includes(s.typology)
        )
          return false;
        const snapshotTimestamp = new Date(s.timestamp);
        return (
          snapshotTimestamp >= minute &&
          (minutes[index + 1] ? snapshotTimestamp < minutes[index + 1] : true)
        );
      });

      const dataItem = { x: timeToPrettyString(minute) };
      snapshots.map((snapshot) => {
        if (dataItem[snapshot.typology]) return // evito di eseguire due volte lo stesso snapshot per lo stesso minuto

        if (chartBarKeys.includes(snapshot.typology)) {
          const label = chartBarLabels[chartBarKeys.indexOf(snapshot.typology)]
          dataItem[snapshot.typology] = 1;
          dataItem[`${snapshot.typology}_string`] = snapshot.value_elaborated || label;
        } else if(chartAreaKeys.includes(snapshot.typology)) {
          const label = chartAreaLabels[chartAreaKeys.indexOf(snapshot.typology)]
          dataItem[snapshot.typology] = 1;
          dataItem[`${snapshot.typology}_string`] = snapshot.value_elaborated || label;
        } else {
          dataItem[snapshot.typology] = snapshot.value;
          // verifico se ce lo stesso dato il minuto prima, altrimenti lo fako con l'ultimo prima di questo
          if (data[data.length - 1] && !data[data.length - 1][snapshot.typology]) {
            data[data.length - 1][snapshot.typology] = dataPrev[snapshot.typology]
          }
          dataPrev[snapshot.typology] = snapshot.value;
        }
        
      });

      if(!dataItem['alarm']){
        dataItem['alarm'] = 0
      }
      
      data.push(dataItem);
    });
    return data;
  }, [props.session_snapshots, t]);

  const configuration = useMemo(() => {
    const c = {};
    Object.keys(props.session.params).forEach((key) => {
      if (!key.startsWith("program_")) return;
      c[key.replace("program_", "")] = getMachineParamsValue(
        props.session.params,
        key
      );
    });
    return c;
  }, [props.session]);

  const alarmData = useMemo(() => {
    const data = [];
    props.session_snapshots.filter((s) => {
      if (s.typology === "alarm") {
        const snapshotTimestamp = new Date(s.timestamp);
        const snapshotValue = s.value_elaborated;
        data.push({
          date: timeToPrettyString(snapshotTimestamp),
          value: snapshotValue,
        });
      }
    });

    return data;
  }, [props.session_snapshots]);

  const cmdData = useMemo(() => {
    const data = [];
    props.session_snapshots.filter((s) => {
      if (s.typology === "cmd") {
        const snapshotTimestamp = new Date(s.timestamp);
        const snapshotValue = s.value_elaborated;
        data.push({
          date: timeToPrettyString(snapshotTimestamp),
          value: snapshotValue,
        });
      }
    });

    return data;
  }, [props.session_snapshots]);

  // EFFECTS ******************************************************************

  useEffect(() => {
    localesActions.setLocale(props.locale);
    localesActions.setTranslations(props.translations);
  }, []);

  // RENDER ******************************************************************

  return (
    <SectionContentComponent>
      <SectionContentBlockComponent sizes={{ default: 24, md: 14 }}>
        <CardComponent
          title={t("application.titles.session_general_infos")}
          actions={[
            {
              icon: () => <i className="fas fa-print"></i>,
              onClick: () => {
                window.print();
              },
            },
            {
              icon: () => <i className="fas fa-file-download"></i>,
              onClick: () => {
                window.open(
                  `/panel/manager/session-csvs-view?session_uuid=${props.session.uuid}`
                );
              },
            },
          ]}
        >
          <SessionInfosComponent session={props.session} />
        </CardComponent>
      </SectionContentBlockComponent>
      <SectionContentBlockComponent sizes={{ default: 24, md: 10 }}>
        <CardComponent title={t("Preset")}>
          <ConfResumeComponent configuration={configuration} />
        </CardComponent>
      </SectionContentBlockComponent>
      <SectionContentBlockComponent sizes={{ default: 24 }}>
        <CardComponent title={t("application.titles.session_work_history")}>
          <ChartLineComponent
            data={chartData}
            keys={chartKeys}
            colors={chartColors}
            units={chartUnits}
            labels={chartLabels}
            barKeys={chartBarKeys}
            barColors={chartBarColors}
            barLabels={chartBarLabels}
            areaKeys={chartAreaKeys}
            areaColors={chartAreaColors}
            areaLabels={chartAreaLabels}
          />
        </CardComponent>
      </SectionContentBlockComponent>
      <SectionContentBlockComponent sizes={{ default: 24, md: 12 }}>
        <CardComponent title={t("application.titles.session_command_history")}>
          <HistoricalComponent data={cmdData} />
        </CardComponent>
      </SectionContentBlockComponent>
      <SectionContentBlockComponent sizes={{ default: 24, md: 12 }}>
        <CardComponent title={t("application.titles.session_alarm_history")}>
          <HistoricalComponent data={alarmData} />
        </CardComponent>
      </SectionContentBlockComponent>
    </SectionContentComponent>
  );
}

export default (props) => {
  return (
    <LocalesProvider>
      <IrriappSession {...props} />
    </LocalesProvider>
  );
};
