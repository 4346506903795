import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import HeadComponent from "../../components/HeadComponent";
import CardComponent from "../../components/CardComponent";
import ConfResumeComponent from "../components/ConfResumeComponent";
import ButtonsGroupComponent from "../../components/ButtonsGroupComponent";
import ButtonComponent from "../../components/ButtonComponent";
import ModalComponent from "../../components/ModalComponent";
import MessageComponent from "../../components/MessageComponent";
import LoadingComponent from "../../components/LoadingComponent";
import ConfTimeComponent from "../components/ConfTimeComponent";
import {
  SecureComponent,
  useSecureComponent,
} from "../components/SecureComponent";
import { LocalesContext } from "../../contexts/LocalesContext";
import { MqttContext } from "../../contexts/MqttContext";
import { gridSizesClassName } from "../../utils/gridSizes";
import { postRequest } from "../../utils/requests";
import { uuidv4 } from "../../utils/data";
import { flashDanger } from "../../utils/flash";
import { getMachineParamsValue, unitaMisuraIntToString } from "../../utils/irriapp";

export default function ConfigurationScreen(props) {
  const { t } = useContext(LocalesContext);
  const { machineParams, actions: mqttActions } = useContext(MqttContext);
  const [params, setParams] = useState(props.params);
  const [loadingModal, setLoadingModal] = useState(false);
  const [timeCompleted, setTimeCompleted] = useState(false);
  const [secureComponentProps, activeSecureComponent] = useSecureComponent();

  // FUNCTIONS
  /////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Funzione eseguita quando viene modificata la configurazione
   * @param {*} newParams
   */
  const onChangeConfiguration = (newParams) => {
    setParams(newParams);
  };

  /**
   * Funzione eseguita alla conferma della configurazione.
   */
  const onConfirm = () => {
    activeSecureComponent(() => {
      setLoadingModal(true);

      const sessionParams = {
        uuid: uuidv4(),
        machine_uuid: props.machine_uuid,
        timestamp_creation: new Date(),
      };
      sessionParams.client_uuid = params.client_uuid;
      sessionParams.field_uuid = params.field_uuid;
      sessionParams.preset_uuid = params.preset_uuid;
      sessionParams.profile_uuid = params.profile_uuid;
      sessionParams.params = Object.assign({}, params);

      // aggiungo valore conf work mod da configurazione macchina per la gestione delle modalità libero / non libero
      const machineParamsWorkMode = getMachineParamsValue(
        machineParams,
        "conf_criterio_regolazione"
      );
      params.conf_work_mod = machineParamsWorkMode;

      mqttActions.sendMessageWithAnswer(
        "UPD_ROLL",
        { tube_length: params.tube_length },
        "COMMAND_ACK",
        (message) => {
          // imposto valore ufficiale di srotolamento
          if (!message || !message.data.result)
            return _onConfirmError("UPD_ROLL");
          mqttActions.sendMessageWithAnswer(
            "END_ROLL",
            {},
            "COMMAND_ACK",
            (message) => {
              // imposto fine fase di srotolamento
              if (!message || !message.data.result)
                return _onConfirmError("END_ROLL");
              mqttActions.sendMessageWithAnswer(
                "UPD_PROG",
                params,
                "COMMAND_ACK",
                (message) => {
                  // invio nuova configurazione
                  if (!message || !message.data.result)
                    return _onConfirmError("UPD_PROG");
                  postRequest(
                    props.apis.sessions_create_action,
                    sessionParams,
                    (_response) => {
                      // memorizzo sul server l'avvio di una nuova sessione
                      mqttActions.sendMessageWithAnswer(
                        "START_IRR",
                        {},
                        "COMMAND_ACK",
                        (message) => {
                          // invio inizio lavoro
                          if (!message || !message.data.result)
                            return _onConfirmError("START_IRR");
                          props.goTo("machine");
                        }
                      );
                    }
                  );
                }
              );
            }
          );
        }
      );
    });
  };
  const _onConfirmError = (messageType) => {
    flashDanger(t("application.messages.general_error"));
    setLoadingModal(false);
  };

  /**
   * Funzione eseguita quando l'utente torna indietro.
   */
  const onGoBack = () => {
    props.goBack({ type: "configuration_new", params });
  };

  /**
   * Funzione eseguita al cambio validazione orario.
   */
  const onChangeValidation = (validation) => {
    setTimeCompleted(validation);
  };

  // RENDER
  /////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="c-grid__container-spaced">
      <HeadComponent
        title={t("application.titles.configuration_confirm")}
        onBack={onGoBack}
      />

      <CardComponent
        title={t("application.titles.configuration_resume")}
        className={`c-card--hasnext ${gridSizesClassName({ default: 24 })}`}
      >
        <ConfResumeComponent configuration={params} converterType={unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura"))} />
      </CardComponent>

      <CardComponent
        title={t("application.titles.configuration_time")}
        className={`c-card--hasnext ${gridSizesClassName({ default: 24 })}`}
      >
        <ConfTimeComponent
          configuration={params}
          onChange={onChangeConfiguration}
          onChangeValidation={onChangeValidation}
        />
      </CardComponent>

      <ButtonsGroupComponent sizes={{ default: 24, sm: 24 }}>
        <ButtonComponent
          label={t("application.cta.start")}
          onClick={onConfirm}
          disabled={!timeCompleted}
        />
        <ButtonComponent
          label={t("application.cta.cancel")}
          confirm={t("application.messages.confirm_cancel")}
          onClick={() => props.goTo("machine")}
          warning
        />
      </ButtonsGroupComponent>

      <ModalComponent status={loadingModal}>
        <MessageComponent
          title={t("application.messages.loading_communication")}
          extraContent={() => <LoadingComponent semifullscreen />}
        />
      </ModalComponent>

      <SecureComponent {...secureComponentProps} />
    </div>
  );
}

ConfigurationScreen.propTypes = {
  goBack: PropTypes.func,
};

ConfigurationScreen.defaultProps = {
  goBack: () => {},
};
