import React, { useState } from 'react'
import PropTypes from 'prop-types'

export default function InputSwitchComponent(props) {
  const value = props.value || props.val1

  const onChangeValue = () => {
    props.onChange(value == props.val1 ? props.val2 : props.val1)
  }

  return (
    <div className="r-input-switch">
      <div className={`r-input-switch__label ${props.lablarge ? 'is-large' : ''}`}>{props.lab1}</div>
      <button onClick={onChangeValue} className={`r-input-switch__input ${value === props.val2 ? 'is-active' : ''}`}></button>
      <div className={`r-input-switch__label ${props.lablarge ? 'is-large' : ''}`}>{props.lab2}</div>
    </div>
  )
}

InputSwitchComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  val1: PropTypes.any,
  val2: PropTypes.any,
  lab1: PropTypes.string,
  lab2: PropTypes.string,
  lablarge: PropTypes.bool
}

InputSwitchComponent.defaultProps = {}
