import React, { useState, useEffect, useContext, useMemo } from "react";
import { LocalesProvider, LocalesContext } from "./contexts/LocalesContext";
import SectionContentComponent from "./components/SectionContentComponent";
import SectionContentBlockComponent from "./components/SectionContentBlockComponent";
import CardComponent from "./components/CardComponent";
import MapComponent from "./components/MapComponent";
import SessionInfosComponent from "./components/SessionInfosComponent";
import SessionStatsComponent from "./miplus/components/SessionStatsComponent";
import ConfResumeComponent from "./miplus/components/ConfResumeComponent";
import HistoricalComponent from "./components/HistoricalComponent";
import SessionChartComponent from "./miplus/components/SessionChartComponent";

import {
  secondsToPrettyTime,
  timeToPrettyString,
} from "./utils/data";

function MiplusSession(props) {
  const { session } = props;
  const {
    t,
    translations,
    actions: localesActions,
  } = useContext(LocalesContext);

  // MEMOS ******************************************************************

  const alarmData = useMemo(() => {
    const data = [];
    props.session_snapshots.filter((s) => {
      if (s.typology === "alarm") {
        const snapshotTimestamp = new Date(s.timestamp);
        const snapshotValue = s.value_elaborated;
        data.push({
          date: timeToPrettyString(snapshotTimestamp),
          value: snapshotValue,
          subvalue: s.lifetime && s.lifetime > 60 ? `x ${secondsToPrettyTime(s.lifetime)}` : null 
        });
      }
    });

    return data;
  }, [translations, props.session_snapshots]);

  // EFFECTS ******************************************************************

  useEffect(() => {
    localesActions.setLocale(props.locale);
    localesActions.setTranslations(props.translations);
  }, []);

  // RENDER ******************************************************************

  return (
    <SectionContentComponent>
      <SectionContentBlockComponent sizes={{ default: 24, md: 14 }}>
        <CardComponent
          title={t("application.titles.session_general_infos")}
          actions={[
            {
              icon: () => <i className="fas fa-print"></i>,
              onClick: () => {
                window.print();
              },
            },
            {
              icon: () => <i className="fas fa-file-download"></i>,
              onClick: () => {
                window.open(
                  `/panel/manager/session-csvs-view?session_uuid=${props.session.uuid}`
                );
              },
            },
          ]}
        >
          <MapComponent coords={session.params.coords} />
          <br />
          <SessionInfosComponent session={props.session} />
        </CardComponent>
      </SectionContentBlockComponent>
      <SectionContentBlockComponent sizes={{ default: 24, md: 10 }}>
        <CardComponent title={t("Preset")}>
          <ConfResumeComponent configuration={props.session.params.logPreset} />
        </CardComponent>
      </SectionContentBlockComponent>
      <SectionContentBlockComponent sizes={{ default: 24 }}>
        <CardComponent title={t("application.titles.session_work_history")}>
          <SessionChartComponent snap={props.session_snapshots} />
        </CardComponent>
      </SectionContentBlockComponent>
      <SectionContentBlockComponent sizes={{ default: 24, md: 12 }}>
        <CardComponent title={t("LeStatistiche")}>
          <SessionStatsComponent session={props.session} />
        </CardComponent>
      </SectionContentBlockComponent>
      <SectionContentBlockComponent sizes={{ default: 24, md: 12 }}>
        <CardComponent title={t("application.titles.session_alarm_history")}>
          <HistoricalComponent data={alarmData} />
        </CardComponent>
      </SectionContentBlockComponent>
    </SectionContentComponent>
  );
}

export default (props) => {
  return (
    <LocalesProvider>
      <MiplusSession {...props} />
    </LocalesProvider>
  );
};
