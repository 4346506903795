import React, { useContext, useState } from "react";
import CardComponent from "../../components/CardComponent";
import { MqttContext } from "../../contexts/MqttContext";
import { LocalesContext } from "../../contexts/LocalesContext";
import { gridSizesClassName } from "../../utils/gridSizes";

export default function RealtimeComponent(props) {
  const {
    machineParams,
    machineName,
    machineRtStatus,
    actions: { getMachineParams, sendMessageWithAnswer },
  } = useContext(MqttContext);
  const [ctaEnabled, setCtaEnabled] = useState(true);
  const { t } = useContext(LocalesContext);

  let status = machineRtStatus;
  let classNameStatus = "";
  let labelDataStatus = "";
  let labelAction = "";
  let functionAction = null;

  if (status == "connected") {
    classNameStatus = "is-connected";
    labelDataStatus = t("application.connestion_statuses.connected");
    labelAction = t("application.cta.update_status");
    functionAction = () => {
      setCtaEnabled(false);
      sendMessageWithAnswer(
        "RD_DATA",
        { dataGroup: "work" },
        "MACHINE_DATA",
        () => setCtaEnabled(true)
      );
    };
  } else if (status == "warning") {
    classNameStatus = "is-warning";
    labelDataStatus = t("application.connestion_statuses.warning");
    labelAction = t("application.cta.view_alarms");
    functionAction = props.showAlarms;
  } else if (status == "alarm") {
    classNameStatus = "is-error";
    labelDataStatus = t("application.connestion_statuses.error");
    labelAction = t("application.cta.view_alarms");
    functionAction = props.showAlarms;
  } else if (status == "disconnected") {
    classNameStatus = "is-disconnected";
    labelDataStatus = t("application.connestion_statuses.disconnected");
    labelAction = t("application.cta.update_status");
    functionAction = () => {
      setCtaEnabled(false);
      sendMessageWithAnswer(
        "RD_DATA",
        { dataGroup: "work" },
        "MACHINE_DATA",
        () => {
          getMachineParams(() => setCtaEnabled(true), false);
        }
      );
    };
  } else if (status == "working") {
    classNameStatus = "is-working";
    labelDataStatus = t("application.connestion_statuses.working");
    labelAction = t("application.cta.view_alarms");
    functionAction = props.showAlarms;
  }

  return (
    <CardComponent
      className={`r-realtime ${gridSizesClassName({ defaut: 24 })}`}
    >
      <div className={`r-realtime__status ${classNameStatus}`}></div>
      <div className="r-realtime__container">
        <div className="r-realtime__data">
          <div className="r-realtime__data-name">{machineName}</div>
          <div className="r-realtime__data-status">{labelDataStatus}</div>
        </div>
        {labelAction && functionAction && (
          <button
            className="r-realtime__action c-button"
            onClick={functionAction}
            disabled={!ctaEnabled}
          >
            {labelAction}
          </button>
        )}
      </div>
    </CardComponent>
  );
}
