import React from 'react'
import PropTypes from 'prop-types'
import { datetimeToPrettyString } from '../utils/data'

export default function ListItemComponent(props) {
  const { title, note, number, onPressInfo, big, children, value, valueType } = props

  const getValue = () => {
    if (valueType == 'datetime') return datetimeToPrettyString(new Date(value))
    return value
  }

  return (
    <div className="r-list-item">
      {(number || onPressInfo) && (
        <a className={`r-list-item__intro ${onPressInfo ? 'r-list-item__intro--clickable' : ''}`} onClick={onPressInfo ? () => onPressInfo({title, note, number}) : () => {}}>
          <span>{number}</span>
        </a>
      )}
      <div className="r-list-item__main">
        <div className="r-list-item__title">
          {title}
        </div>
        <div className={`r-list-item__content ${big ? 'is-big' : ''}`}>
          {children || getValue() || ' - '}
        </div>
      </div>
    </div>
  )
}

ListItemComponent.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.any,
  valueType: PropTypes.string,
  note: PropTypes.string,
  number: PropTypes.any,
  onPressInfo: PropTypes.func,
  big: PropTypes.bool,
}

ListItemComponent.defaultProps = {
  className: '',
  title: ''
}
