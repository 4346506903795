import MachineComponent from '../screens/MachineScreen'
import useNav from '../../hooks/navHook'

export default function DashboardNav(props) {
  const [currentScreenName, currentScreenProps, getCurrentScreen] = useNav({
    machine: MachineComponent,
  }, 'machine')

  return getCurrentScreen(props)
}
