import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { LocalesContext } from "../../contexts/LocalesContext";
import { MachineContext } from "../../contexts/MachineContext";
import HeadComponent from "../../components/HeadComponent";
import CardComponent from "../../components/CardComponent";
import DiagnosticStatComponent from "../../components/DiagnosticStatComponent";
import ListItemComponent from "../../components/ListItemComponent";
import { gridSizesClassName } from "../../utils/gridSizes";
import {
  getMachineParamsValue,
  getMachineParamsComplete,
} from "../../utils/irriapp";

export default function DiagnosticScreen(props) {
  const { t } = useContext(LocalesContext);
  const { machineParams } = useContext(MachineContext);

  function hasParam(data, unit = "") {
    if (data) {
      return `${data} ${unit}`;
    }
    return "-";
  }

  // RENDER
  /////////////////////////////////////////////////////////////////////////////////////////////

  console.log(machineParams.diagnostic)

  return (
    <div className="c-grid__container-spaced">
      <HeadComponent title={t("application.titles.diagnostic")} />

      <CardComponent
        className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
        title={t("ContatoriTotali")}
      >
        <ListItemComponent title={t("SuperficieLavorata")} big>
          {hasParam(machineParams.diagnostic.superficieTotale, "ha")}
        </ListItemComponent>
        <ListItemComponent title={t("OreDiUtilizzo")} big>
          {hasParam(machineParams.diagnostic.tempoTotale)}
        </ListItemComponent>
      </CardComponent>
      <CardComponent
        className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
        title={t("Informazioni")}
      >
        <ListItemComponent title={t("EcuSn")} big>
          {hasParam(machineParams.diagnostic.ecuCode)}
        </ListItemComponent>
        <ListItemComponent title={t("Seriale")} big>
          {hasParam(machineParams.diagnostic.seriale)}
        </ListItemComponent>
        <ListItemComponent title={t("Modello")} big>
          {hasParam(machineParams.diagnostic.modello)}
        </ListItemComponent>
        <ListItemComponent title={t("HWVersion")} big>
          {hasParam(machineParams.diagnostic.hwVersion)}
        </ListItemComponent>
        <ListItemComponent title={t("SWVersion")} big>
          {hasParam(machineParams.diagnostic.swVersion)}
        </ListItemComponent>
        <ListItemComponent title={t("BTHWVersion")} big>
          {hasParam(machineParams.diagnostic.btHwVersion)}
        </ListItemComponent>
        <ListItemComponent title={t("BTSWVersion")} big>
          {hasParam(machineParams.diagnostic.btSwVersion)}
        </ListItemComponent>
        <ListItemComponent title={t("SDCARDspace")} big>
          {machineParams.diagnostic.sdcardSpacePercentage <= 100
            ? `${machineParams.diagnostic.sdcardSpacePercentage} %`
            : "-"}
        </ListItemComponent>
        <ListItemComponent title={t("IngressoAnalogico1")} big>
          {hasParam(machineParams.diagnostic.extraAin1Racs, "mA")}
        </ListItemComponent>
        <ListItemComponent title={t("IngressoAnalogico2")} big>
          {hasParam(machineParams.diagnostic.extraAin2, "mA")}
        </ListItemComponent>
        <ListItemComponent title={t("ADCDepressione")} big>
          {hasParam(machineParams.diagnostic.adcDepressione, "mA")}
        </ListItemComponent>
        {/* <ListItemComponent title={t("Depressione")} big>
          {hasParam(machineParams.status.depression, 'mbar')}
        </ListItemComponent> */}
      </CardComponent>
    </div>
  );
}

DiagnosticScreen.propTypes = {
  goBack: PropTypes.func,
};

DiagnosticScreen.defaultProps = {
  goBack: () => {},
};
