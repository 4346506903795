import React from 'react'
import PropTypes from 'prop-types'
import { gridSizesClassName } from '../utils/gridSizes'

export default function FixedBarComponent(props) {
  let className = `r-fixed-bar ${gridSizesClassName({ default: 24 })}`

  return (
    <div className={className}>
      <div className="r-fixed-bar__children">{props.children}</div>
      <div className={`r-fixed-bar__content ${props.active ? 'is-active' : ''}`}>
        {props.onClose && <a className="r-fixed-bar__content-close" onClick={props.onClose}><i className="fas fa-times-circle"></i></a>}
        {props.active && props.renderContent()}
      </div>
    </div>
  )
}

FixedBarComponent.propTypes = {
  active: PropTypes.bool,
  renderContent: PropTypes.func,
  onClose: PropTypes.func
}

FixedBarComponent.defaultProps = {

}
