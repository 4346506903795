/**
 * Esegue una richiesta GET AJAX.
 * Gestisce sempre la risposta a JSON con una chiave result che specifica il risultato
 * della richiesta.
 */
export function getRequest(url, params = {}, callback = () => {}) {
  const paramsString = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&')

  let headers = {}
  if (params.apis_auth_token) {
    headers['Authorization'] = params.apis_auth_token
  }

  fetch(`${url}?${paramsString}`, {
    method: 'GET',
    headers
  }).then((res) => {
    return res.json()
  }).then((res) => {
    callback(requestResponseManager(res))
  })
}

/**
 * Esegue una richiesta POST AJAX.
 * Gestisce sempre la risposta a JSON con una chiave result che specifica il risultato
 * della richiesta.
 */
export function postRequest(url, params = {}, callback = () => {}) {
  let headers = { "content-type": "application/json" }
  if (params.apis_auth_token) {
    headers['Authorization'] = params.apis_auth_token
  }

  fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
    headers
  }).then((res) => {
    return res.json()
  }).then((res) => {
    callback(requestResponseManager(res))
  })
}

/**
 * Trasforma la risposa di una richiesta AJAX in un formato standard.
 * Formato: { result: (true|false), payload: (object) }
 */
export function requestResponseManager(response) {
  if (!response) {
    return { result: false }
  }

  if (typeof response === 'string') {
    return { result: true, payload: response }
  }

  return response
}