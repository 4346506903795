/**
 * @function getMachineParamsStatusValue
 * @param {*} params
 * @param {*} key
 * @param {*} placeholder
 */
export function getMachineParamsStatusValue(params, key, placeholder = null) {
  return params && params.status && params.status[key]
    ? params.status[key]
    : placeholder;
}

/**
 *
 * @param {*} t
 * @returns
 */
export function presetSensibilitaGraficoOptions(t) {
  return [
    { value: 2, label: t("SensibilitaGraficoBasso") },
    { value: 3, label: t("SensibilitaGraficoMedio") },
    { value: 4, label: t("SensibilitaGraficoAlto") },
    { value: 1, label: t("Personalizzato") },
  ];
}

/**
 *
 * @param {*} t
 * @returns
 */
export function presetGraficoDiDefaultOptions(t) {
  return [
    { value: "iso", label: t("StatisticheIso") },
    { value: "population", label: t("Popolazione") },
  ];
}

/**
 *
 * @param {*} options
 * @param {*} value
 * @returns
 */
export function getOptionsTranslation(options, value) {
  if (options.filter((o) => o.value == value)[0]) {
    return options.filter((o) => o.value == value)[0].label;
  }
  return "";
}
