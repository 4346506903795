import React, { useState, useEffect, useContext } from "react";
import { LocalesProvider, LocalesContext } from "./contexts/LocalesContext";
import { MqttProvider, MqttContext } from "./contexts/MqttContext";
import SectionContentComponent from "./components/SectionContentComponent";
import SectionContentBlockComponent from "./components/SectionContentBlockComponent";
import ActionbarComponent from "./components/ActionbarComponent";
import CardComponent from "./components/CardComponent";
import ButtonComponent from "./components/ButtonComponent";
import LoadingComponent from "./components/LoadingComponent";
import MessageComponent from "./components/MessageComponent";
import DashboardNav from "./irriapp/navs/DashboardNav";
import PresetsNav from "./irriapp/navs/PresetsNav";
import SetupNav from "./irriapp/navs/SetupNav";
import DiagnosticNav from "./irriapp/navs/DiagnosticNav";
import RealtimeComponent from "./irriapp/components/RealtimeComponent";
import ModalAlarmsComponent from "./irriapp/components/ModalAlarmsComponent";
import {
  SvgWork,
  SvgPreset,
  SvgSetup,
  SvgDiagnostic,
} from "./components/SvgIcons";
import { getMachineParamsValue } from "./utils/irriapp";

const navsMap = {
  dashboard: DashboardNav,
  presets: PresetsNav,
  setup: SetupNav,
  diagnostic: DiagnosticNav,
};

function Irriapp(props) {
  const { t, actions: localesActions } = useContext(LocalesContext);
  const {
    status: mqttStatus,
    actions: mqttActions,
    machineParams,
    machineRtStatus,
  } = useContext(MqttContext);
  const [currentScreen, setCurrentScreen] = useState("dashboard");
  const [setupDisabled, setSetupDisabled] = useState(false);
  const [showAlarms, setShowAlarms] = useState(false);
  const [loadingMachine, setLoadingMachine] = useState(true);

  const machineParamsValid =
    machineParams &&
    Object.keys(machineParams).includes("conf_logo") &&
    Object.keys(machineParams).includes("diagnostic_stato_motore_1");

  // EFFECTS ******************************************************************

  // inizializzazione
  useEffect(() => {
    localesActions.setLocale(props.locale);
    localesActions.setTranslations(props.translations);
    mqttActions.connect(props.mqtt_credentials);

    // imposto listener messaggio dati per aggiornamento rialtime parametri macchina e rt status
    mqttActions.addMessageListener(
      "MACHINE_DATA",
      (message, machineParams, machineRtStatus) => {
        mqttActions.setMachineParams(
          Object.assign({}, machineParams, message.data)
        );
        if (machineRtStatus === "disconnected")
          mqttActions.setMachineRtStatus("connected");
        if (
          machineRtStatus === "connected" &&
          machineParams.work_status?.value === "opEsecuzione"
        )
          mqttActions.setMachineRtStatus("working");
        if (
          machineRtStatus === "working" &&
          machineParams.work_status?.value === "opSrotolamentoTubo"
        )
          mqttActions.setMachineRtStatus("connected");

        for (const data in message.data) {
          if (data == "work_alarm_status") {
            setTimeout(() => mqttActions.getMachineParams(() => {}, false), 15000);
          }
        }
      },
      false
    );

    // imposto listener messaggio disconnessione per aggiornamento realtime rt status
    mqttActions.addMessageListener(
      "BYEBYE",
      (message) => {
        mqttActions.setMachineRtStatus("disconnected");
      },
      false
    );
  }, []);

  // lettura stato macchina ed eventuale aggiornamento real-time
  useEffect(() => {
    if (mqttStatus) {
      // eseguo una richiesta di lettura aggiornata di tutti i dati della macchina
      mqttActions.sendMessageWithAnswer(
        "RD_DATA",
        { dataGroup: "work" },
        "MACHINE_DATA",
        (answer) => {
          setLoadingMachine(false);

          // imposto la macchina disconnessa se non risponde alla prima richiesta di lettura
          if (!answer) {
            mqttActions.setMachineRtStatus("disconnected");
            return;
          }

          mqttActions.sendMessageWithAnswer(
            "RD_DATA",
            { dataGroup: "diagnostic" },
            "MACHINE_DATA",
            () => {
              mqttActions.sendMessageWithAnswer(
                "RD_DATA",
                { dataGroup: "program" },
                "MACHINE_DATA",
                () => {
                  mqttActions.sendMessageWithAnswer(
                    "RD_DATA",
                    { dataGroup: "config" },
                    "MACHINE_DATA",
                    () => {}
                  );
                }
              );
            }
          );
        }
      );
    }
  }, [mqttStatus]);

  // lettura parametri maccina e eliminazione loading machine se si è certi che non è connessa
  useEffect(() => {
    if (machineRtStatus && machineRtStatus == "disconnected") {
      setLoadingMachine(false);
    }
  }, [machineRtStatus, machineParams]);

  // gestione validità area di setup in base a stato macchina
  useEffect(() => {
    const statusValue = getMachineParamsValue(machineParams, "work_status");

    if (machineRtStatus == "disconnected") {
      setSetupDisabled(true);
    } else if (statusValue === "opSrotolamentoTubo") {
      setSetupDisabled(false);
    } else {
      setSetupDisabled(true);
    }
  }, [machineParams, machineRtStatus]);

  // RENDER *******************************************************************

  const actionbarActions = [
    {
      label: t("application.app_actionbar.work"),
      active: currentScreen === "dashboard",
      onClick: () => setCurrentScreen("dashboard"),
      icon: <SvgWork />,
    },
    {
      label: t("application.app_actionbar.presets"),
      active: currentScreen === "presets",
      onClick: () => setCurrentScreen("presets"),
      icon: <SvgPreset />,
    },
    {
      label: t("application.app_actionbar.setup"),
      active: currentScreen === "setup",
      onClick: () => setCurrentScreen("setup"),
      icon: <SvgSetup />,
      disabled: !machineParamsValid || setupDisabled,
    },
    {
      label: t("application.app_actionbar.diagnostic"),
      active: currentScreen === "diagnostic",
      onClick: () => setCurrentScreen("diagnostic"),
      icon: <SvgDiagnostic />,
      disabled: !machineParamsValid,
    },
  ];

  const CurrentNavComponent = navsMap[currentScreen];

  if (mqttActions.connectStatus("loading") || loadingMachine) {
    return <LoadingComponent fullscreen />;
  } else if (mqttActions.connectStatus("disconnected")) {
    return (
      <div style={{ width: "100%", height: "90vh", position: "relative" }}>
        <CardComponent
          className="c-card--centerpage"
          style={{ position: "relative" }}
        >
          <MessageComponent
            title={t("application.messages.connection_error_title")}
            message={t("application.messages.connection_error_message")}
            extraContent={() => (
              <ButtonComponent
                label={t("application.cta.retry")}
                onClick={() => mqttActions.connect(props.mqtt_credentials)}
              />
            )}
          />
        </CardComponent>
      </div>
    );
  } else if (mqttActions.connectStatus("connected")) {
    return (
      <>
        <SectionContentComponent className="r-app-structure">
          <SectionContentBlockComponent
            className="r-app-structure__bar"
            sizes={{ default: 24, md: 6 }}
          >
            <ActionbarComponent actions={actionbarActions} />
          </SectionContentBlockComponent>
          <SectionContentBlockComponent
            className="r-app-structure__content"
            sizes={{ default: 24, md: 18 }}
          >
            <div className="r-app-structure__content-head">
              <RealtimeComponent
                {...props}
                showAlarms={() => setShowAlarms(true)}
              />
            </div>
            <div className="r-app-structure__content-main">
              <CurrentNavComponent {...props} />
            </div>
          </SectionContentBlockComponent>
        </SectionContentComponent>
        <ModalAlarmsComponent
          {...props}
          status={showAlarms}
          onClose={() => setShowAlarms(false)}
        />
      </>
    );
  }
}

export default (props) => {
  return (
    <LocalesProvider>
      <MqttProvider
        apis={props.apis}
        machineUuid={props.machine_uuid}
        machineMqtt={props.machine_mqtt}
      >
        <Irriapp {...props} />
      </MqttProvider>
    </LocalesProvider>
  );
};
