export function flashDanger (message) {
  const Flash = window.$matildaCore.app.getControllerForElementAndIdentifier(
    document.getElementById('flash'), 'flash'
  )

  Flash.openMessage(message, 'orange')
}

export function flashSuccess (message) {
  const Flash = window.$matildaCore.app.getControllerForElementAndIdentifier(
    document.getElementById('flash'), 'flash'
  )

  Flash.openMessage(message, 'green')
}