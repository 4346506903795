import React, { useState, useContext } from "react"
import ModalComponent from "../../components/ModalComponent"
import CheckboxComponent from '../../components/CheckboxComponent'
import ButtonComponent from "../../components/ButtonComponent"
import { LocalesContext } from '../../contexts/LocalesContext'

export function SecureComponent(props) {
  const { t } = useContext(LocalesContext) 

  return (
    <ModalComponent status={props.visible} title={t("application.titles.configuration_confirm")} >
      <CheckboxComponent
        label={t("irriapp.messages.alert_secure_check")}
        value={props.valid}
        onChange={(value) => {
          if (value) props.valiateModal()
        }}
      ></CheckboxComponent>

      <div style={{ textAlign: 'right', marginTop: 30 }}>
        <ButtonComponent label={t('application.cta.confirm')} disabled={!props.valid} onClick={() => props.confirmModal()} />
      </div>
    </ModalComponent>
  );
}

export function useSecureComponent () {
  const [visible, setVisible] = useState(false)
  const [valid, setValid] = useState(false)
  const [callback, setCallback] = useState(null)

  const activateModal = (cb = () => {}) => {
    setVisible(true)
    setCallback({ cb })
  }

  const valiateModal = () => {
    setValid(true)
  }

  const confirmModal = () => {
    if (callback) callback.cb()
    setVisible(false)
    setValid(false)
    setCallback(null)
  }

  return [{
    visible, valid, valiateModal, confirmModal
  }, activateModal]
}