/**
 *
 * @param {*} data
 * @returns
 */
export function stringFromArrayOfBytes(data) {
  var encodedString = String.fromCharCode.apply(null, data);
  var decodedString = decodeURIComponent(escape(encodedString));
  return decodedString;
}

/**
 *
 * @returns
 */
export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 *
 * @param {*} number
 * @returns
 */
export function numberToDateNumber(number) {
  if (number > 9) return number;
  return `0${number}`;
}

/**
 *
 * @param {*} date
 * @returns
 */
export function dateToInputString(date) {
  return `${date.getFullYear()}-${numberToDateNumber(
    date.getMonth() + 1
  )}-${numberToDateNumber(date.getDate())}`;
}

/**
 *
 * @param {*} time
 * @returns
 */
export function timeToInputString(time) {
  return `${numberToDateNumber(time.getHours())}:${numberToDateNumber(
    time.getMinutes()
  )}`;
}

/**
 *
 * @param {*} date
 * @returns
 */
export function dateToPrettyString(date) {
  return `${numberToDateNumber(date.getDate())}/${numberToDateNumber(
    date.getMonth() + 1
  )}/${date.getFullYear()}`;
}

/**
 *
 * @param {*} time
 * @returns
 */
export function timeToPrettyString(time) {
  return `${numberToDateNumber(time.getHours())}:${numberToDateNumber(
    time.getMinutes()
  )}`;
}

/**
 *
 * @param {*} datetime
 * @returns
 */
export function datetimeToPrettyString(datetime) {
  return `${dateToPrettyString(datetime)} ${timeToPrettyString(datetime)}`;
}

/**
 *
 * @param {*} obj
 * @param {*} objKeys
 * @param {*} defaultValue
 * @returns
 */
export function objValueOrDefault(obj, objKeys, defaultValue = null) {
  let value = obj;

  for (let i = 0; i < objKeys.length; i++) {
    const key = objKeys[i];
    if (!value[key]) return defaultValue;
    value = value[key];
  }

  return value;
}

/**
 *
 * @param {*} seconds
 * @returns
 */
export function secondsToPrettyTime(seconds) {
  var d = Number(seconds);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);

  var hDisplay = h > 0 ? h + "h" : "";
  var mDisplay = m > 0 ? m + "min" : "";
  return hDisplay + " " + mDisplay;
}

/**
 * NOTE: Per farla funzionare bisogna che la funzione asyncFn faccia un reject se tutto è negativo.
 * @param {*} values
 * @param {*} asyncFn
 */
export function runPromisesSequence(values, asyncFn) {
  return new Promise(function (resolve, reject) {
    // Are there any values to check?
    if (values.length === 0) {
      // All were rejected
      reject();
    }
    // Try the first value
    asyncFn(values[0])
      .then(function (val) {
        // Resolved, we're all done
        resolve(val);
      })
      .catch(function () {
        // Rejected, remove the first item from the array and recursively
        // try the next one
        values.shift();
        if (values.length) {
          runPromisesSequence(values, asyncFn).then(resolve).catch(reject);
        } else {
          resolve();
        }
      });
  });
}

/**
 *
 * @param {*} dateStart
 * @param {*} dateEnd
 */
export function getDatesPerMinuteBetweenDates(dateStart, dateEnd) {
  for (
    var arr = [], ds = new Date(dateStart), de = new Date(dateEnd);
    ds <= dateEnd;
    ds.setMinutes(ds.getMinutes() + 1)
  ) {
    arr.push(new Date(ds));
  }
  return arr;
}
