import { dateToPrettyString, timeToPrettyString, dateToInputString, timeToInputString } from './data'

/**
 * @function getPresetSectorsLength
 * @param {*} presetParams 
 */
export function getPresetSectorsLength(presetParams) {
  let length = 0
  if (presetParams.s1length) length += presetParams.s1length
  if (presetParams.s2length) length += presetParams.s2length
  if (presetParams.s3length) length += presetParams.s3length
  if (presetParams.s4length) length += presetParams.s4length
  return length
}

/**
 * @function getPresetSectorsNumber
 * @param {*} presetParams 
 */
export function getPresetSectorsNumber(presetParams) {
  let number = 0
  if (presetParams.s1length) number += 1
  if (presetParams.s2length) number += 1
  if (presetParams.s3length) number += 1
  if (presetParams.s4length) number += 1
  return number
}

/**
 * @function getMachineParamsValue
 * @param {*} params 
 * @param {*} key 
 * @param {*} placeholder 
 */
export function getMachineParamsValue(params, key, placeholder = null) {
  return params && params[key] ? params[key].value : placeholder
}

/**
 * @function getMachineParamsUnit
 * @param {*} params 
 * @param {*} key 
 * @param {*} placeholder 
 */
export function getMachineParamsUnit(params, key, placeholder = null) {
  return params && params[key] ? getUnitLabel(params[key].unit) : placeholder
}

/**
 * @function getMachineParamsComplete
 * @param {*} params 
 * @param {*} key 
 * @param {*} placeholder 
 */
export function getMachineParamsComplete(params, key, placeholder = null) {
  const value = getMachineParamsValue(params, key)
  const unit = getMachineParamsUnit(params, key)
  return value != null ? `${value} ${unit}` : placeholder
}

/**
 * @function getMachineParamsValueDate
 * @param {*} params 
 * @param {*} key 
 * @param {*} placeholder 
 */
export function getMachineParamsValueDate(params, key, placeholder = null) {
  let date = getMachineParamsValue(params, key)
  if (!date) return placeholder

  date = new Date(date * 1000)
  return {
    date: dateToPrettyString(date),
    time: timeToPrettyString(date),
    input_date: dateToInputString(date),
    input_time: timeToInputString(date)
  }
}

/**
 * @function getUnitLabel
 * @param {*} unit 
 */
export function getUnitLabel(unit) {
  const map = {
    'm3' : 'm³'
  }
  return map[unit] || unit || ''
}

/**
 * @function listenUpdateOfKeyParam
 * @param {*} mqttActions 
 * @param {*} msg 
 * @param {*} key 
 * @param {*} value 
 * @param {*} callback 
 * @param {*} timeout 
 */
export function listenUpdateOfKeyParam(mqttActions, msg, key, value, callback, timeout = 15000) {
  const listenerId = mqttActions.addMessageListener(msg, (message) => {
    const newValue = getMachineParamsValue(message.data, key)
    if ((value && newValue === value) || (!value && newValue)) {
      clearTimeout(listenerTimeout)
      callback(true)
    }
  }, false)

  const listenerTimeout = setTimeout(() => {
    mqttActions.removeMessageListener(listenerId)
    callback(false)
  }, timeout)
}

export function unitaMisuraIntToString(int) {
  return ['eu', 'uk', 'us'][int]
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////
// Originali su algoritmi.js in root

function configurationHeightToSpeed(mouth_pressure, mouth_diameter, bandwidth, height) {
  let portata_fissa_memoria = parseInt((parseInt(Math.sqrt(mouth_pressure * 10 * 10340)) * mouth_diameter * mouth_diameter * 198) / 100000)
  let portata_fissa_video = parseInt(portata_fissa_memoria * 6 / 10) / 10
  return parseInt(parseInt(10000 * parseInt(portata_fissa_video) / bandwidth) / height)
}

function configurationSpeedToHeight(mouth_pressure, mouth_diameter, bandwidth, speed) {
  let portata_fissa_memoria = parseInt((parseInt(Math.sqrt(mouth_pressure * 10 * 10340)) * mouth_diameter * mouth_diameter * 198) / 100000)
  let portata_fissa_video = parseInt(portata_fissa_memoria * 6 / 10) / 10
  return parseInt(parseInt(10000 * parseInt(portata_fissa_video) / bandwidth) / speed)
}

export function configurationTimePrevision(configuration) {
  const { pause_start, pause_end, s1length, s1speed, s1height, s2length, s2speed, s2height, s3length, s3speed, s3height, s4length, s4speed, s4height, work_mod, mouth_pressure, mouth_diameter, bandwidth } = configuration
  let timePrevision = 0
  let speed_1 = s1speed * 10
  let speed_2 = s2speed * 10
  let speed_3 = s3speed * 10
  let speed_4 = s4speed * 10
  let pausa_iniziale = (pause_start || 0) * 60
  let pausa_finale = (pause_end || 0) * 60

  if (work_mod == 'mm') {
    speed_1 = configurationHeightToSpeed(mouth_pressure, mouth_diameter, bandwidth, s1height)
    speed_2 = configurationHeightToSpeed(mouth_pressure, mouth_diameter, bandwidth, s2height)
    speed_3 = configurationHeightToSpeed(mouth_pressure, mouth_diameter, bandwidth, s3height)
    speed_4 = configurationHeightToSpeed(mouth_pressure, mouth_diameter, bandwidth, s4height)

    if (speed_1 < 40 || speed_1 > 8500) return null
    if (speed_2 < 40 || speed_2 > 8500) return null
    if (speed_3 < 40 || speed_3 > 8500) return null
    if (speed_4 < 40 || speed_4 > 8500) return null
  }

  if (pause_start) timePrevision += pausa_iniziale
  if (s1length && s1length > 0) timePrevision += parseInt((s1length / speed_1) * 3600 * 10)
  if (s2length && s2length > 0) timePrevision += parseInt((s2length / speed_2) * 3600 * 10)
  if (s3length && s3length > 0) timePrevision += parseInt((s3length / speed_3) * 3600 * 10)
  if (s4length && s4length > 0) timePrevision += parseInt((s4length / speed_4) * 3600 * 10)
  if (pause_end) timePrevision += pausa_finale

  return timePrevision
}

export function configurationRecalcByTime(configuration, newTimeStart, newTimeEnd) {
  const { work_mod, datetime_end, datetime_start, s1speed, s2speed, s3speed, s4speed, s1height, s2height, s3height, s4height, s1length, s2length, s3length, s4length, mouth_pressure, mouth_diameter, bandwidth, pause_start, pause_end } = configuration 
  if (!datetime_end || !datetime_start) return configuration

  let speed_1 = (s1speed || 0) * 10
  let speed_2 = (s2speed || 0) * 10
  let speed_3 = (s3speed || 0) * 10
  let speed_4 = (s4speed || 0) * 10
  let pausa_iniziale = (pause_start || 0) * 60
  let pausa_finale = (pause_end || 0) * 60
  let moltiplicatore = 10000

  if (work_mod == 'mm') {
    speed_1 = configurationHeightToSpeed(mouth_pressure, mouth_diameter, bandwidth, s1height)
    speed_2 = configurationHeightToSpeed(mouth_pressure, mouth_diameter, bandwidth, s2height)
    speed_3 = configurationHeightToSpeed(mouth_pressure, mouth_diameter, bandwidth, s3height)
    speed_4 = configurationHeightToSpeed(mouth_pressure, mouth_diameter, bandwidth, s4height)
  }

  const durataInizialeParziale = datetime_end - datetime_start - pausa_iniziale - pausa_finale
  const durataFinaleParziale = newTimeEnd - newTimeStart - pausa_iniziale - pausa_finale
  const fattoreProporzione = parseInt((1 - (durataInizialeParziale - durataFinaleParziale) / durataInizialeParziale) * moltiplicatore)

  if (speed_1) speed_1 = parseInt((speed_1 / fattoreProporzione) * moltiplicatore)
  if (speed_2) speed_2 = parseInt((speed_2 / fattoreProporzione) * moltiplicatore)
  if (speed_3) speed_3 = parseInt((speed_3 / fattoreProporzione) * moltiplicatore)
  if (speed_4) speed_4 = parseInt((speed_4 / fattoreProporzione) * moltiplicatore)

  let durataSettori = 0
  if (speed_1) durataSettori += parseInt((s1length / speed_1) * 3600 * 10)
  if (speed_2) durataSettori += parseInt((s2length / speed_2) * 3600 * 10)
  if (speed_3) durataSettori += parseInt((s3length / speed_3) * 3600 * 10)
  if (speed_4) durataSettori += parseInt((s4length / speed_4) * 3600 * 10)

  configuration.datetime_start = newTimeStart
  configuration.datetime_end = newTimeStart + durataSettori + pausa_iniziale + pausa_finale
  if (work_mod == 'mm') {
    if (s1height && s1length && s1length > 0) configuration.s1height = configurationSpeedToHeight(mouth_pressure, mouth_diameter, bandwidth, speed_1)
    if (s2height && s2length && s2length > 0) configuration.s2height = configurationSpeedToHeight(mouth_pressure, mouth_diameter, bandwidth, speed_2)
    if (s3height && s3length && s3length > 0) configuration.s3height = configurationSpeedToHeight(mouth_pressure, mouth_diameter, bandwidth, speed_3)
    if (s4height && s4length && s4length > 0) configuration.s4height = configurationSpeedToHeight(mouth_pressure, mouth_diameter, bandwidth, speed_4)
  } else {
    if (speed_1 && s1length && s1length > 0) configuration.s1speed = speed_1 / 10
    if (speed_2 && s2length && s2length > 0) configuration.s2speed = speed_2 / 10
    if (speed_3 && s3length && s3length > 0) configuration.s3speed = speed_3 / 10
    if (speed_4 && s4length && s4length > 0) configuration.s4speed = speed_4 / 10
  }

  return configuration
}
