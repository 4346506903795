import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { LocalesContext } from '../contexts/LocalesContext'

export default function CardComponent(props) {
  const { t } = useContext(LocalesContext)
  let className = `c-card ${props.className}` 
  if (props.ctaFunction) className = `${className} c-card--hasedit`

  return (
    <div className={className} style={props.style}>
      {props.title && (
        <div className="c-card__header">
          {props.title && (
            <div className="c-card__header-title">
              {props.title}
            </div>
          )}
          <ul className="c-card__header-actions">
            {props.actions.map((action, index) => {
              return (
                <li className="c-card__header-action" key={index}>
                  <button className="c-button" onClick={action.onClick}>{action.label || action.icon()}</button>
                </li>
              )
            })}
          </ul>
        </div>
      )}
      <div className="c-card__body">
        {props.children}
      </div>
      {props.ctaFunction && (
        <a className="c-card__edit" onClick={props.ctaFunction}>
          {props.ctaLabel || t('application.cta.edit')}{props.ctaIcon || <i className="fas fa-pen"></i>}
        </a>
      )}
    </div>
  )
}

CardComponent.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  ctaFunction: PropTypes.func,
  ctaLabel: PropTypes.string,
  ctaIcon: PropTypes.any,
  style: PropTypes.object,
  actions: PropTypes.array
}

CardComponent.defaultProps = {
  className: '',
  title: '',
  actions: []
}
