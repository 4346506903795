import React, { useContext } from 'react'
import CardComponent from '../../components/CardComponent'
import { gridSizesClassName } from '../../utils/gridSizes'
import { getMachineParamsValue, getMachineParamsValueDate } from '../../utils/irriapp'
import { LocalesContext } from '../../contexts/LocalesContext'

export default function ProgressComponent({ machineParams, sizes, completed, paused, editEnd }) {
  const { t } = useContext(LocalesContext)
  const statoSessione = getMachineParamsValue(machineParams, 'work_session_status')
  const settoreSessions = getMachineParamsValue(machineParams, 'work_current_sector')
  const pausaInizio = statoSessione === 'srPausaInizio'
  const pausaFine = statoSessione === 'srPausaFine'

  const points = []
  if (getMachineParamsValue(machineParams, 'program_pause_start')) { points.push(1) }
  if (getMachineParamsValue(machineParams, 'program_s1length')) { points.push(2) }
  if (getMachineParamsValue(machineParams, 'program_s2length')) { points.push(3) }
  if (getMachineParamsValue(machineParams, 'program_s3length')) { points.push(4) }
  if (getMachineParamsValue(machineParams, 'program_s4length')) { points.push(5) }
  if (getMachineParamsValue(machineParams, 'program_pause_end')) { points.push(6) }

  let currentPoint = 0
  let title = ''
  if (pausaInizio) {
    currentPoint = 1
    title = t('irriapp.labels.pause_start')
  } else if (pausaFine) {
    currentPoint = 6
    title = t('irriapp.labels.pause_end')
  } else if (settoreSessions) {
    currentPoint = settoreSessions + 1
    title = `${t('irriapp.titles.sector')} ${settoreSessions}`
  }

  if (completed) {
    currentPoint = 9999
    title = t('application.labels.work_status_completed')
  }

  if (paused) {
    currentPoint = 0
    title = t('application.labels.work_status_paused')
  }

  const timeEnd = getMachineParamsValueDate(machineParams, 'work_final_time')

  return(
    <CardComponent className={`r-progress ${gridSizesClassName(sizes)} ${points.length < 2 ? 'is-single' : ''}`} ctaFunction={completed ? null : editEnd} >
      {points.length > 1 && (
        <div className="r-progress__line">
          {points.map((point, index) => {
            const pointCompleted = currentPoint > point
            const pointRunning = currentPoint == point

            let icon = 'fa-circle'
            if (pointCompleted) { icon = 'fa-check-circle'}
            if (pointRunning) { icon = 'fa-dot-circle'}
            if (paused) { icon = 'fa-pause-circle' }

            return <div className={`${pointRunning ? 'is-running' : ''} ${pointCompleted ? 'is-completed' : ''}`} key={index}><i className={`fas ${icon}`}></i></div>
          })}
        </div>
      )}
      <div className="r-progress__label">
        <div className="main">{t('application.titles.work_status')}: <span>{title}</span></div>
        {!completed && <div className="time">{t('irriapp.titles.work_end_time_previon')}: <span>{timeEnd.time}</span></div>}
      </div>
    </CardComponent>
  )
}