import React, { useContext, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import StatComponent from "../../components/StatComponent";
import IconComponent from "../../components/IconComponent";
import ProgressComponent from "../components/ProgressComponent";
import ButtonsGroupComponent from "../../components/ButtonsGroupComponent";
import ButtonComponent from "../../components/ButtonComponent";
import MessageComponent from "../../components/MessageComponent";
import ConfResumeComponent from "../components/ConfResumeComponent";
import CardComponent from "../../components/CardComponent";
import ModalComponent from "../../components/ModalComponent";
import InputTextComponent from "../../components/InputTextComponent";
import InputNumberComponent from "../../components/InputNumberComponent";
import LoadingComponent from "../../components/LoadingComponent";
import { LocalesContext } from "../../contexts/LocalesContext";
import { MqttContext } from "../../contexts/MqttContext";
import { objValueOrDefault } from "../../utils/data";
import { gridSizesClassName } from "../../utils/gridSizes";
import { flashDanger, flashSuccess } from "../../utils/flash";
import {
  getMachineParamsValue,
  getMachineParamsUnit,
  getMachineParamsValueDate,
  listenUpdateOfKeyParam,
  unitaMisuraIntToString,
} from "../../utils/irriapp";
import {
  SecureComponent,
  useSecureComponent,
} from "../components/SecureComponent";
import { convertUnit, convertValue } from '../../utils/converter'

export default function MachineScreen(props) {
  const { t } = useContext(LocalesContext);
  const {
    machineParams,
    machineRtStatus,
    actions: mqttActions,
  } = useContext(MqttContext);

  const inModeMillimetri = getMachineParamsValue(machineParams, 'work_irrigation_type') == 'mm'

  const [ctaEnabled, setCtaEnabled] = useState(true);
  const [editEndModal, setEditEndModal] = useState(false);
  const [editEndLoading, setEditEndLoading] = useState(false);
  const [editEndFormValues, setEditEndFormValuesModal] = useState({});
  const [editSpeedModal, setEditSpeedModal] = useState(false);
  const [editSpeedLoading, setEditSpeedLoading] = useState(false);
  const [editSpeedFormValues, setEditSpeedFormValuesModal] = useState({});

  // identifico lo stato della macchina mediante la combinazioni degli stati ufficiali.
  const stato = getMachineParamsValue(machineParams, "work_status");
  const statoSessione = getMachineParamsValue(
    machineParams,
    "work_session_status"
  );

  const inAttesa = ["opSrotolamentoTubo"].includes(stato);
  const inPreparazione =
    ["opEsecuzione", "vdPassword"].includes(stato) &&
    ["srAperturaBypassInizio", "srAttesaMotorMatic"].includes(statoSessione);
  const inProgramma =
    ["opEsecuzione", "vdPassword"].includes(stato) &&
    ["srOrarioInizio"].includes(statoSessione);
  const inLavoro =
    ["opEsecuzione", "vdPassword"].includes(stato) &&
    [
      "srPausaInizio",
      "srRegolazioneBypass",
      "srPausaFine",
      "srAperturaBypassEmergenza",
    ].includes(statoSessione);
  const inMovimento =
    (
      ["opEsecuzione", "vdPassword"].includes(stato) &&
      [
        "srChiusuraBypassPerCarrello",
        "srAperturaBypassEmergenza",
        "srAperturaBypassFermaRegolazione",
      ].includes(statoSessione)
    ) || (
      ["vdInizializzaValvole"].includes(stato) &&
      [
        "srAttesa",
      ].includes(statoSessione)
    )
  ;
  const inPausa =
    ["opEsecuzione", "vdPassword"].includes(stato) &&
    ["srAttesaFermaRegolazione"].includes(statoSessione);
  const inFine =
    ["opEsecuzione", "vdPassword"].includes(stato) &&
    ["srAttesa"].includes(statoSessione);
  const inUsoFisico = [
    "opOraInizio",
    "opSettori",
    "opCriterioRegolazione",
    "opPausaInizio",
    "opCriterioAltezza",
    "opPausaFine",
    "opOraFine",
    "vdConfigurazione",
    "vdMenu",
  ].includes(stato); // caso di utilizzo macchina da locale
  const inSetup = ["vdNetScritturaRemota"].includes(stato);
  const inRiepilogo = ["opRiepilogo"].includes(stato);
  const [secureComponentProps, activeSecureComponent] = useSecureComponent();

  useEffect(() => {
    if (!machineParams) return;
    console.log('machineParams', machineParams)
    if (machineParams.work_final_time) {
      const timeData = getMachineParamsValueDate(
        machineParams,
        "work_final_time"
      );
      if (!timeData) return;

      setEditEndFormValuesModal({
        date_end: timeData.input_date,
        time_end: timeData.input_time,
      });
    }
    if (
      !editSpeedFormValues.speed && machineParams.work_speed_current_sector_prevision?.value !== editSpeedFormValues.speed
    ) {
      onEditSpeedValueChange(
        "speed",
        inModeMillimetri ? machineParams.work_height_current_sector_prevision.value : machineParams.work_speed_current_sector_prevision.value
      );
    }
  }, [machineParams]);

  // FUNCTIONS
  /////////////////////////////////////////////////////////////////////////////////////////////

  const onLoadConfiguration = () => {
    props.goTo("configuration");
  };

  const onPauseWork = () => {
    activeSecureComponent(() => {
      setCtaEnabled(false);
      mqttActions.sendMessageWithAnswer("STOP_IRR", {}, "COMMAND_ACK", () => {
        listenUpdateOfKeyParam(
          mqttActions,
          "MACHINE_DATA",
          "work_session_status",
          "srAperturaBypassFermaRegolazione",
          () => {
            setCtaEnabled(true);
          }
        );
      });
    });
  };

  const onCancelWork = () => {
    setCtaEnabled(false);
    mqttActions.sendMessageWithAnswer("ALT_IRR", {}, "COMMAND_ACK", () => {
      listenUpdateOfKeyParam(
        mqttActions,
        "MACHINE_DATA",
        "work_session_status",
        null,
        () => {
          setCtaEnabled(true);
        }
      );
    });
  };

  const onStopWork = () => {
    activeSecureComponent(() => {
      setCtaEnabled(false);
      mqttActions.sendMessageWithAnswer("ALT_IRR", {}, "COMMAND_ACK", () => {
        listenUpdateOfKeyParam(
          mqttActions,
          "MACHINE_DATA",
          "work_session_status",
          null,
          () => {
            setCtaEnabled(true);
          }
        );
      });
    });
  };

  const onRestartWork = () => {
    activeSecureComponent(() => {
      setCtaEnabled(false);
      mqttActions.sendMessageWithAnswer("START_IRR", {}, "COMMAND_ACK", () => {
        listenUpdateOfKeyParam(
          mqttActions,
          "MACHINE_DATA",
          "work_session_status",
          "srRegolazioneBypass",
          () => {
            setCtaEnabled(true);
          }
        );
      });
    });
  };

  const onStartWork = () => {
    activeSecureComponent(() => {
      setCtaEnabled(false);
      mqttActions.sendMessageWithAnswer(
        "WST_IRR",
        { datetime_start: new Date().getTime() },
        "COMMAND_ACK",
        () => {
          listenUpdateOfKeyParam(
            mqttActions,
            "MACHINE_DATA",
            "work_starting_time",
            null,
            () => {
              setCtaEnabled(true);
            }
          );
        }
      );
    });
  };

  const onEndWork = () => {
    setCtaEnabled(false);
    mqttActions.sendMessageWithAnswer("END_REG", {}, "COMMAND_ACK", () => {
      listenUpdateOfKeyParam(
        mqttActions,
        "MACHINE_DATA",
        "work_status",
        "opSrotolamentoTubo",
        () => {
          setCtaEnabled(true);
        }
      );
    });
  };

  const onConfirmConfiguration = () => {
    setCtaEnabled(false);
    mqttActions.sendMessageWithAnswer("START_IRR", {}, "COMMAND_ACK", () => {
      listenUpdateOfKeyParam(
        mqttActions,
        "MACHINE_DATA",
        "work_session_status",
        "srRegolazioneBypass",
        () => {
          setCtaEnabled(true);
        }
      );
    });
  };

  const onCancelConfiguration = () => {
    setCtaEnabled(false);
    mqttActions.sendMessageWithAnswer("END_PRG", {}, "COMMAND_ACK", () => {
      listenUpdateOfKeyParam(
        mqttActions,
        "MACHINE_DATA",
        "work_status",
        "opSrotolamentoTubo",
        () => {
          setCtaEnabled(true);
        }
      );
    });
  };

  // funzioni di gestione modifica orario di fine lavoro
  const onEditEndRequest = () => {
    setEditEndModal(true);
    setEditEndLoading(false);
  };
  const onEditEndRequestConfirm = () => {
    setEditEndLoading(true);
    const newDate = new Date(
      `${editEndFormValues.date_end} ${editEndFormValues.time_end}`
    );
    mqttActions.sendMessageWithAnswer(
      "WET_IRR",
      { datetime_end: newDate.getTime() },
      "COMMAND_ACK",
      (response) => {
        if (response.data.result) {
          flashSuccess(t("application.messages.edit_success"));
          setEditEndModal(false);
        } else {
          flashDanger(t("application.messages.edit_error"));
          setEditEndLoading(false);
        }
      }
    );
  };
  const onEditEndValueChange = (name, value) => {
    const newEditEndFormValues = Object.assign({}, editEndFormValues);
    newEditEndFormValues[name] = value;
    setEditEndFormValuesModal(newEditEndFormValues);
  };

  // funzioni di gestione modifica velocità attuale
  const onEditSpeedRequest = () => {
    setEditSpeedModal(true);
    setEditEndLoading(false);
  };
  const onEditSpeedRequestConfirm = () => {
    setEditSpeedLoading(true);
    mqttActions.sendMessageWithAnswer(
      "WSC_IRR",
      {
        sector: machineParams.work_current_sector.value,
        speed: editSpeedFormValues.speed * (inModeMillimetri ? 1 : 10),
      },
      "COMMAND_ACK",
      (response) => {
        if (response.data.result) {
          flashSuccess(t("application.messages.edit_success"));
          setEditSpeedModal(false);
          setEditSpeedLoading(false);
        } else {
          flashDanger(t("application.messages.edit_error"));
          setEditSpeedLoading(false);
        }
      }
    );
  };
  const onEditSpeedValueChange = (name, value) => {
    const newEditSpeedFormValues = Object.assign({}, editSpeedFormValues);
    newEditSpeedFormValues[name] = value;
    setEditSpeedFormValuesModal(newEditSpeedFormValues);
  };

  // RENDER
  /////////////////////////////////////////////////////////////////////////////////////////////
  if (machineRtStatus == "disconnected") {
    return (
      <div className="c-grid__container-spaced">
        <MessageComponent
          title={t("application.messages.machine_not_connected")}
        />
      </div>
    );
  }

  return (
    <div className="c-grid__container-spaced c-grid__container-spaced--flex">
      {inAttesa && <InAttesaInterface machineParams={machineParams} t={t} />}
      {inProgramma && (
        <InProgrammaInterface machineParams={machineParams} t={t} />
      )}
      {inPreparazione && (
        <InPreparazioneInterface machineParams={machineParams} t={t} />
      )}
      {inLavoro && (
        <InLavoroInterface
          machineParams={machineParams}
          t={t}
          editEnd={onEditEndRequest}
          editSpeed={onEditSpeedRequest}
        />
      )}
      {inMovimento && (
        <InMovimentoInterface machineParams={machineParams} t={t} />
      )}
      {inPausa && <InPausaInterface machineParams={machineParams} t={t} />}
      {inFine && <InFineInterface machineParams={machineParams} t={t} />}
      {inUsoFisico && (
        <InUsoFisicoInterface
          machineParams={machineParams}
          mqttActions={mqttActions}
          t={t}
        />
      )}
      {inSetup && (
        <InSetupInterface
          machineParams={machineParams}
          t={t}
          mqttActions={mqttActions}
        />
      )}
      {inRiepilogo && (
        <InRiepilogoInterface machineParams={machineParams} t={t} />
      )}

      <ButtonsGroupComponent sizes={{ default: 24, sm: 24 }}>
        {inAttesa && (
          <ButtonComponent
            label={t("application.cta.load_configuration")}
            onClick={onLoadConfiguration}
            disabled={!ctaEnabled}
          />
        )}
        {inProgramma && (
          <ButtonComponent
            label={t("application.cta.start")}
            onClick={onStartWork}
            disabled={!ctaEnabled}
          />
        )}
        {inProgramma && (
          <ButtonComponent
            label={t("application.cta.cancel")}
            onClick={onCancelWork}
            disabled={!ctaEnabled}
            danger
          />
        )}
        {inPreparazione && (
          <ButtonComponent
            label={t("application.cta.pause")}
            onClick={onPauseWork}
            disabled={!ctaEnabled}
          />
        )}
        {inLavoro && (
          <ButtonComponent
            label={t("application.cta.pause")}
            onClick={onPauseWork}
            disabled={!ctaEnabled}
          />
        )}
        {inLavoro && (
          <ButtonComponent
            label={t("application.cta.stop")}
            onClick={onStopWork}
            disabled={!ctaEnabled}
            danger
          />
        )}
        {inPausa && (
          <ButtonComponent
            label={t("application.cta.restart")}
            onClick={onRestartWork}
            disabled={!ctaEnabled}
          />
        )}
        {inPausa && (
          <ButtonComponent
            label={t("application.cta.cancel")}
            onClick={onEndWork}
            disabled={!ctaEnabled}
          />
        )}
        {inFine && (
          <ButtonComponent
            label={t("application.cta.cancel")}
            onClick={onEndWork}
            disabled={!ctaEnabled}
          />
        )}
        {inRiepilogo && (
          <ButtonComponent
            label={t("application.cta.confirm")}
            onClick={onConfirmConfiguration}
            disabled={!ctaEnabled}
          />
        )}
        {inRiepilogo && (
          <ButtonComponent
            label={t("application.cta.cancel")}
            onClick={onCancelConfiguration}
            disabled={!ctaEnabled}
            danger
          />
        )}
      </ButtonsGroupComponent>

      <ModalComponent
        status={editEndModal}
        onClose={() => setEditEndModal(false)}
      >
        {editEndLoading ? (
          <LoadingComponent semifullscreen />
        ) : (
          <>
            <InputTextComponent
              type="date"
              value={editEndFormValues.date_end || ""}
              label={t("application.labels.date_end")}
              onChange={(v) => onEditEndValueChange("date_end", v)}
            />
            <InputTextComponent
              type="time"
              value={editEndFormValues.time_end || ""}
              label={t("application.labels.time_end")}
              onChange={(v) => onEditEndValueChange("time_end", v)}
            />
            <ButtonComponent
              label={t("application.cta.confirm")}
              onClick={onEditEndRequestConfirm}
              block
            />
          </>
        )}
      </ModalComponent>

      <ModalComponent
        status={editSpeedModal}
        onClose={() => setEditSpeedModal(false)}
      >
        {editSpeedLoading ? (
          <LoadingComponent semifullscreen />
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 25,
              }}
            >
              <InputNumberComponent
                value={editSpeedFormValues.speed || ""}
                label={t("irriapp.labels.speed")}
                onChange={(v) => onEditSpeedValueChange("speed", v)}
                min={inModeMillimetri ? 5 : 4}
                max={inModeMillimetri ? 150 : 850}
              />
            </div>
            <ButtonComponent
              label={t("application.cta.confirm")}
              onClick={onEditSpeedRequestConfirm}
              block
            />
          </>
        )}
      </ModalComponent>

      <SecureComponent {...secureComponentProps} />
    </div>
  );
}

MachineScreen.propTypes = {
  goTo: PropTypes.func,
};

MachineScreen.defaultProps = {
  goTo: () => {},
};

/////////////////////////
/////////////////////////

// Ritorna i dati per la card MM
const getMmDash = (machineParams) => {
  const mmDashArr = [];

  const batteryStatus = getMachineParamsValue(
    machineParams,
    "work_mm_batteria_status"
  );
  mmDashArr.push({
    status: batteryStatus !== "hidden",
    flash: batteryStatus === "flashing",
    icon: <i className="fontello icon-battery">&#xe801;</i>,
    iconValue: getMachineParamsValue(machineParams, "work_mm_batteria_value"),
    iconUnit: getMachineParamsUnit(machineParams, "work_mm_batteria_value"),
  });

  const sparkStatus = getMachineParamsValue(
    machineParams,
    "work_mm_candelette_status"
  );
  mmDashArr.push({
    status: sparkStatus !== "hidden",
    flash: sparkStatus === "flashing",
    icon: <i className="fontello icon-spark">&#xe802;</i>,
  });

  const carbStatus = getMachineParamsValue(
    machineParams,
    "work_mm_carb_anal_status"
  );
  mmDashArr.push({
    status: carbStatus !== "hidden",
    flash: carbStatus === "flashing",
    icon: <i className="fontello icon-fuel">&#xe819;</i>,
    iconValue: getMachineParamsValue(
      machineParams,
      "work_mm_carb_anal_value"
    ),
    iconUnit: getMachineParamsUnit(machineParams, "work_mm_carb_anal_value"),
  });

  const filterStatus = getMachineParamsValue(
    machineParams,
    "work_mm_filtro_status"
  );
  mmDashArr.push({
    status: filterStatus !== "hidden",
    flash: filterStatus === "flashing",
    icon: <i className="fontello icon-filter">&#xf0b0;</i>,
  });

  const oilStatus = getMachineParamsValue(
    machineParams,
    "work_mm_olio_status"
  );
  mmDashArr.push({
    status: oilStatus !== "hidden",
    flash: oilStatus === "flashing",
    icon: <i className="fas fa-oil-can"></i>,
    iconValue: getMachineParamsValue(machineParams, "work_mm_olio_value"),
    iconUnit: getMachineParamsUnit(machineParams, "work_mm_olio_value"),
  });

  const tempAnalStatus = getMachineParamsValue(
    machineParams,
    "work_mm_temperatura_anal_status"
  );
  mmDashArr.push({
    status: tempAnalStatus !== "hidden",
    flash: tempAnalStatus === "flashing",
    icon: <i className="fontello icon-temperatire">&#xe800;</i>,
    iconValue: getMachineParamsValue(
      machineParams,
      "work_mm_temperatura_anal_value"
    ),
    iconUnit: getMachineParamsUnit(
      machineParams,
      "work_mm_temperatura_anal_value"
    ),
  });

  const tempStatus = getMachineParamsValue(
    machineParams,
    "work_mm_temperatura_status"
  );
  mmDashArr.push({
    status: tempStatus !== "hidden",
    flash: tempStatus === "flashing",
    icon: <i className="fontello icon-temperatire">&#xe800;</i>
  });

  return mmDashArr;
};

// Ritorna i dati per la card MTR
const getMtrDash = (machineParams) => {
  const mtrDashArr = [];
  const oilStatus = getMachineParamsValue(
    machineParams,
    "work_mtr_oil_status"
  );
  mtrDashArr.push({
    status: oilStatus !== "hidden",
    flash: oilStatus === "flashing",
    icon: <i className="fas fa-oil-can"></i>,
  });

  const carbStatus = getMachineParamsValue(
    machineParams,
    "work_mtr_carb_anal_status"
  );
  mtrDashArr.push({
    status: carbStatus !== "hidden",
    flash: carbStatus === "flashing",
    icon: <i className="fontello icon-fuel">&#xe819;</i>,
    iconValue: getMachineParamsValue(
      machineParams,
      "work_mtr_carb_anal_value"
    ),
    iconUnit: getMachineParamsUnit(machineParams, "work_mtr_carb_anal_value"),
  });

  const tempStatus = getMachineParamsValue(
    machineParams,
    "work_mtr_temperatura_status"
  );
  mtrDashArr.push({
    status: tempStatus !== "hidden",
    flash: tempStatus === "flashing",
    icon: <i className="fontello icon-temperatire">&#xe800;</i>,
  });

  return mtrDashArr;
};

/**
 * Interfaccia IN ATTESA
 */
const InAttesaInterface = (props) => {
  const { t, machineParams } = props;

  return (
    <>
      <StatComponent
        sizes={{ default: 24, sm: 24 }}
        label={t("irriapp.titles.unrolling_pipe")}
        value={getMachineParamsValue(machineParams, "work_tube_length", "-")}
        unit={getMachineParamsUnit(machineParams, "work_tube_length")}
        converterType={unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura"))}
      />
    </>
  );
};

/**
 * Interfaccia IN PROGRAMMA
 */
const InProgrammaInterface = (props) => {
  const { t, machineParams } = props;
  const datetimeWorkStart = useMemo(
    () => getMachineParamsValueDate(machineParams, "program_datetime_start"),
    [machineParams]
  );
  const datetimeWorkEnd = useMemo(
    () => getMachineParamsValueDate(machineParams, "program_datetime_end"),
    [machineParams]
  );

  const mmDash = mmActive ? getMmDash(machineParams) : [];
  const mmActive = !!getMachineParamsValue(
    machineParams,
    "conf_modulo_mm_presente"
  );

  return (
    <>
      <StatComponent
        sizes={{ default: 24, lg: 6 }}
        label={t("irriapp.titles.unrolling_pipe")}
        value={getMachineParamsValue(machineParams, "work_tube_length", "-")}
        unit={getMachineParamsUnit(machineParams, "work_tube_length")}
        converterType={unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura"))}
      />
      <StatComponent
        sizes={{ default: 12, lg: 6 }}
        label={t("irriapp.titles.work_programmed_to_start")}
        value={objValueOrDefault(datetimeWorkStart, ["time"], " - ")}
        subvalue={objValueOrDefault(datetimeWorkStart, ["date"])}
      />
      <StatComponent
        sizes={{ default: 12, lg: 6 }}
        label={t("irriapp.titles.work_programmed_to_end")}
        value={objValueOrDefault(datetimeWorkEnd, ["time"], " - ")}
        subvalue={objValueOrDefault(datetimeWorkEnd, ["date"])}
      />
      {mmActive && (
        <StatComponent
          sizes={{ default: 24, md: 24 }}
          icon={<i className="fas fa-cogs"></i>}
          label={t("irriapp.titles.motormatic")}
          value={getMachineParamsValue(machineParams, "work_mm_real_bar", "-")}
          unit={getMachineParamsUnit(machineParams, "work_mm_real_bar")}
          subvalue={`${t("application.labels.target")}: ${convertValue(getMachineParamsValue(machineParams, "work_mm_target_bar", "-"), getMachineParamsUnit(machineParams, "work_mm_target_bar"), unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura", "-")))} ${convertUnit(getMachineParamsUnit(machineParams, "work_mm_target_bar"), unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura", "-")))}`}
          value2={getMachineParamsValue(machineParams, "work_mm_rpm", "-")}
          unit2={getMachineParamsUnit(machineParams, "work_mm_rpm")}
          dash={mmDash}
        />
      )}
    </>
  );
};

/**
 * Interfaccia IN LAVORO
 */
const InLavoroInterface = (props) => {
  const { t, machineParams } = props;
  const statoSessione = getMachineParamsValue(
    machineParams,
    "work_session_status"
  );
  const pausaIniziale = getMachineParamsValue(
    machineParams,
    "program_pause_start"
  );
  const pausaFinale = getMachineParamsValue(machineParams, "program_pause_end");

  // Rreparo i dati della pausa iniziale
  const pausaInizio = statoSessione === "srPausaInizio";
  const pausaInizioPercentuale = useMemo(() => {
    const totale = pausaIniziale;
    const rimanente = machineParams.work_initial_break
      ? machineParams.work_initial_break.value
      : totale;
    const completata = totale - rimanente;
    return Math.round((completata * 100) / totale);
  }, [pausaIniziale, machineParams.work_initial_break]);

  // Rreparo i dati della pausa finale
  const pausaFine = statoSessione === "srPausaFine";
  const pausaFinePercentuale = useMemo(() => {
    const totale = pausaFinale;
    const rimanente = machineParams.work_final_break
      ? machineParams.work_final_break.value
      : totale;
    const completata = totale - rimanente;
    return Math.round((completata * 100) / totale);
  }, [pausaFinale, machineParams.work_final_break]);

  const mmActive = !!getMachineParamsValue(
    machineParams,
    "conf_modulo_mm_presente"
  );
  const mtrActive = !!getMachineParamsValue(
    machineParams,
    "conf_mtr_abilitata"
  );
  const mmDash = mmActive ? getMmDash(machineParams) : [];
  const mtrDash = getMtrDash(machineParams);
  const flussometroActive =
    getMachineParamsValue(machineParams, "conf_tipo_flussometro") > 0;

  // Preparo i dati per la velocita
  let speedValue, speedUnit, speedSubvalue
  if (getMachineParamsValue(machineParams, 'work_irrigation_type') == 'mm') {
    speedValue = getMachineParamsValue(
      machineParams,
      "work_height_current_sector_real",
      "-"
    )
    speedUnit = getMachineParamsUnit(
      machineParams,
      "work_height_current_sector_real"
    )

    speedSubvalue = `${t("application.labels.target")}: ${convertValue(getMachineParamsValue(machineParams, "work_height_current_sector_prevision", "-"), getMachineParamsUnit(machineParams, "work_height_current_sector_prevision"), unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura", "-")))} ${convertUnit(getMachineParamsUnit(machineParams, "work_height_current_sector_prevision"), unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura", "-")))}`
  } else {
    speedValue = getMachineParamsValue(
      machineParams,
      "work_speed_current_sector_real",
      "-"
    )
    speedUnit = getMachineParamsUnit(
      machineParams,
      "work_speed_current_sector_real"
    )

    speedSubvalue = `${t("application.labels.target")}: ${convertValue(getMachineParamsValue(machineParams, "work_speed_current_sector_prevision", "-"), getMachineParamsUnit(machineParams, "work_speed_current_sector_prevision"), unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura", "-")))} ${convertUnit(getMachineParamsUnit(machineParams, "work_speed_current_sector_prevision"), unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura", "-")))}`
  }

  // Preparo i dati per la pressione acqua
  let pressureValue, pressureUnit
  if (machineParams?.conf_bar_trasduttore_soglia?.value > 0 && machineParams?.diagnostic_pressione_acqua) {
    pressureValue = machineParams.diagnostic_pressione_acqua.value
    pressureUnit = machineParams.diagnostic_pressione_acqua.unit
  }

  return (
    <>
      <ProgressComponent
        machineParams={machineParams}
        sizes={{ default: 24 }}
        editEnd={props.editEnd}
      />
      {flussometroActive && (
        <StatComponent
          sizes={{ default: 12, md: 12 }}
          label={t("irriapp.titles.current_water")}
          value={getMachineParamsValue(machineParams, "work_water_used", "-")}
          unit={getMachineParamsUnit(machineParams, "work_water_used")}
        />
      )}
      {!pausaInizio && !pausaFine && (
        <StatComponent
          sizes={{
            default: flussometroActive ? 12 : 24,
            md: flussometroActive ? 12 : 24,
          }}
          label={t("irriapp.titles.unrolling_pipe")}
          value={getMachineParamsValue(machineParams, "work_tube_length", "-")}
          unit={getMachineParamsUnit(machineParams, "work_tube_length")}
          converterType={unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura"))}
        />
      )}
      {!pausaInizio && !pausaFine && (
        <StatComponent
          sizes={{ default: 24, md: 24 }}
          icon={<i className="fas fa-tachometer-alt"></i>}
          label={t("irriapp.labels.speed")}
          value={speedValue}
          unit={speedUnit}
          subvalue={speedSubvalue}
          cardProps={{ ctaFunction: props.editSpeed }}
          converterType={unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura"))}
        />
      )}
      {!pausaInizio && !pausaFine && pressureUnit && (
        <StatComponent
          sizes={{ default: 24, md: 24 }}
          icon={<img src="/icon-pressure.png" style={{ width: '1em', height: '1em' }} />}
          label={t("irriapp.labels.water_pressure")}
          value={pressureValue || 0}
          unit={pressureUnit}
        />
      )}
      {pausaInizio && (
        <StatComponent
          sizes={{
            default: flussometroActive ? 12 : 24,
            md: flussometroActive ? 12 : 24,
          }}
          label={t("irriapp.labels.pause_start")}
          value={pausaInizioPercentuale}
          unit={"%"}
          subvalue={`${getMachineParamsValue(
            machineParams,
            "program_pause_start",
            "-"
          )} ${t("application.units.minutes")}`}
        />
      )}
      {pausaFine && (
        <StatComponent
          sizes={{
            default: flussometroActive ? 12 : 24,
            md: flussometroActive ? 12 : 24,
          }}
          label={t("irriapp.labels.pause_end")}
          value={pausaFinePercentuale}
          unit={"%"}
          subvalue={`${getMachineParamsValue(
            machineParams,
            "program_pause_end",
            "-"
          )} ${t("application.units.minutes")}`}
        />
      )}
      {mtrActive && (
        <StatComponent
          sizes={{ default: 24, md: 24 }}
          icon={<i className="fas fa-cogs"></i>}
          label={t("irriapp.titles.mtr")}
          value={getMachineParamsValue(machineParams, "work_mtr_rpm", "-")}
          unit={"rpm"}
          dash={mtrDash}
        />
      )}
      {mmActive && (
        <StatComponent
          sizes={{ default: 24, md: 24 }}
          icon={<i className="fas fa-cogs"></i>}
          label={t("irriapp.titles.motormatic")}
          value={getMachineParamsValue(machineParams, "work_mm_real_bar", "-")}
          unit={getMachineParamsUnit(machineParams, "work_mm_real_bar")}
          subvalue={`${t("application.labels.target")}: ${convertValue(getMachineParamsValue(machineParams, "work_mm_target_bar", "-"), getMachineParamsUnit(machineParams, "work_mm_target_bar"), unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura", "-")))} ${convertUnit(getMachineParamsUnit(machineParams, "work_mm_target_bar"), unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura", "-")))}`}
          value2={getMachineParamsValue(machineParams, "work_mm_rpm", "-")}
          unit2={getMachineParamsUnit(machineParams, "work_mm_rpm")}
          dash={mmDash}
        />
      )}
    </>
  );
};

/**
 * Interfaccia IN PAUSA
 */
const InPausaInterface = (props) => {
  const { t, machineParams } = props;

  return (
    <>
      <ProgressComponent
        machineParams={machineParams}
        sizes={{ default: 24 }}
        paused
      />
      <StatComponent
        sizes={{ default: 12, md: 12 }}
        label={t("irriapp.titles.current_water")}
        value={getMachineParamsValue(machineParams, "work_water_used", "-")}
        unit={getMachineParamsUnit(machineParams, "work_water_used")}
      />
      <StatComponent
        sizes={{ default: 12, md: 12 }}
        label={t("irriapp.titles.unrolling_pipe")}
        value={getMachineParamsValue(machineParams, "work_tube_length", "-")}
        unit={getMachineParamsUnit(machineParams, "work_tube_length")}
        converterType={unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura"))}
      />
    </>
  );
};

/**
 * Interfaccia IN FINE
 */
const InFineInterface = (props) => {
  const { t, machineParams } = props;

  return (
    <>
      <ProgressComponent
        machineParams={machineParams}
        sizes={{ default: 24 }}
        completed
      />
    </>
  );
};

/**
 * Interfaccia IN PREPARAZIONE
 */
const InPreparazioneInterface = (props) => {
  const { t, machineParams } = props;
  const mmActive = !!getMachineParamsValue(
    machineParams,
    "conf_modulo_mm_presente"
  );

  const mmDash = mmActive ? getMmDash(machineParams) : [];

  return (
    <>
      <IconComponent
        sizes={{ default: 24, sm: 24 }}
        label={t("irriapp.titles.preparation")}
        icon={<i className="fas fa-cog"></i>}
      />
      {mmActive && (
        <StatComponent
          sizes={{ default: 24, md: 24 }}
          icon={<i className="fas fa-cogs"></i>}
          label={t("irriapp.titles.motormatic")}
          value={getMachineParamsValue(machineParams, "work_mm_real_bar", "-")}
          unit={getMachineParamsUnit(machineParams, "work_mm_real_bar")}
          subvalue={`${t("application.labels.target")}: ${convertValue(getMachineParamsValue(machineParams, "work_mm_target_bar", "-"), getMachineParamsUnit(machineParams, "work_mm_target_bar"), unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura", "-")))} ${convertUnit(getMachineParamsUnit(machineParams, "work_mm_target_bar"), unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura", "-")))}`}
          value2={getMachineParamsValue(machineParams, "work_mm_rpm", "-")}
          unit2={getMachineParamsUnit(machineParams, "work_mm_rpm")}
          dash={mmDash}
        />
      )}
    </>
  );
};

/**
 * Interfaccia IN MOVIMENTO
 */
const InMovimentoInterface = (props) => {
  const { t, machineParams } = props;

  return (
    <>
      <IconComponent
        sizes={{ default: 24, sm: 24 }}
        label={t("irriapp.titles.in_movement")}
        icon={<i className="fas fa-exclamation-triangle"></i>}
        danger
      />
    </>
  );
};

/**
 * Interfaccia IN USO FINISCO
 */
const InUsoFisicoInterface = (props) => {
  const { t, mqttActions } = props;

  return (
    <>
      <MessageComponent
        title={t("application.messages.local_usage_title")}
        message={t("application.messages.local_usage_message")}
        extraContent={() => (
          <ButtonComponent
            label={t("application.cta.update_status")}
            onClick={() =>
              mqttActions.sendMessage("RD_DATA", { dataGroup: "work" })
            }
          />
        )}
      />
    </>
  );
};

/**
 * Interfaccia IN SETUP
 */
const InSetupInterface = (props) => {
  const { t, mqttActions } = props;

  return (
    <>
      <MessageComponent
        title={t("application.messages.local_usage_title")}
        message={t("application.messages.local_usage_message")}
        extraContent={() => (
          <ButtonComponent
            label={t("application.cta.update_status")}
            onClick={() =>
              mqttActions.sendMessage("RMT_WRT", { active: false })
            }
          />
        )}
      />
    </>
  );
};

/**
 * Interfaccia IN RIEPILOGO
 */
const InRiepilogoInterface = (props) => {
  const { t, machineParams } = props;

  const configuration = useMemo(() => {
    const c = {};
    Object.keys(machineParams).forEach((key) => {
      if (!key.startsWith("program_")) return;
      c[key.replace("program_", "")] = getMachineParamsValue(
        machineParams,
        key
      );
    });
    return c;
  }, [machineParams]);

  return (
    <>
      <CardComponent
        title={t("application.titles.configuration_resume")}
        className={`c-card--hasnext ${gridSizesClassName({ default: 24 })}`}
      >
        <ConfResumeComponent configuration={configuration} />
      </CardComponent>
    </>
  );
};
