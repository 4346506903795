import MachineComponent from '../screens/MachineScreen'
import ConfigurationScreen from '../screens/ConfigurationScreen'
import ConfigurationConfirmScreen from '../screens/ConfigurationConfirmScreen'
import EditorScreen from '../screens/EditorScreen'
import useNav from '../../hooks/navHook'

export default function DashboardNav(props) {
  const [currentScreenName, currentScreenProps, getCurrentScreen] = useNav({
    machine: MachineComponent,
    configuration: ConfigurationScreen,
    configurationConfirm: ConfigurationConfirmScreen,
    editor: EditorScreen
  }, 'machine')

  return getCurrentScreen(props)
}
