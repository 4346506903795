import React, { useState, useEffect, createRef } from "react";
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  Line,
  Area,
  ResponsiveContainer,
} from "recharts";
import PropTypes from "prop-types";
import { isMobile } from "../utils/device";

export default function ChartLineComponent(props) {
  let {
    data,
    keys,
    colors,
    units,
    labels,
    barKeys,
    barColors,
    barLabels,
    areaKeys,
    areaColors,
    areaLabels,
    height,
  } = props;

  // filtro le chiavi sulla base dei dati effettivamente disponibili in modo da non mostrare cose che non esistono
  const rm = () => {
    let cont = false
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      const exists = data.filter((d) => !!d[key]).length > 0
      if (!exists) {
        keys.splice(i, 1)
        colors.splice(i, 1)
        units.splice(i, 1)
        labels.splice(i, 1)
        cont = true
        break
      }
    }

    if (cont) rm()
  }
  rm()
  const barrm = () => {
    let cont = false
    for (let i = 0; i < barKeys.length; i++) {
      const key = barKeys[i]
      const exists = data.filter((d) => !!d[key]).length > 0
      if (!exists) {
        barKeys.splice(i, 1)
        barColors.splice(i, 1)
        barLabels.splice(i, 1)
        cont = true
        break
      }
    }

    if (cont) barrm()
  }
  barrm()
  const arearm = () => {
    let cont = false
    for (let i = 0; i < areaKeys.lengt; i++) {
      const key = areaKeys[i]
      const exists = data.filter((d) => !!d[key]).length > 0
      if(!exists){
        areaKeys.splice(i, 1)
        areaColors.splice(i, 1)
        areaLabels.splice(i, 1)
        cont = true
        break
      }
    }

    if (cont) arearm()
  }
  arearm()

  const tooltipFormatter = (value, name, props) => {
    if (barKeys.includes(props.dataKey)) {
      return [props.payload[`${props.dataKey}_string`], name]
    } else if (areaKeys.includes(props.dataKey)){
      if(value){
        return [props.payload[`${props.dataKey}_string`], name]
      } else {
        return []
      }
    } else {
      return [value, name]
    }
  };

  const [hidden, setHidden] = useState([]);

  const legendClick = (key) => {
    if (!isMobile()) {
      if (hidden.includes(key)) {
        setHidden(hidden.filter((obj) => obj !== key));
      } else {
        setHidden([...hidden, key]);
      }
    }
  };

  const renderCusomizedLegend = ({ payload }) => {
    return (
      <ul className="r-chart-legend">
        {payload.map((key, index) => {
          const hide = hidden.includes(key.dataKey);
          const style = {
            border: hide ? "2px solid transparent" : "2px solid " + key.color,
            backgroundColor: hide ? "#D8D8D8" : "transparent",
            color: hide ? "#000" : key.color,
          };
          return (
            <li
              key={index}
              className="r-chart-legend__item"
              onClick={() => legendClick(key.dataKey)}
              style={style}
            >
              {key.value}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={height}>
      <ComposedChart data={data}>
        {keys.map((key, index) => {
          const hide = hidden.includes(key);

          return (
            <Line
              key={`Line-${key}`}
              yAxisId={`YAxis-${key}`}
              dataKey={key}
              type="linear"
              stroke={colors[index] || "#000000"}
              name={labels[index]}
              unit={units[index]}
              strokeWidth={hide ? 0 : 3}
              dot={{ strokeWidth: hide ? 0 : 2 }}
              connectNulls
            />
          );
        })}
        {barKeys.map((key, index) => {
          const hide = hidden.includes(key);

          return (
            <Bar
              key={`Bar-${key}`}
              yAxisId={`YAxis-${key}`}
              dataKey={key}
              barSize={hide ? 0 : 10}
              fill={hide ? 'transparent' : barColors[index]}
              fillOpacity={0.5}
              name={barLabels[index]}
            />
          );
        })}
        {areaKeys.map((key, index) => {
          const hide = hidden.includes(key)

          return(
            <Area 
              key={`Area-${key}`}
              yAxisId={`YAxis-${key}`}
              dataKey={key}
              stroke={ hide ? 'none' : areaColors[index] }
              fill={ hide ? 'transparent' : areaColors[index] }
              fillOpacity={0.5}
              name={areaLabels[index]}
            />
          )
        })}

        {keys.map((key, index) => {
          const hide = hidden.includes(key);

          return (
            <YAxis
              key={`YAxis-${key}`}
              yAxisId={`YAxis-${key}`}
              dataKey={key}
              stroke={colors[index] || "#000000"}
              domain={units[index] == "%" ? [0, 100] : [0, "dataMax"]}
              hide={isMobile() || hide}
            />
          );
        })}
        {barKeys.map((key, index) => {

          return(
            <YAxis
              key={`YAxis-${key}`}
              yAxisId={`YAxis-${key}`}
              dataKey={key}
              stroke={barColors[index] || "#000000"}
              orientation="right"
              hide
            />
        )})}
        {areaKeys.map((key, index) => {

          return(
            <YAxis
              key={`YAxis-${key}`}
              yAxisId={`YAxis-${key}`}
              dataKey={key}
              stroke={barColors[index] || "#000000"}
              orientation="right"
              domain={[0, 1]}
              // padding={{top: 100}}
              hide
            />
          )
        })}

        <XAxis dataKey="x" />
        <Tooltip formatter={tooltipFormatter} />
        <Legend content={renderCusomizedLegend} />

        <CartesianGrid stroke="#eee" />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

ChartLineComponent.propTypes = {
  keys: PropTypes.array,
  data: PropTypes.array,
  colors: PropTypes.array,
  units: PropTypes.array,
  labels: PropTypes.array,
  barKeys: PropTypes.array,
  barColors: PropTypes.array,
  barLabels: PropTypes.array,
  areaKeys: PropTypes.array,
  areaColors: PropTypes.array,
  areaLabels: PropTypes.array,
  height: PropTypes.number,
};

ChartLineComponent.defaultProps = {
  keys: [],
  data: [],
  colors: [],
  units: [],
  labels: [],
  barKeys: [],
  barColors: [],
  barLabels: [],
  areaKeys: [],
  areaColors: [],
  areaLabels: [],
  height: 300,
};
