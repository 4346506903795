import React, { useState, useEffect, useContext } from "react";
import mapboxgl from "!mapbox-gl";
import { LocalesContext } from "../contexts/LocalesContext";

mapboxgl.accessToken =
  "pk.eyJ1IjoibWF0ZXJtYWNjbWlwbHVzIiwiYSI6ImNrazdlNWlnMzA4anEycG80ZnBhNXNvamcifQ.ZJTUcPAifQRHmEpp2YgYrA";

export default function MapComponent(props) {
  const { t } = useContext(LocalesContext);
  const { coords } = props;

  useEffect(() => {
    if (coords && coords.lat && coords.lng) {
      const map = new mapboxgl.Map({
        container: "MapComponentMap",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [coords.lng, coords.lat],
        zoom: 10,
        preserveDrawingBuffer: true,
      });

      const marker = new mapboxgl.Marker()
        .setLngLat([coords.lng, coords.lat])
        .addTo(map);
    }
  }, []);

  return (
    <div className="r-map">
      {coords ? (
        <div className="r-map__map" id="MapComponentMap" />
      ) : (
        <div className="r-map__empty">{t("PosizioneNonDisponibile")}</div>
      )}
    </div>
  );
}
