import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { LocalesContext } from '../contexts/LocalesContext'

export default function DiagnosticStatComponent(props) {
  const { t } = useContext(LocalesContext)
  let className = `r-diagnostic-stat ${props.className}`

  return (
    <table className={className}>
      <tbody>
        {Object.keys(props.data).map((key) => {
          return (
            <tr key={key}>
              <td className="key">{t(`irriapp.diagnostic.diagnostic_stat_${key}`) || key}</td>
              <td className={`value ${props.data[key] ? 'is-valid' : 'is-error'}`}>{props.data[key] ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

DiagnosticStatComponent.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object
}

DiagnosticStatComponent.defaultProps = {
  className: '',
  data: {}
}
