import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { LocalesContext } from "../../contexts/LocalesContext";
import HeadComponent from "../../components/HeadComponent";
import CardComponent from "../../components/CardComponent";
import ListItemComponent from "../../components/ListItemComponent";
import InputSelectComponent from "../../components/InputSelectComponent";
import InputNumberComponent from "../../components/InputNumberComponent";
import InputSwitchComponent from "../../components/InputSwitchComponent";
import FixedBarComponent from "../../components/FixedBarComponent";
import ButtonsGroupComponent from "../../components/ButtonsGroupComponent";
import ButtonComponent from "../../components/ButtonComponent";
import ModalComponent from "../../components/ModalComponent";
import RowsPresetComponent from "../../components/RowsPresetComponent";
import InputLabelButtonComponent from "../../components/InputLabelButtonComponent";
import { MachineContext } from "../../contexts/MachineContext";
import { gridSizesClassName } from "../../utils/gridSizes";
import { postRequest } from "../../utils/requests";
import { objValueOrDefault } from "../../utils/data";
import {
  presetSensibilitaGraficoOptions,
  presetGraficoDiDefaultOptions,
} from "../../utils/miplus";

export default function EditorScreen(props) {
  const isPresetEdit = props.type == "preset_edit";

  const { t } = useContext(LocalesContext);
  const [formValues, setFormValues] = useState({
    setupFile: objValueOrDefault(props, ["params", "setupFile"], []),
    interfila: objValueOrDefault(props, ["params", "interfila"], 45),
    distanzaSemina: objValueOrDefault(props, ["params", "distanzaSemina"], 20),
    investimento: objValueOrDefault(props, ["params", "investimento"], 111.1),
    numeroForiDisco: objValueOrDefault(
      props,
      ["params", "numeroForiDisco"],
      24
    ),
    diametroForiDisco: objValueOrDefault(
      props,
      ["params", "diametroForiDisco"],
      5
    ),
    graficoDefault: objValueOrDefault(
      props,
      ["params", "graficoDefault"],
      "population"
    ),
    sensibilitaGrafico: objValueOrDefault(
      props,
      ["params", "sensibilitaGrafico"],
      3
    ),
    sogliaAllarmeQualita: objValueOrDefault(
      props,
      ["params", "sogliaAllarmeQualita"],
      20
    ),
    sogliaAllarmePopolazione: objValueOrDefault(
      props,
      ["params", "sogliaAllarmePopolazione"],
      20
    ),
    livelloAllarmeGrafico: objValueOrDefault(
      props,
      ["params", "livelloAllarmeGrafico"],
      80
    ),
    livelloDepressioneMin: objValueOrDefault(
      props,
      ["params", "livelloDepressioneMin"],
      35
    ),
    livelloDepressioneMax: objValueOrDefault(
      props,
      ["params", "livelloDepressioneMax"],
      50
    ),
    ripristinoAutoFile: objValueOrDefault(
      props,
      ["params", "ripristinoAutoFile"],
      false
    ),
  });
  const [editEndModal, setEditEndModal] = useState(false);
  const { machineParams } = useContext(MachineContext);

  let title = isPresetEdit ? t("application.titles.presets_edit") : t("application.titles.presets_new");

  // FUNCTIONS
  /////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Funzione eseguita alla conferma di completamento dell'editor
   */
  const onConfirm = () => {
    props.goTo("presetsConfirm", {
      type: props.type,
      preset: props.preset
        ? Object.assign(props.preset, { params: formValues })
        : { params: formValues },
    });
  };

  /**
   * Funzione eseguita quando l'utente torna indietro.
   */
  const onGoBack = () => {
    props.goBack();
  };

  /**
   * Funzione eseguita per aggiornare un valore del form
   * @param {*} name
   * @param {*} value
   */
  const updateFormValue = (name, value) => {
    const newFormValues = Object.assign({}, formValues);
    newFormValues[name] = value;

    if (name === "interfila") {
      newFormValues.investimento =
        Math.round((10 * 100000) / (value * newFormValues.distanzaSemina)) / 10;
    }
    if (name === "distanzaSemina") {
      newFormValues.investimento =
        Math.round((10 * 100000) / (value * newFormValues.interfila)) / 10;
    }
    if (name === "investimento") {
      newFormValues.distanzaSemina =
        Math.round((10 * 100000) / (value * newFormValues.interfila)) / 10;
    }
    if (name === "livelloDepressioneMin") {
      newFormValues.livelloDepressioneMax =
        newFormValues.livelloDepressioneMax || 50;
      if (newFormValues.livelloDepressioneMax < value) {
        newFormValues.livelloDepressioneMax = value;
      }
    }

    if (name == "sensibilitaGrafico") {
      if (value == 2) {
        newFormValues.sogliaAllarmeQualita = 60;
        newFormValues.sogliaAllarmePopolazione = 30;
        newFormValues.livelloAllarmeGrafico = 80;
      }
      if (value == 3) {
        newFormValues.sogliaAllarmeQualita = 20;
        newFormValues.sogliaAllarmePopolazione = 20;
        newFormValues.livelloAllarmeGrafico = 80;
      }
      if (value == 4) {
        newFormValues.sogliaAllarmeQualita = 10;
        newFormValues.sogliaAllarmePopolazione = 10;
        newFormValues.livelloAllarmeGrafico = 80;
      }
    }

    setFormValues(newFormValues);
  };

  /**
   * Funzione eseguita alla richiesta di rimozione del preset
   */
  const onRemove = () => {
    postRequest(
      props.apis.presets_manage_actions_action,
      { actions: [{ uuid: props.preset.uuid, type: "remove" }] },
      (response) => {
        if (response.result) {
          props.goTo("presets");
        }
      }
    );
  };

  // RENDER
  /////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="c-grid__container-spaced">
      <HeadComponent title={title} onBack={onGoBack} />

      <CardComponent
        className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
        title={t("GestioneFile")}
      >
        <ListItemComponent title={t("NumeroFile")}>
          <InputLabelButtonComponent
            label={(
              formValues.setupFile.length || machineParams.setup?.numeroFile || 24
            ).toString()}
            btnCta={t("application.cta.edit")}
            btnOnClick={() => setEditEndModal(true)}
          />
        </ListItemComponent>
      </CardComponent>

      <CardComponent
        className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
        title={t("Configurazione")}
      >
        <ListItemComponent title={t("Interfila")}>
          <InputNumberComponent
            onChange={(v) => updateFormValue("interfila", v)}
            value={formValues.interfila}
            unit="cm"
            min={25}
            max={200}
          />
        </ListItemComponent>
        <ListItemComponent title={t("DistanzaSemina")}>
          <InputNumberComponent
            onChange={(v) => updateFormValue("distanzaSemina", v)}
            value={formValues.distanzaSemina}
            unit="cm"
            min={1}
            max={200}
            decimal={1}
          />
        </ListItemComponent>
        <ListItemComponent title={t("Investimento")}>
          <InputNumberComponent
            onChange={(v) => updateFormValue("investimento", v)}
            value={formValues.investimento}
            unit="kseed/ha"
            min={1}
            max={5000}
            decimal={1}
          />
        </ListItemComponent>
        <ListItemComponent title={t("NumeroForiDisco")}>
          <InputNumberComponent
            onChange={(v) => updateFormValue("numeroForiDisco", v)}
            value={formValues.numeroForiDisco}
            unit="n°"
            min={1}
            max={250}
          />
        </ListItemComponent>
        <ListItemComponent title={t("DiametroForiDisco")}>
          <InputNumberComponent
            onChange={(v) => updateFormValue("diametroForiDisco", v)}
            value={formValues.diametroForiDisco}
            unit="mm"
            min={1}
            max={10}
            decimal={1}
          />
        </ListItemComponent>
        <ListItemComponent title={t("GraficoDiDefault")}>
          <InputSelectComponent
            options={presetGraficoDiDefaultOptions(t)}
            onChange={(v) => updateFormValue("GraficoDiDefault", v)}
            value={formValues.GraficoDiDefault}
          />
        </ListItemComponent>
        <ListItemComponent title={t("SensibilitaGrafico")}>
          <InputSelectComponent
            options={presetSensibilitaGraficoOptions(t)}
            onChange={(v) => updateFormValue("sensibilitaGrafico", v)}
            value={formValues.sensibilitaGrafico}
          />
        </ListItemComponent>
        <ListItemComponent title={t("SogliaAllarmeQualita")}>
          <InputNumberComponent
            onChange={(v) => updateFormValue("sogliaAllarmeQualita", v)}
            value={formValues.sogliaAllarmeQualita}
            unit="%"
            min={1}
            max={100}
            disabled={formValues.sensibilitaGrafico != 1}
          />
        </ListItemComponent>
        <ListItemComponent title={t("SogliaAllarmePopolazione")}>
          <InputNumberComponent
            onChange={(v) => updateFormValue("sogliaAllarmePopolazione", v)}
            value={formValues.sogliaAllarmePopolazione}
            unit="%"
            min={1}
            max={100}
            disabled={formValues.sensibilitaGrafico != 1}
          />
        </ListItemComponent>
        <ListItemComponent title={t("LivelloAllarmeGrafico")}>
          <InputNumberComponent
            onChange={(v) => updateFormValue("livelloAllarmeGrafico", v)}
            value={formValues.livelloAllarmeGrafico}
            unit="%"
            min={1}
            max={100}
            disabled={formValues.sensibilitaGrafico != 1}
          />
        </ListItemComponent>
        <ListItemComponent title={t("LivelloDepressioneMin")}>
          <InputNumberComponent
            onChange={(v) => updateFormValue("livelloDepressioneMin", v)}
            value={formValues.livelloDepressioneMin}
            unit="mbar"
            min={10}
            max={70}
          />
        </ListItemComponent>
        <ListItemComponent title={t("LivelloDepressioneMax")}>
          <InputNumberComponent
            onChange={(v) => updateFormValue("livelloDepressioneMax", v)}
            value={formValues.livelloDepressioneMax}
            unit="mbar"
            min={formValues.livelloDepressioneMin || 10}
            max={70}
          />
        </ListItemComponent>
        <ListItemComponent title={t("RipristinoAutoFile")}>
          <InputSwitchComponent
            onChange={(v) => updateFormValue("ripristinoAutoFile", v)}
            value={formValues.ripristinoAutoFile}
            val1={false}
            val2={true}
            lab1={t("application.labels.not_active")}
            lab2={t("application.labels.active")}
          />
        </ListItemComponent>
      </CardComponent>

      <ButtonsGroupComponent sizes={{ default: 24, sm: 24 }}>
        <ButtonComponent
          label={t("application.cta.continue")}
          disabled={false}
          onClick={onConfirm}
        />
        <ButtonComponent
          label={t("application.cta.cancel")}
          confirm={t("application.messages.confirm_cancel")}
          onClick={() => props.goBack()}
          warning
        />
        {isPresetEdit && (
          <ButtonComponent
            label={t("application.cta.remove")}
            confirm={t("application.messages.confirm_remove")}
            onClick={onRemove}
            danger
          />
        )}
      </ButtonsGroupComponent>

      <ModalComponent
        status={editEndModal}
        onClose={() => setEditEndModal(false)}
        large={true}
      >
        <RowsPresetComponent
          rows={
            machineParams.setup && machineParams.setup.numeroFile
              ? machineParams.setup.numeroFile
              : 24
          }
          rowsActive={formValues.setupFile}
          onRowsChange={(newRows) => updateFormValue("setupFile", newRows)}
        />
      </ModalComponent>
    </div>
  );
}

EditorScreen.propTypes = {
  goBack: PropTypes.func,
};

EditorScreen.defaultProps = {
  goBack: () => {},
};
