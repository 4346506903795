import React from 'react'
import PropTypes from 'prop-types'
import { gridSizesProptypes, gridSizesClassName } from '../utils/gridSizes'

export default function SectionContentBlockComponent(props) {
  let className = `c-section__content-block ${props.className}`
  if (props.sizes) className = gridSizesClassName(props.sizes, className)

  return (
    <div className={className}>
      {props.children}
    </div>
  )
}

SectionContentBlockComponent.propTypes = {
  className: PropTypes.string,
  sizes: gridSizesProptypes()
}

SectionContentBlockComponent.defaultProps = {
  className: ''
}
