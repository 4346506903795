import DiagnosticScreen from '../screens/DiagnosticScreen'
import useNav from '../../hooks/navHook'

export default function DiagnosticNav(props) {
  const [currentScreenName, currentScreenProps, getCurrentScreen] = useNav({
    diagnostic: DiagnosticScreen
  }, 'diagnostic')

  return getCurrentScreen(props)
}
