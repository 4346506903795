import React from 'react'
import PropTypes from 'prop-types'

export default function LoadingComponent(props) {
  let className = `r-loading`
  if (props.fullscreen) className = `${className} r-loading--fullscreen`
  if (props.semifullscreen) className = `${className} r-loading--semi-fullscreen`

  return (
    <div className={className}>
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  )
}

LoadingComponent.propTypes = {
  fullscreen: PropTypes.bool,
  semifullscreen: PropTypes.bool
}

LoadingComponent.defaultProps = {
  fullscreen: false,
  semifullscreen: false
}
