import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { LocalesContext } from '../../contexts/LocalesContext'
import { MqttContext } from '../../contexts/MqttContext'
import HeadComponent from '../../components/HeadComponent'
import CardComponent from '../../components/CardComponent'
import ListItemComponent from '../../components/ListItemComponent'
import InputSwitchComponent from '../../components/InputSwitchComponent'
import InputNumberComponent from '../../components/InputNumberComponent'
import FixedBarComponent from '../../components/FixedBarComponent'
import InputSectorsComponent from '../components/InputSectorsComponent'
import ButtonsGroupComponent from '../../components/ButtonsGroupComponent'
import ModalComponent from "../../components/ModalComponent";
import InputTextComponent from "../../components/InputTextComponent";
import ButtonComponent from '../../components/ButtonComponent'
import { flashDanger } from '../../utils/flash'
import { gridSizesClassName } from '../../utils/gridSizes'
import { postRequest } from '../../utils/requests'
import { uuidv4, objValueOrDefault, secondsToPrettyTime } from '../../utils/data'
import { configurationTimePrevision, getMachineParamsValue, unitaMisuraIntToString } from '../../utils/irriapp'
import { convertUnit } from '../../utils/converter'

export default function EditorScreen(props) {
  const isPreset = props.type == 'preset_new' || props.type == 'preset_edit'
  const isPresetEdit = isPreset && props.type == 'preset_edit'
  const isConfiguration = props.type == 'configuration_new'

  const [presetModal, setPresetModal] = useState(false);
  const [presetName, setPresetName] = useState(null);

  const { t } = useContext(LocalesContext)
  const { machineParams } = useContext(MqttContext)
  const machineParamsWorkMode = getMachineParamsValue(machineParams, 'conf_criterio_regolazione')
  const machineParamsMMActive = getMachineParamsValue(machineParams, 'conf_modulo_mm_presente')
  const machineParamsTubeLength = getMachineParamsValue(machineParams, 'work_tube_length')
  const [sectorsValid, setSectorsValid] = useState(false)
  const [timeValid, setTimeValid] = useState(false)
  const [formValues, setFormValues] = useState({
    work_mod: objValueOrDefault(props, ['params', 'work_mod'], machineParamsWorkMode == 1 ? 'mm' : 'm/h'),
    s1speed: objValueOrDefault(props, ['params', 's1speed'], 4),
    s1height: objValueOrDefault(props, ['params', 's1height'], 5),
    s1length: objValueOrDefault(props, ['params', 'tube_length'], machineParamsTubeLength || 0),
    s2speed: objValueOrDefault(props, ['params', 's2speed']),
    s2height: objValueOrDefault(props, ['params', 's2height']),
    s2length: objValueOrDefault(props, ['params', 's2length']),
    s3speed: objValueOrDefault(props, ['params', 's3speed']),
    s3height: objValueOrDefault(props, ['params', 's3height']),
    s3length: objValueOrDefault(props, ['params', 's3length']),
    s4speed: objValueOrDefault(props, ['params', 's4speed']),
    s4height: objValueOrDefault(props, ['params', 's4height']),
    s4length: objValueOrDefault(props, ['params', 's4length']),
    pause_start: objValueOrDefault(props, ['params', 'pause_start'], 0),
    pause_end: objValueOrDefault(props, ['params', 'pause_end'], 0),
    motormaticc_pressure: objValueOrDefault(props, ['params', 'motormaticc_pressure'], 3),
    motormaticc_active: machineParamsMMActive,
    mouth_pressure: objValueOrDefault(props, ['params', 'mouth_pressure'], 1),
    mouth_diameter: objValueOrDefault(props, ['params', 'mouth_diameter'], 6),
    bandwidth: objValueOrDefault(props, ['params', 'bandwidth'], 5),
    time_prevision: objValueOrDefault(props, ['params', 'time_prevision'], null)
  })

  let title = ''
  if (isPreset) {
    title = isPresetEdit ? t('application.titles.presets_edit') : t('application.titles.presets_new')
  } else if (isConfiguration) {
    title = t('application.titles.configuration_new')
  }

  // EFFECTS
  /////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    updateFormValues({})
  }, [])

  // FUNCTIONS
  /////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Funzione eseguita alla conferma di completamento dell'editor.
   */
  const onConfirm = () => {
    if (!formValues.s1speed || !formValues.s1length) {
      flashDanger('Compile all fields please')
      return
    }

    onEnd()
  }

  /**
   * Funzione eseguita alla conferma con salvataggio.
   */
  const onConfirmAndSave = () => {
    if (!formValues.s1speed || !formValues.s1length) {
      flashDanger('Compile all fields please')
      return
    }

    setPresetModal(true)
  }

  /**
   * Funzione eseguita al salvataggio del preset.
   */
  const onSavePreset = () => {
    setPresetModal(false);

    postRequest(
      props.apis.presets_create_action,
      {
        apis_auth_token: props.apis.auth_token,
        uuid: uuidv4(),
        name: presetName,
        machine_uuid: props.machine_uuid,
        timestamp_creation: new Date(),
        params: Object.assign({}, props.params, formValues),
      },
      (response) => {
        onEnd();
      }
    );
  };

  const onEnd = () => {
    if (isPreset) {
      props.goTo('presetsConfirm', { type: props.type, preset: props.preset ? Object.assign(props.preset, { params: formValues }) : { params: formValues } })
    } else if (isConfiguration) {
      props.goTo('configurationConfirm', { params: Object.assign({}, props.params, formValues) })
    }
  }

  /**
   * Funzione eseguita quando l'utente torna indietro.
   */
  const onGoBack = () => {
    if (isConfiguration) {
      props.goBack({ params: props.params })
    } else {
      props.goBack()
    }
  }

  /**
   * Funzione eseguita per aggiornare un valore del form
   * @param {*} name 
   * @param {*} value 
   */
  const updateFormValue = (name, value) => {
    const newFormValues = Object.assign({}, formValues)
    newFormValues[name] = value
    const timePrevision = configurationTimePrevision(newFormValues)
    setTimeValid(!!timePrevision)
    newFormValues.time_prevision = timePrevision
    setFormValues(newFormValues)
  }

  /**
   * Funzione eseguita per aggiornare un valore del form
   * @param {*} values
   */
  const updateFormValues = (values) => {
    const newFormValues = Object.assign({}, formValues, values)
    const timePrevision = configurationTimePrevision(newFormValues)
    setTimeValid(!!timePrevision)
    newFormValues.time_prevision = timePrevision
    setFormValues(newFormValues)
  }

  /**
   * Funzione eseguita alla richiesta di rimozione del preset
   */
  const onRemove = () => {
    postRequest(props.apis.presets_manage_actions_action, { apis_auth_token: props.apis.auth_token, actions: [{ uuid: props.preset.uuid, type: 'remove' }] }, (response) => {
      if (response.result) {
        props.goTo('presets')
      }
    })
  }

  // RENDER
  /////////////////////////////////////////////////////////////////////////////////////////////

  const hasMm = !!getMachineParamsValue(machineParams, 'conf_modulo_mm_presente')
  const hasWorkMode = machineParamsWorkMode == 2 // mod libero

  return (
    <div className="c-grid__container-spaced">
      <HeadComponent title={title} onBack={onGoBack} />

      <FixedBarComponent
        active={!!formValues.time_prevision}
        renderContent={() => {
          if (!formValues.time_prevision) return null
          return (
            <div className="shame-irriapp-configuration-time-estimated">
              <h4>{t('irriapp.titles.estimated_time').toUpperCase()}:</h4>
              <h1>{secondsToPrettyTime(formValues.time_prevision)}</h1>
            </div>
          )
        }}
      >

        {(hasWorkMode || formValues.work_mod == 'mm') && (
          <CardComponent
            className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
            title={t('irriapp.titles.work_mode')}
          >
            {hasWorkMode && (
              <ListItemComponent title={t('irriapp.labels.work_mode')}>
                <InputSwitchComponent
                  value={formValues.work_mod}
                  onChange={(v) => updateFormValue('work_mod', v)}
                  val1={'m/h'}
                  val2={'mm'}
                  lab1={convertUnit('m/h', unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura")))}
                  lab2={convertUnit('mm', unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura")))}
                  lablarge
                />
              </ListItemComponent>
            )}

            {formValues.work_mod == 'mm' && (
              <>
                <ListItemComponent title={t('irriapp.labels.pressione_boccaglio')}>
                  <InputNumberComponent onChange={(v) => updateFormValue('mouth_pressure', v)} value={formValues.mouth_pressure} unit='bar' min={1} max={10} />
                </ListItemComponent>

                <ListItemComponent title={t('irriapp.labels.diametro_boccaglio')}>
                  <InputNumberComponent
                    onChange={(v) => updateFormValue('mouth_diameter', v)}
                    value={formValues.mouth_diameter}
                    unit='mm'
                    min={6}
                    max={50}
                    converterType={unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura"))}
                  />
                </ListItemComponent>

                <ListItemComponent title={t('irriapp.labels.larghezza_banda')}>
                  <InputNumberComponent
                    onChange={(v) => updateFormValue('bandwidth', v)}
                    value={formValues.bandwidth}
                    unit='m'
                    min={5}
                    max={200}
                    converterType={unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura"))}
                  />
                </ListItemComponent>
              </>
            )}
          </CardComponent>
        )}

        <CardComponent
          className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
          title={t('irriapp.titles.sectors')}
        >
          <InputSectorsComponent
            onChange={(v, n) => updateFormValue(n, v)}
            onChangeValues={(v) => updateFormValues(v)}
            onChangeValidation={(v) => setSectorsValid(v)}
            s1speed={formValues.s1speed}
            s1height={formValues.s1height}
            s1length={formValues.s1length}
            s2speed={formValues.s2speed}
            s2height={formValues.s2height}
            s2length={formValues.s2length}
            s3speed={formValues.s3speed}
            s3height={formValues.s3height}
            s3length={formValues.s3length}
            s4speed={formValues.s4speed}
            s4height={formValues.s4height}
            s4length={formValues.s4length}
            mod={formValues.work_mod}
            target={objValueOrDefault(props, ['params', 'tube_length'])}
            converterType={unitaMisuraIntToString(getMachineParamsValue(machineParams, "conf_unita_misura"))}
          />
        </CardComponent>

        <CardComponent
          className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
          title={t('irriapp.titles.stops')}
        >
          <ListItemComponent title={t('irriapp.labels.pause_start')}>
            <InputNumberComponent onChange={(v) => updateFormValue('pause_start', v)} value={formValues.pause_start} unit={t('application.units.minutes')} min={0} max={120} />
          </ListItemComponent>

          <ListItemComponent title={t('irriapp.labels.pause_end')}>
            <InputNumberComponent onChange={(v) => updateFormValue('pause_end', v)} value={formValues.pause_end} unit={t('application.units.minutes')} min={0} max={120} />
          </ListItemComponent>
        </CardComponent>
        
        {hasMm && (
          <CardComponent
            className={`${gridSizesClassName({ default: 24 })} c-card--hasnext`}
            title={t('irriapp.titles.motormatic')}
          >
            <ListItemComponent title={t('irriapp.labels.pressure')}>
              <InputNumberComponent onChange={(v) => updateFormValue('motormaticc_pressure', v)} value={formValues.motormaticc_pressure} unit='bar' min={3} max={30} />
            </ListItemComponent>
          </CardComponent>
        )}

        <ButtonsGroupComponent sizes={{ default: 24, sm: 24 }} >
          <ButtonComponent label={t('application.cta.continue')} disabled={!sectorsValid || !timeValid} onClick={onConfirm} />
          {!isPreset && <ButtonComponent label={t('application.cta.save_and_continue')} disabled={!sectorsValid || !timeValid} onClick={onConfirmAndSave} />}
          <ButtonComponent label={t('application.cta.cancel')} confirm={t('application.messages.confirm_cancel')} onClick={() => props.goBack()} warning />
          {isPresetEdit && <ButtonComponent label={t('application.cta.remove')} confirm={t('application.messages.confirm_remove')} onClick={onRemove} danger />}
        </ButtonsGroupComponent>
      </FixedBarComponent>

      <ModalComponent
        status={!!presetModal}
        onClose={() => setPresetModal(false)}
        title={t('application.titles.presets_name')}
      >
        <InputTextComponent
          alue={presetName}
          label={t("irriapp.messages.alert_set_preset_name")}
          placeholder={t("application.labels.name")}
          onChange={(v) => setPresetName(v)}
        />
        <ButtonsGroupComponent>
          <ButtonComponent
            label={t("application.cta.confirm")}
            onClick={onSavePreset}
            disabled={!presetName}
          />
          <ButtonComponent
            label={t("application.cta.cancel")}
            onClick={() => setPresetModal(false)}
            warning
          />
        </ButtonsGroupComponent>
      </ModalComponent>
    </div>
  )
}

EditorScreen.propTypes = {
  goBack: PropTypes.func
}

EditorScreen.defaultProps = {
  goBack: () => {}
}
